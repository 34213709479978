import { SxProps } from '@mui/material'
import { gridClasses } from '@mui/x-data-grid-pro'

import { colors } from 'src/theme/colors'

export const dataGridStandard: SxProps = {
	mb: 4,
	bgcolor: '#fff',
	[`& .${gridClasses.cell}`]: {
		py: 1,
	},
	'&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
		outline: 'none',
	},
	'&.MuiDataGrid-root .MuiDataGrid-row:hover': {
		cursor: 'pointer',
	},
	'.MuiDataGrid-cell': {
		minHeight: '55px !important',
	},
	'.task-last-visible': {
		borderRadius: '0 0px 15px 0px',
	},
	'.MuiDataGrid-pinnedColumns': {
		borderRadius: '0 0px 0px 15px',
	},
	'.MuiDataGrid-pinnedColumns .MuiDataGrid-row--lastVisible': {
		borderRadius: '0 0px 0px 15px',
	},
	'.task-state-complete': {
		opacity: 0.5,
		backgroundColor: 'rgba(246, 248, 252, 1)',
	},
	'.task-state-complete:hover ': {
		backgroundColor: 'rgba(246, 248, 252, 1)',
	},
	'.task-sync-no_task_for_blueprint_step': {
		backgroundColor: '#e6f2ff',
		backgroundImage:
			'linear-gradient(#b3d9ff 0.4px, transparent 0.4px), linear-gradient(to right, #b3d9ff 0.4px, #e6f2ff 0.4px)',
		backgroundSize: '12px 12px',
		opacity: '0.8',
	},
	'.task-type-multi_use_as_required, .task-type-single_use_as_required, .task-type-linked': {
		backgroundColor: 'rgba(255,165,0, 0.2)',
	},
	'.task-type-multi_use_as_required:hover, .task-type-single_use_as_required:hover, .task-type-linked:hover ':
		{
			backgroundColor: 'rgba(255,165,0, 0.4)',
		},
	'.task-flagged-note': {
		backgroundColor: 'rgba(239,83,80, 0.3)',
	},
	'.task-overdue': {
		color: 'red',
		fontWeight: 'bold',
	},
	'.MuiDataGrid-row.read': {
		backgroundColor: 'rgba(246, 248, 252, 1)',
	},
}

export const dataGridTables: SxProps = {
	bgcolor: '#fff',
	'& .MuiDataGrid-row.row--removed': {
		backgroundColor: 'rgba(255, 170, 170, 0.3)',
	},
	'& .MuiDataGrid-row.row--edited': {
		backgroundColor: 'rgba(255, 254, 176, 0.3)',
	},
	'& .MuiDataGrid-row.row--added': {
		backgroundColor: 'rgba(200, 230, 201, 0.3)',
	},
	'& .MuiDataGrid-cell.cell--selected': {
		backgroundColor: 'rgba(252, 202, 70, 0.3)',
	},
}

export const taggedInputStandard: SxProps = {
	width: '100%',

	'&multiLine': {
		highlighter: {
			padding: 8,
			borderWidth: 0,
			border: '2px inset transparent',
			fontWeight: 500,
			fontSize: 14,
			borderRadius: 8,
		},
		input: {
			padding: 8,
			borderWidth: 1,
			borderColor: colors.neutral['40'],
			borderRadius: 8,
			fontFamily: 'Figtree',
			fontSize: 14,
		},
	},

	'&singleLine': {
		display: 'inline-block',
		width: '100%',

		highlighter: {
			padding: 1,
			border: '2px inset transparent',
		},
		input: {
			padding: 8,
			borderWidth: 1,
			borderColor: colors.neutral['40'],
			borderRadius: 8,
			backgroundColor: 'white',
			fontFamily: 'Figtree',
			fontSize: 14,
		},
	},

	suggestions: {
		list: {
			backgroundColor: 'white',
			border: '1px solid rgba(0,0,0,0.15)',
			borderRadius: 8,
			overflow: 'hidden',
		},
		item: {
			padding: '6px 8px',
			fontSize: 14,
			borderBottom: '1px solid rgba(0,0,0,0.15)',
			'&focused': {
				backgroundColor: colors.feldgrau['10'],
			},
		},
	},
}

export const taggedMentionStandard: SxProps = {
	backgroundColor: colors.feldgrau['20'],
	padding: 1,
	borderRadius: 4,
}
