import { V3BlueprintSdk, FilesSdk, V3ProjectSdk } from '@cango-app/sdk'
import { V3ClientTypes } from '@cango-app/types'

export type ListedTask = V3ProjectSdk.ListedTask
export type Project = V3ProjectSdk.GetProjectResponse & { tasks: ListedTask[] }

export enum ProjectFilesState {
	Loading = 'loading',
	Error = 'error',
	Idle = 'idle',
}

export interface ProjectsStateV3 {
	projects: Record<string, Project>
	projectCards: V3BlueprintSdk.Card[]
	projectFiles: FilesSdk.GetFilesByFolderIdResponse
	projectFilesState: ProjectFilesState
	selectedProjectId?: string
	selectedTaskId?: string
	isFetchingProjectTasks: boolean
}

export type ProjectNameWithId = Pick<V3ClientTypes.Project.Project, '_id' | 'name'>
