import React, { ComponentType, useMemo, useState } from 'react'
import capitalize from 'lodash/capitalize'
import { useSelector } from 'react-redux'
import { PulseLoader } from 'react-spinners'

import { selectors as v3blueprintSelectors } from 'src/store/modules/blueprints-v3'

import { Button } from '../button'
import { Text } from '../text'
import { Select } from '../select'

import { CardMenuType } from './index'
import { CardContainer } from './card-container'

type CreationCardProps = {
	menuType: CardMenuType
	onSubmit: (
		selectedBlueprintId: string,
	) => Promise<{ requestStatus: 'fulfilled' | 'rejected'; _id?: string } | void>
	onSubmitSuccess: (_id: string) => void
	isV3: boolean
}

export const CreationCard: ComponentType<CreationCardProps> = ({
	menuType,
	onSubmit,
	onSubmitSuccess,
	isV3,
}) => {
	const [selectedBlueprintId, setSelectedBlueprintId] = useState('')
	const [isLoading, setIsLoading] = useState(false)
	const v3blueprintCards = useSelector(v3blueprintSelectors.getCards)

	const optionsForBlueprintV3Clone = useMemo(
		() => [
			{ label: 'None', value: '', _id: '' },
			...v3blueprintCards
				.filter(({ active }) => !!active)
				.map(({ name, _id }) => ({ label: name, value: _id, _id }))
				.sort((a, b) => a.label.localeCompare(b.label)),
		],
		[JSON.stringify(v3blueprintCards)],
	)

	const createDocument = async () => {
		setIsLoading(true)
		const response = await onSubmit(selectedBlueprintId)
		setIsLoading(false)
		if (response?.requestStatus === 'fulfilled' && response._id) {
			onSubmitSuccess(response._id)
		}
	}

	const cloneOrCreate = menuType === 'blueprint' ? 'Clone' : 'Create'
	const dropdownLabel = `${cloneOrCreate} from Blueprint`

	return (
		<CardContainer shouldGrey menuType={menuType}>
			<Text fontSize={18} fontWeight="bold" color="white" sx={{ mb: 2 }}>
				{`New ${capitalize(menuType)}`}
			</Text>
			<Select
				labelId="clone-blueprint-label"
				label={dropdownLabel}
				onChange={(event) => setSelectedBlueprintId(event.target.value as string)}
				value={selectedBlueprintId}
				sx={{ bgcolor: 'white' }}
				options={optionsForBlueprintV3Clone}
			/>
			{((menuType === 'project' && !!selectedBlueprintId) || menuType === 'blueprint') && (
				<Button
					onClick={createDocument}
					variant="contained"
					sx={{ my: 2, width: '100%' }}
					disabled={isLoading}>
					{isLoading ? (
						<PulseLoader size={8} color="white" />
					) : selectedBlueprintId ? (
						`${cloneOrCreate} ${
							v3blueprintCards.find(({ _id }) => _id === selectedBlueprintId)?.name
						} ${cloneOrCreate === 'Create' ? 'Project' : ''}`
					) : (
						`Create Blank ${menuType}`
					)}
				</Button>
			)}
		</CardContainer>
	)
}
