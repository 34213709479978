import { Dispatch, createAsyncThunk } from '@reduxjs/toolkit'
import { ContactSdk } from '@cango-app/sdk'
import { ClientTypes } from '@cango-app/types'
import axios from 'axios'
import { CreateContactRequest } from '@cango-app/sdk/lib/contacts/create'

import { showSnackbar } from 'src/helpers/snackbarManager'

import type { RootState } from '../../types'
import { selectors as authSelectors } from '../auth'
import { errorHandler } from '../../../helpers/api'

export const getContacts = createAsyncThunk<
	ClientTypes.Contact[],
	undefined,
	{ rejectValue: string; dispatch: Dispatch }
>('contacts/getContacts', async (_, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState() as RootState
		const headers = authSelectors.getAuthHeaders(state)
		const response = await ContactSdk.getAll(import.meta.env.VITE_API as string, headers)
		return response
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue((error as Error).message)
	}
})

export const createContact = createAsyncThunk<
	ClientTypes.Contact,
	CreateContactRequest,
	{ rejectValue: string; state: RootState; dispatch: Dispatch }
>(
	'contacts/createContact',
	async (contact: CreateContactRequest, { getState, rejectWithValue, dispatch }) => {
		try {
			const state = getState()
			const headers = authSelectors.getAuthHeaders(state)
			const response = await ContactSdk.create(import.meta.env.VITE_API as string, headers, contact)
			return response
		} catch (error) {
			errorHandler({ error, dispatch })
			return rejectWithValue((error as Error).message)
		}
	},
)

export const updateContact = createAsyncThunk(
	'contacts/updateContact',
	async (contact: any, { getState, dispatch }) => {
		try {
			const state = getState() as RootState
			const headers = authSelectors.getAuthHeaders(state)
			const response = await axios.put('contact', contact, { headers })
			showSnackbar('Contact updated', { variant: 'success' })
			return response.data ?? []
		} catch (error) {
			errorHandler({ error, dispatch })
		}
	},
)

export const deleteContact = createAsyncThunk(
	'contacts/deleteContact',
	async (contact: any, { getState, dispatch }) => {
		try {
			const state = getState() as RootState
			const headers = authSelectors.getAuthHeaders(state)
			const response = await axios.delete('contact', { data: contact, headers })
			showSnackbar('Contact deleted', { variant: 'success' })
			return response.data ?? []
		} catch (error) {
			errorHandler({ error, dispatch })
		}
	},
)
