/**
 * Cango operations library
 */

export const moveItemInArray = (array: Array<any>, fromIndex: number, toIndex: number) => {
	const copiedArray = [...array]
	const startIndex = fromIndex < 0 ? array.length + fromIndex : fromIndex

	if (startIndex >= 0 && startIndex < copiedArray.length) {
		const endIndex = toIndex < 0 ? copiedArray.length + toIndex : toIndex

		const [item] = copiedArray.splice(fromIndex, 1)
		copiedArray.splice(endIndex, 0, item)
	}

	return copiedArray
}
