import { V3ClientTypes } from '@cango-app/types'
import { createSelector } from '@reduxjs/toolkit'
import _values from 'lodash/values'
import _orderBy from 'lodash/orderBy'
import _map from 'lodash/map'
import _uniq from 'lodash/uniq'
import { FilesSdk, V3BlueprintSdk } from '@cango-app/sdk'

import { sortDocuments } from 'src/helpers/chains'

import { RoleWithUsage, selectors as rolesSelectors } from '../roles'
import { RootState } from '../../types'

import { ListedTask, ProjectFilesState } from './types'

const getProjectsState: (state: RootState) => RootState['cangoProjectsV3'] = createSelector(
	(state: RootState) => state.cangoProjectsV3,
	(projectsState) => projectsState,
)

const getCards: (state: RootState) => V3BlueprintSdk.Card[] = createSelector(
	getProjectsState,
	({ projectCards }) => projectCards,
)

const getMenuChips = createSelector(getCards, (cards) =>
	cards.map(({ _id, name, active }) => ({ _id, name, active })),
)

const getProjectIds: (state: RootState) => string[] = createSelector(
	getProjectsState,
	({ projects }) => Object.keys(projects) || [],
)

const getSelectedProjectId = createSelector(
	getProjectsState,
	({ selectedProjectId }) => selectedProjectId,
)

const getProjects = createSelector(getProjectsState, ({ projects }) => projects || {})

const getProjectsList = createSelector(getProjects, (projects) => {
	const projectList = _values(projects)
	const filteredForSelect = _map(projectList, ({ _id, name }) => ({ _id, label: name }))
	return _orderBy(filteredForSelect, 'label', 'asc')
})

const getSelectedProject = createSelector(
	getProjects,
	getSelectedProjectId,
	(projects, projectId) => {
		if (!projectId) return
		return projects[projectId]
	},
)

const getSelectedTaskId: (state: RootState) => string | undefined = createSelector(
	getProjectsState,
	(projectState) => projectState.selectedTaskId,
)

const getProjectTasks = createSelector(getSelectedProject, (project) =>
	project ? project.tasks : [],
)

const getTaskDescendants: (state: RootState, taskId?: string) => ListedTask[] = createSelector(
	getProjectTasks,
	(state: RootState, taskId?: string) => taskId,
	(tasks, taskId) => {
		let offspring: string[] = []
		if (!taskId) return []

		const recursivelyGetChildren = (parentId: string) => {
			const parentTask = tasks.find(({ _id }) => _id === parentId)
			const children = tasks
				.filter(({ instance, step_id, parent, isSection }) => {
					if (isSection) return false
					if (parentTask?.isMultiUse && !instance?._id) return false
					// use step infrastructure to get children
					if (step_id && offspring.includes(step_id)) {
						return (
							parentTask?.children.some((child) => child.steps.includes(step_id)) &&
							(parentTask.instance ? instance?._id === parentTask?.instance?._id : true)
						)
					}
					// if no step_id, use parent
					return parent?._id === parentId
				})
				.map(({ _id }) => _id)
			if (!children.length) return []
			offspring = [...offspring, ...children]
			children.map(recursivelyGetChildren)
		}

		recursivelyGetChildren(taskId)

		return tasks.filter(({ _id }) => offspring.includes(_id))
	},
)

const getSelectedTask = createSelector(
	getSelectedTaskId,
	getSelectedProject,
	(state: RootState) => state,
	(selectedTaskId, project, state) => {
		if (!project) return
		const projectTasks = project.tasks
		const task = projectTasks.find(({ _id }) => _id === selectedTaskId)
		if (!task) return
		const taskDescendants = getTaskDescendants(state, selectedTaskId).filter(
			({ parent, isMultiUse }) => !isMultiUse && parent?._id === selectedTaskId,
		)
		const selectedTask = {
			...task,
			isCompletable: taskDescendants.every(({ lifecycle }) => lifecycle.complete),
		}
		return selectedTask
	},
)

const getMappedProjectTasks: (state: RootState) => Map<string, V3ClientTypes.Project.Task> =
	createSelector(getSelectedProject, (project) => {
		if (!project) {
			return new Map()
		}
		const mappedTasks = new Map(project.tasks.map((task) => [task._id, task]))
		return mappedTasks
	})

interface SectionWithStepID extends Omit<V3ClientTypes.Project.Task, 'step_id'> {
	step_id: string
}

const getProjectSections: (state: RootState) => SectionWithStepID[] = createSelector(
	getSelectedProject,
	(project) => {
		if (!project) return []
		const filteredTasks = project.tasks.filter(
			({ isSection, step_id }) => isSection && !!step_id,
		) as SectionWithStepID[]
		if (!project.section_order) return filteredTasks
		return sortDocuments(filteredTasks ?? [], project.section_order ?? [])
	},
)

const getProjectMasterTasks = createSelector(
	getSelectedProject,
	getProjectSections,
	(project, sections) => {
		if (!project) return []
		const sectionStepIds = sections.map(({ _id }) => _id)
		return project.tasks.filter(({ section_id, _id }) => {
			if (!section_id && !sectionStepIds.includes(_id ?? '')) return true
		})
	},
)

export interface ProjectSection {
	_id: ListedTask['_id']
	project_id: ListedTask['project_id']
	step_id: string
	name: string
	isCompletable: ListedTask['isCompletable']
	lifecycle: ListedTask['lifecycle']
	instance?: ListedTask['instance']
	isMasterSection?: boolean
	tasks: ListedTask[]
	chain: ListedTask['chain']
}

const getProjectSectionsWithMasterTasks: (state: RootState) => ProjectSection[] = createSelector(
	getSelectedProjectId,
	getProjectMasterTasks,
	getProjectSections,
	getProjectTasks,
	(projectId, masterTasks, sections, tasks) => {
		if (!projectId) return []
		const regularSectionTasks = sections.map((section) => {
			const sectionInstanceId = section.instance?._id
			const sectionTasks = tasks.filter(
				({ section_id, _id, instance }) =>
					section_id === section._id &&
					_id !== section?._id &&
					((!!sectionInstanceId && instance?._id === sectionInstanceId) || !sectionInstanceId),
			)
			const completableTasks = sectionTasks.filter(({ isMultiUse }) => !isMultiUse)
			return {
				...section,
				tasks: sortDocuments(sectionTasks, section.childOrder ?? []),
				isCompletable: completableTasks.every(({ lifecycle }) => lifecycle.complete),
				chain: section.chain,
			}
		})

		if (masterTasks.length) {
			const masterSection = {
				_id: projectId,
				project_id: projectId,
				step_id: projectId,
				name: 'Project wide tasks',
				isCompletable: false,
				lifecycle: { complete: false, completed_options: [] },
				isMasterSection: true,
				tasks: masterTasks,
				chain: undefined,
			}
			return [masterSection, ...regularSectionTasks]
		}

		return regularSectionTasks
	},
)

const getTask: (
	state: RootState,
	projectId: string,
	taskId?: string,
) => V3ClientTypes.Project.Task | undefined = createSelector(
	getMappedProjectTasks,
	getTaskDescendants,
	(_: RootState, __: string, taskId?: string) => taskId,
	(mappedTasks, taskDescendants, taskId) => {
		const task = mappedTasks.get(taskId || '')
		if (!task) return
		const taskChildren = taskDescendants.filter(({ isMultiUse }) => !isMultiUse)
		return { ...task, isCompletable: taskChildren.every(({ lifecycle }) => lifecycle.complete) }
	},
)

const getDriveId = createSelector(getSelectedProject, (project) => project?.googleDriveId)

const getProjectFiles: (state: RootState) => FilesSdk.GetFilesByFolderIdResponse = createSelector(
	getProjectsState,
	({ projectFiles }) => projectFiles,
)

const getProjectFilesState: (state: RootState) => ProjectFilesState = createSelector(
	getProjectsState,
	({ projectFilesState }) => projectFilesState,
)

const getSelectedTaskRoles = createSelector(getSelectedTask, (task) => {
	if (!task) return []
	return task.actions.reduce((_acc: string[], _action) => {
		return _uniq([..._acc, ..._action.roles])
	}, [])
})

const getTaskRoles: (state: RootState) => { role: string; user?: string }[] = createSelector(
	getSelectedProject,
	rolesSelectors.getMappedRoles,
	getSelectedTaskRoles,
	(project, mappedRoles, selectedTaskRoles) => {
		if (!project) return []
		const projectRoles = project.roles
		const roles = selectedTaskRoles.map((_role) => mappedRoles.get(_role))
		const internalRoles = roles.filter((_role) => !!_role && !!_role.internal) as RoleWithUsage[]
		return internalRoles.map((actionRole) => {
			const matchedRole = projectRoles.find(({ role }) => role === actionRole._id)
			if (matchedRole) {
				return matchedRole
			}
			return {
				role: actionRole._id,
			}
		})
	},
)

const getTaskExternals: (state: RootState) => { role: string; contact?: string }[] = createSelector(
	getSelectedProject,
	rolesSelectors.getMappedRoles,
	getSelectedTaskRoles,
	(project, mappedRoles, selectedTaskRoles) => {
		if (!project) return []
		const projectExternals = project.externals
		const roles = selectedTaskRoles.map((_role) => mappedRoles.get(_role))
		const externalRoles = roles.filter((_role) => !!_role && !_role.internal) as RoleWithUsage[]
		return externalRoles.map((actionRole) => {
			const matchedExternal = projectExternals.find(({ role }) => role === actionRole._id)
			if (matchedExternal) {
				return {
					role: matchedExternal.role,
					contact: matchedExternal.contact,
				}
			}
			return {
				role: actionRole._id,
			}
		})
	},
)

const isFetchingProjectTasks: (state: RootState) => boolean = createSelector(
	getProjectsState,
	({ isFetchingProjectTasks }) => isFetchingProjectTasks,
)

export const selectors = {
	getCards,
	getMenuChips,
	getProjectIds,
	getSelectedProject,
	getProjectTasks,
	getProjectMasterTasks,
	getTaskDescendants,
	getProjectSections,
	getTask,
	getDriveId,
	getSelectedTask,
	getProjectFiles,
	getProjectFilesState,
	getSelectedProjectId,
	getTaskRoles,
	getTaskExternals,
	getProjectSectionsWithMasterTasks,
	getMappedProjectTasks,
	getProjectsList,
	getSelectedTaskId,
	isFetchingProjectTasks,
}
