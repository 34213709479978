import React, { ComponentType, useState } from 'react'
import { PulseLoader } from 'react-spinners'

import { relativeTimeLabel } from 'src/helpers/labels'
import { SwitchVertical } from 'src/assets/icons'

import { Box } from '../box'
import { Button } from '../button'
import { Grid } from '../grid'
import { Text } from '../text'
import { IconButton } from '../icon-button'

type SectionTitleBarProps = {
	isBlueprint: boolean
	sectionName: string
	sectionId: string
	onNewTaskClick: (data: { sectionId: string }) => void
	onSectionStart?: (sectionId: string) => Promise<void>
	onSectionComplete?: (sectionId: string) => Promise<void>
	startedAt?: number
	completedAt?: number[]
	rowCount?: number
	canReorder?: boolean
	onReorderClick?: () => void
	onSectionNameClick?: () => void
}

export const SectionTitleBar: ComponentType<SectionTitleBarProps> = ({
	isBlueprint,
	sectionName,
	sectionId,
	onNewTaskClick,
	onSectionStart,
	onSectionComplete,
	startedAt,
	completedAt,
	rowCount,
	canReorder,
	onReorderClick,
	onSectionNameClick,
}) => {
	const [isSectionStartLoading, setSectionStartLoading] = useState(false)

	const handleSectionStart = async () => {
		if (!onSectionStart) return
		setSectionStartLoading(true)
		await onSectionStart(sectionId)
		setSectionStartLoading(false)
	}

	const handleSectionComplete = async () => {
		if (!onSectionComplete) return
		setSectionStartLoading(true)
		await onSectionComplete(sectionId)
		setSectionStartLoading(false)
	}

	return (
		<Grid container mb={1}>
			<Grid xs={6} item>
				<Box display="flex" alignItems="center" minHeight="50px">
					<Text variant="h5" fontWeight={500} onClick={onSectionNameClick}>
						{sectionName}
					</Text>
					<Button variant="outlined" sx={{ ml: 2 }} onClick={() => onNewTaskClick({ sectionId })}>
						Add {isBlueprint ? 'Step' : 'Task'}
					</Button>
					{!!canReorder && (
						<IconButton sx={{ mx: 1 }} onClick={onReorderClick}>
							<SwitchVertical />
						</IconButton>
					)}
				</Box>

				{!!rowCount && (
					<Text fontSize={14} variant="overline" lineHeight={1}>{`${rowCount} task${
						rowCount !== 0 ? 's' : ''
					}`}</Text>
				)}
			</Grid>
			<Grid xs={6} display="flex" justifyContent="flex-end" alignItems="center" item>
				{!!onSectionStart && (
					<Button onClick={handleSectionStart} sx={{ width: 150 }}>
						{isSectionStartLoading ? <PulseLoader size={8} /> : 'Start Section'}
					</Button>
				)}
				{!!startedAt && <Text fontSize={14}>Started {relativeTimeLabel(startedAt)}</Text>}
				{!!onSectionComplete && (
					<Button onClick={handleSectionComplete} sx={{ width: 150 }}>
						{isSectionStartLoading ? <PulseLoader size={8} /> : 'Complete Section'}
					</Button>
				)}
				{!!completedAt?.length && (
					<Text fontSize={14}>
						Completed {relativeTimeLabel(completedAt[completedAt.length - 1])}
					</Text>
				)}
			</Grid>
		</Grid>
	)
}
