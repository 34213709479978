import React, { ComponentType } from 'react'
import { useNavigate } from 'react-router-dom'

import ConfusedUser from 'src/assets/images/illustrations/confused-user'

import { Box } from './box'
import { Button } from './button'
import { Text } from './text'

type ErrorFallbackProps = {
	error: Error
	resetError(): void
}

const ErrorFallback: ComponentType<ErrorFallbackProps> = ({ error, resetError }) => {
	const navigate = useNavigate()

	const onTryAgainClick = () => {
		navigate('/', { replace: true })
		resetError()
	}
	return (
		<Box flex={1} display="flex" justifyContent="center" alignItems="center" flexDirection="column">
			<Box width={400} mt={-4}>
				<ConfusedUser />
			</Box>
			<Text variant="h5" fontWeight="bold" mb={2}>
				Oops! Something has gone wrong...
			</Text>
			<Text>{"...but don't worry! These things happen (albeit mostly for politicians)."}</Text>
			<Text mb={2}>{"We've alerted the Cango nerds and they'll be onto it in a flash"}</Text>
			<Button variant="contained" onClick={onTryAgainClick}>
				Re-launch Cango
			</Button>
			<Text mt={4} fontSize={14}>
				<b>Error message:</b> {error.message}
			</Text>
		</Box>
	)
}

export default ErrorFallback
