import {
	useState,
	useCallback,
	ComponentType,
	createContext,
	useEffect,
	PropsWithChildren,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FilesSdk } from '@cango-app/sdk'
import * as Sentry from '@sentry/react'

import { selectors as authSelectors } from 'src/store/modules/auth'
import {
	selectors as persistedFilesSelector,
	actions as persistedFilesActions,
} from 'src/store/modules/persisted-files'
import { errorHandler } from 'src/helpers/api'
import { RootState } from 'src/store/types'

export type UploadDriveChildProps = {
	allFiles: FilesSdk.GetFilesByFolderIdResponse
	isLoadingFiles: boolean
	fetchFiles: () => Promise<FilesSdk.GetFilesByFolderIdResponse>
	fetchFilesWithAllIdsPresent: (
		targetFileIds?: string[],
		maxRetries?: number,
		initialWait?: number,
		backoffFactor?: number,
	) => Promise<FilesSdk.GetFilesByFolderIdResponse>
	parentFolderId?: string
	countdown: number
	attemptNumber: number
	addNewFilesToCollection: () => void
}

export const DriveFilesContext = createContext<UploadDriveChildProps>({
	allFiles: [] as FilesSdk.GetFilesByFolderIdResponse,
	isLoadingFiles: false,
	fetchFiles: async () => [],
	parentFolderId: undefined,
	fetchFilesWithAllIdsPresent: async () => [],
	countdown: 0,
	attemptNumber: 0,
	addNewFilesToCollection: () => undefined,
})

const allFilesPresent = (fetchedIds: string[], targetIds: string[]): boolean => {
	return targetIds.every((id) => fetchedIds.includes(id))
}

export const DriveFilesProvider: ComponentType<PropsWithChildren<{ parentFolderId?: string }>> = (
	props,
) => {
	const dispatch = useDispatch()
	const [isLoadingFiles, setIsLoadingFiles] = useState(false)
	const projectFiles = useSelector((state: RootState) =>
		persistedFilesSelector.getProjectFiles(state, props.parentFolderId),
	)
	const authHeaders = useSelector(authSelectors.getAuthHeaders)
	const [countdown, setCountdown] = useState(0)
	const [attemptNumber, setAttemptNumber] = useState(0)

	const fetchFiles = useCallback(async () => {
		if (!props.parentFolderId) return []
		let fetchedFiles: FilesSdk.GetFilesByFolderIdResponse = []
		try {
			setIsLoadingFiles(true)
			const files = await FilesSdk.getFilesByFolderId(
				import.meta.env.VITE_API as string,
				authHeaders,
				{
					parentFolderId: props.parentFolderId,
				},
			)
			dispatch(persistedFilesActions.setProjectFiles({ id: props.parentFolderId, files }))
			fetchedFiles = files
		} catch (error) {
			errorHandler({ error, dispatch, message: 'Could not fetch files' })
		} finally {
			setIsLoadingFiles(false)
		}
		return fetchedFiles
	}, [props.parentFolderId, authHeaders])

	const fetchFilesWithAllIdsPresent = async (
		targetFileIds: string[] = [],
		maxRetries = 4,
		initialWait = 1000,
		backoffFactor = 2,
	): Promise<FilesSdk.GetFilesByFolderIdResponse> => {
		let attempt = 0
		let waitTime = initialWait

		const attemptFetch = async (): Promise<FilesSdk.GetFilesByFolderIdResponse> => {
			if (attempt >= maxRetries)
				throw new Error('Maximum retries reached without finding all files.')

			const fetchedFiles = await fetchFiles()
			const fetchedIds = fetchedFiles.map((file) => file.id)
			if (allFilesPresent(fetchedIds, targetFileIds)) {
				setCountdown(0)
				setAttemptNumber(0)
				return fetchedFiles
			} else {
				setCountdown(waitTime / 1000)
				setAttemptNumber(attempt + 1)

				const intervalId = setInterval(() => {
					setCountdown((currentCountdown) => {
						if (currentCountdown <= 1) {
							clearInterval(intervalId)
							return 0
						}
						return currentCountdown - 1
					})
				}, 1000)

				await new Promise((resolve) => setTimeout(resolve, waitTime))
				waitTime *= backoffFactor
				attempt++
				return attemptFetch()
			}
		}

		return attemptFetch()
	}

	const addNewFilesToCollection = async () => {
		if (!props.parentFolderId) return
		try {
			const files = await FilesSdk.getFilesByFolderId(
				import.meta.env.VITE_API as string,
				authHeaders,
				{
					parentFolderId: props.parentFolderId,
				},
			)

			const newFiles = files.filter(
				(_file) => !projectFiles.some((_projectFile) => _projectFile.id === _file.id),
			)

			dispatch(
				persistedFilesActions.setAddNewFilesToProjectFiles({
					id: props.parentFolderId,
					files: newFiles,
				}),
			)
		} catch (error) {
			Sentry.captureException(error)
		}
	}

	useEffect(() => {
		if (props.parentFolderId) {
			fetchFiles()
		}
	}, [props.parentFolderId])

	return (
		<DriveFilesContext.Provider
			value={{
				allFiles: projectFiles,
				isLoadingFiles,
				fetchFiles,
				parentFolderId: props.parentFolderId,
				fetchFilesWithAllIdsPresent,
				countdown,
				attemptNumber,
				addNewFilesToCollection,
			}}
		>
			{props.children}
		</DriveFilesContext.Provider>
	)
}
