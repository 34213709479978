import React, { ChangeEvent, ComponentType, useState } from 'react'
import findIndex from 'lodash/findIndex'
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import SwapVerticalCircleOutlined from '@mui/icons-material/SwapVerticalCircleOutlined'
import { useEffectOnce } from 'usehooks-ts'
import { FilesSdk } from '@cango-app/sdk'

import { CameraIcon, CloseIcon, VideoCameraIcon } from 'src/assets/icons'
import { FileData } from 'src/hooks/useFileUpload'
import { colors } from 'src/theme/colors'
import { UploadDriveChildProps } from 'src/providers'

import googleDriveLogo from '../../assets/images/google_drive_logo.png'
import { Button } from '../button'
import { Modal } from '../modal'
import { Box } from '../box'
import { Text } from '../text'

import { UploadPanel } from './upload-panel'
import { FilesPanel } from './files-panel'
import { DriveUploadProps } from './index'

enum DriveUploadView {
	Options = 0,
	Select = 1,
	Upload = 2,
	Photo = 3,
}

export const DriveUploadMobile: ComponentType<UploadDriveChildProps & DriveUploadProps> = ({
	containerStyles,
	label = 'Save to Google Drive™',
	isDisabled,
	parentFolderId,
	parentFolderName,
	onChange,
	isLoading,
	fileIds,
	ctaVariant = 'button',
	fetchFiles,
	isLoadingFiles,
	allFiles,
	children,
}) => {
	const [view, setView] = useState<DriveUploadView>(DriveUploadView.Options)
	const [isUploadModalOpen, setIsUploadModalOpen] = useState(false)
	const [selectedFiles, setSelectedFiles] = useState<string[]>([])
	const [stagedCameraFiles, setStagedCameraFiles] = useState<FileData[]>([])

	const handleCloseModal = () => {
		setView(DriveUploadView.Options)
		setStagedCameraFiles([])
		setSelectedFiles([])
		setIsUploadModalOpen(false)
	}

	const onFilesUploaded = async (uploadedFileIds: string[]) => {
		await onChange(uploadedFileIds)
		handleCloseModal()
	}

	const handleDriveClick = async () => {
		await fetchFiles()
		setSelectedFiles(fileIds)
		setIsUploadModalOpen(true)
	}

	const handleSubmit = async (fileIds: string[]) => {
		await onChange(fileIds)
		handleCloseModal()
	}

	const handleCapture = (event: ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files ? event.target.files[0] : null
		if (file) {
			setStagedCameraFiles([
				...stagedCameraFiles,
				{
					file,
					uploadProgress: 0,
					state: FilesSdk.FileUploadState.Pending,
				},
			])
		}
	}

	const onSelectFile = (fileId: string, isSelected: boolean) => {
		if (selectedFiles.includes(fileId) && isSelected) return

		if (!selectedFiles.includes(fileId) && isSelected) {
			setSelectedFiles([...selectedFiles, fileId])
			return
		}

		if (selectedFiles.includes(fileId) && !isSelected) {
			const selectedFilesCopy = [...selectedFiles]
			const fileIndex = findIndex(selectedFilesCopy, (_fileId) => _fileId === fileId)
			selectedFilesCopy.splice(fileIndex, 1)
			setSelectedFiles([...selectedFilesCopy])
		}
	}

	const handleDeleteStagedCameraFile = (index: number) => {
		const stagedFilesCopy = [...stagedCameraFiles]
		stagedFilesCopy.splice(index, 1)
		setStagedCameraFiles([...stagedFilesCopy])
	}

	const handleSubmitCameraFiles = () => {
		setView(DriveUploadView.Upload)
	}

	useEffectOnce(() => {
		return () => {
			handleCloseModal()
		}
	})

	const renderCta = () => {
		if (children) {
			return children({ onClick: handleDriveClick })
		}
		switch (ctaVariant) {
			case 'replaceIcon':
				return (
					<Button
						onClick={handleDriveClick}
						isLoading={!!isLoading || isLoadingFiles}
						variant="outlined"
						size="small"
						startIcon={<SwapVerticalCircleOutlined />}>
						Replace files
					</Button>
				)
			default: {
				return (
					<Button
						sx={{ ...containerStyles, textTransform: 'none' }}
						variant="outlined"
						startIcon={<img src={googleDriveLogo} width={20} />}
						disabled={isDisabled}
						isLoading={!!isLoading || isLoadingFiles}
						onClick={handleDriveClick}>
						<Text>{isDisabled ? 'Failed to fetch file' : label}</Text>
					</Button>
				)
			}
		}
	}

	const content = () => {
		if (view === DriveUploadView.Options) {
			return (
				<Box>
					<List>
						{!stagedCameraFiles.length && (
							<ListItem>
								<ListItemButton onClick={() => setView(DriveUploadView.Select)}>
									<ListItemIcon>
										<img src={googleDriveLogo} width={20} />
									</ListItemIcon>
									<ListItemText>Select from Google Drive</ListItemText>
								</ListItemButton>
							</ListItem>
						)}
						{!stagedCameraFiles.length && (
							<ListItem>
								<ListItemButton onClick={() => setView(DriveUploadView.Upload)}>
									<ListItemIcon>
										<img src={googleDriveLogo} width={20} />
									</ListItemIcon>
									<ListItemText>Upload to Google Drive</ListItemText>
								</ListItemButton>
							</ListItem>
						)}
						<ListItem>
							<label htmlFor="photo-input" style={{ width: '100%' }}>
								<ListItemButton>
									<ListItemIcon>
										<CameraIcon />
									</ListItemIcon>
									<ListItemText primary="Take a photo" />
									<input
										id="photo-input"
										type="file"
										accept="image/*"
										capture="environment"
										onChange={handleCapture}
										style={{ display: 'none' }} // Hide the actual input
									/>
								</ListItemButton>
							</label>
						</ListItem>
						<ListItem>
							<label htmlFor="video-input" style={{ width: '100%' }}>
								<ListItemButton>
									<ListItemIcon>
										<VideoCameraIcon />
									</ListItemIcon>
									<ListItemText primary="Take a video" />
									<input
										id="video-input"
										type="file"
										accept="video/*"
										capture="environment"
										onChange={handleCapture}
										style={{ display: 'none' }} // Hide the actual input
									/>
								</ListItemButton>
							</label>
						</ListItem>
					</List>
					<Box display="flex" flexWrap="wrap">
						{stagedCameraFiles.map(({ file }, index) => (
							<Box key={`${file.name}-${index}`} position="relative" m={1}>
								<img src={URL.createObjectURL(file)} alt="staged" width="75px" />
								<Box
									position="absolute"
									right={2}
									top={2}
									bgcolor={colors.neutral['30']}
									width={30}
									height={30}
									p={0.5}
									display="flex"
									justifyContent="center"
									alignItems="center"
									borderRadius={30}
									onClick={() => handleDeleteStagedCameraFile(index)}>
									<CloseIcon />
								</Box>
							</Box>
						))}
					</Box>
					{!!stagedCameraFiles.length && (
						<Box sx={{ mx: 2 }}>
							<Button fullWidth onClick={handleSubmitCameraFiles}>
								Submit
							</Button>
						</Box>
					)}
				</Box>
			)
		}

		if (view === DriveUploadView.Upload) {
			return (
				<Box>
					<UploadPanel
						parentFolderId={parentFolderId}
						onFilesUploaded={onFilesUploaded}
						defaultFilesToUpload={stagedCameraFiles}
					/>
				</Box>
			)
		}

		if (view === DriveUploadView.Select) {
			return (
				<FilesPanel
					parentFolderName={parentFolderName}
					files={allFiles}
					onSubmit={handleSubmit}
					selectedFiles={selectedFiles}
					onSelectFile={onSelectFile}
				/>
			)
		}

		return null
	}

	return (
		<>
			<Modal open={isUploadModalOpen} onClose={handleCloseModal}>
				<Box width={700} minHeight={400} maxHeight={600} maxWidth="100%" bgcolor="white">
					{content()}
				</Box>
			</Modal>
			{renderCta()}
		</>
	)
}
