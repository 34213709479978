import { createSelector } from '@reduxjs/toolkit'
import { V3ClientTypes } from '@cango-app/types'
import findIndex from 'lodash/findIndex'
import _find from 'lodash/find'
import _cloneDeep from 'lodash/cloneDeep'
import orderBy from 'lodash/orderBy'
import some from 'lodash/some'
import get from 'lodash/get'
import difference from 'lodash/difference'
import _map from 'lodash/map'
import _findLastIndex from 'lodash/findLastIndex'
import _isEmpty from 'lodash/isEmpty'
import _uniqBy from 'lodash/uniqBy'
import { V3ProjectSdk } from '@cango-app/sdk'

import {
	getTaskDescendants as _getTaskDescendants,
	recursivelyGetParents,
	sortDocuments,
	sortDocumentsUsingHierarchy,
} from 'src/helpers/chains'

import { selectors as notesSelectors } from '../notes'
import { RoleWithUsage, selectors as rolesSelectors } from '../roles'
import { RootState, CangoReduxModuleName } from '../../types'
import { selectors as projectSelectors } from '../projects-v3'

import {
	MyTasksV3State,
	Project,
	Section,
	TaskListType,
	MyTasksLoadingState,
	TaskWithCompletable,
	AsRequiredInstance,
} from './types'

const getMyTasksState: (state: RootState) => MyTasksV3State = createSelector(
	(state: RootState) => state[CangoReduxModuleName.CangoMyTasksV3],
	(myTasksState) => myTasksState,
)

const getTasks = createSelector(getMyTasksState, (myTasksState) => myTasksState.tasks)

const getBlockOptions = createSelector(getMyTasksState, (myTasksState) => myTasksState.blockOptions)

const getIsLoadingBlockOptions = createSelector(
	getMyTasksState,
	(myTasksState) => myTasksState.isLoadingBlockOptions,
)

const getMappedTasks = createSelector(getTasks, (tasks) => {
	return new Map(tasks.map((task) => [task._id, task]))
})

const getBlockedTaskId: (state: RootState) => string | undefined = createSelector(
	getMyTasksState,
	({ flaggedTaskId }) => flaggedTaskId,
)

const getProjectListState = createSelector(getMyTasksState, ({ myTasksState }) => myTasksState)

const isChatModalOpen: (state: RootState) => boolean = createSelector(
	getMyTasksState,
	(myTasksState) => myTasksState.isChatModalOpen,
)

const getTaskListType: (state: RootState) => TaskListType = createSelector(
	getMyTasksState,
	(myTasksState) => myTasksState.taskListType,
)

const getProjects: (state: RootState) => MyTasksV3State['projects'] = createSelector(
	getMyTasksState,
	(myTasksState) => myTasksState.projects,
)

const getProjectsWithBlockedTasksSection = createSelector(getProjects, (projects) => {
	const clonedProjects = _cloneDeep(projects)

	const mappedProjects = _map(clonedProjects, (project, projectId) => {
		const filteredSections = project.sections.filter((section) => {
			if (section.userPendingTaskCount > 0) {
				return true
			}

			if (section.pendingTasksForOtherUsers > 0) {
				return false
			}

			return section.totalTaskCount > 0 && section.pendingTasksForOtherUsers === 0
		})
		project.sections = filteredSections
		if (project.sections.every((section) => !section.isBlocked)) return project
		const blockedTasksSection: Pick<
			Section,
			| '_id'
			| 'pendingTasksForOtherUsers'
			| 'totalTaskCount'
			| 'userPendingTaskCount'
			| 'project_id'
			| 'name'
			| 'step_id'
			| 'isBlocked'
			| 'childOrder'
			| 'instance'
		> = {
			_id: `${projectId}--blocked`,
			pendingTasksForOtherUsers: 0,
			totalTaskCount: 0,
			userPendingTaskCount: 0,
			project_id: projectId,
			name: 'Blocked tasks',
			isBlocked: true,
			childOrder: [],
		}

		return {
			...project,
			sections: [
				...sortDocuments(project.sections, project.section_order ?? []),
				blockedTasksSection,
			],
		}
	})
	const removeEmptyProjects = mappedProjects.filter((project) => project.sections.length > 0)
	return orderBy(removeEmptyProjects, ['name'], ['asc'])
})

const isLoadingProjects: (state: RootState) => boolean = createSelector(
	getMyTasksState,
	({ myTasksState }) => myTasksState === MyTasksLoadingState.Loading,
)

const hasMyTasksError: (state: RootState) => boolean = createSelector(
	getMyTasksState,
	({ myTasksState }) => myTasksState === MyTasksLoadingState.Error,
)

const getSectionIdLoading: (state: RootState) => string | undefined = createSelector(
	getMyTasksState,
	(myTasksState) => myTasksState.sectionLoading,
)

const getSelectedSectionId: (state: RootState) => string | undefined = createSelector(
	getMyTasksState,
	(myTasksState) => myTasksState.selectedSectionId,
)

const getSelectedTaskId: (state: RootState) => string | undefined = createSelector(
	getMyTasksState,
	(myTasksState) => myTasksState.selectedTaskId,
)

type SelectedSection = Section & { tasks: V3ClientTypes.Project.Task[] }

const getSelectedSection: (state: RootState) => SelectedSection | undefined = createSelector(
	getProjects,
	getSelectedSectionId,
	getTasks,
	(projects, selectedSectionId, tasks) => {
		if (_isEmpty(projects)) return
		const project = _find(projects, (project) =>
			project.sections.some((section) => section._id === selectedSectionId),
		)
		if (!project) return
		const sectionIndex = findIndex(project.sections, ['_id', selectedSectionId])
		if (sectionIndex < 0) return
		const isBlockedSection = selectedSectionId?.includes('--blocked')
		const section = project.sections[sectionIndex]
		const sectionTasks: V3ClientTypes.Project.Task[] = []
		if (isBlockedSection) {
			sectionTasks.push(
				...tasks.filter((task) => task.project_id === section.project_id && task.isFlagged),
			)
		} else {
			sectionTasks.push(
				...tasks.filter(
					(task) => task.project_id === section.project_id && task.section_id === section._id,
				),
			)
		}

		return {
			...section,
			tasks: sectionTasks,
		}
	},
)

const getSelectedProjectId: (state: RootState) => string | undefined = createSelector(
	getSelectedSection,
	(section) => section?.project_id,
)

const getSelectedProject = createSelector(
	getProjectsWithBlockedTasksSection,
	getSelectedProjectId,
	(projects, selectedProjectId) => {
		if (!selectedProjectId) return
		return _find(projects, ['_id', selectedProjectId])
	},
)

const getSelectedProjectRoles: (state: RootState) => V3ClientTypes.Project.Project['roles'] =
	createSelector(getSelectedProject, (selectedProject) => {
		if (!selectedProject) return []
		return selectedProject.roles
	})

const getSelectedProjectExternals: (
	state: RootState,
) => V3ClientTypes.Project.Project['externals'] = createSelector(
	getSelectedProject,
	(selectedProject) => {
		if (!selectedProject) return []
		return selectedProject.externals
	},
)

const getMappedTaskDescendants = createSelector(getTasks, (tasks) => {
	return new Map(tasks.map((task) => [task._id, _getTaskDescendants(tasks, task._id)]))
})

const getSectionTasks: (state: RootState) => TaskWithCompletable[] = createSelector(
	getSelectedSection,
	getMappedTaskDescendants,
	(section, mappedTaskDescendants) => {
		if (!section) return []

		const sectionTasks = section.tasks.map((task) => {
			const children = mappedTaskDescendants.get(section._id) ?? []
			const taskHierachy = recursivelyGetParents([], task, section.tasks)
			const filteredChildren = children.filter(({ isMultiUse }) => !isMultiUse)

			return {
				...task,
				hierarchy: taskHierachy,
				descendants: mappedTaskDescendants.get(task._id) ?? [],
				subtaskParentId:
					taskHierachy.length > 1 ? taskHierachy[taskHierachy.length - 2] : undefined,
				isCompletable: Boolean(filteredChildren.every(({ lifecycle }) => lifecycle.complete)),
			}
		})

		const topLevelSortedDocs = sortDocuments(sectionTasks, section.childOrder)

		return sortDocumentsUsingHierarchy(topLevelSortedDocs)
	},
)

const getMappedSectionTasks: (state: RootState) => Map<string, TaskWithCompletable> =
	createSelector(getSectionTasks, (tasks) => {
		return new Map(tasks.map((task) => [task._id, task]))
	})

const getParentsInSection = createSelector(getSelectedSection, (section) => {
	if (!section) return []
	return section.tasks.filter((task) => task.isParent)
})

const getActiveTasks = createSelector(getSectionTasks, (tasks) => {
	const filteredTasks = tasks.filter((task) => !task.lifecycle.complete)
	return filteredTasks
})

const getMasterTasks = createSelector(
	getTasks,
	getSelectedSectionId,
	getMappedTaskDescendants,
	(tasks, selectedSectionId, mappedDescendants) => {
		const filteredTasks = tasks.filter(
			(task) => !task.isSection && !task.section_id && task._id !== selectedSectionId,
		)

		return filteredTasks.map((task) => {
			const hierarchy = recursivelyGetParents([], task, filteredTasks)
			const children = mappedDescendants.get(task._id) ?? []
			const filteredChildren = children.filter(({ isMultiUse }) => !isMultiUse)
			return {
				...task,
				descendants: mappedDescendants.get(task._id) ?? [],
				subtaskParentId: hierarchy.length > 1 ? hierarchy[hierarchy.length - 2] : undefined,
				isCompletable: Boolean(filteredChildren.every(({ lifecycle }) => lifecycle.complete)),
			}
		})
	},
)

const getInstanceTasks: (state: RootState) => AsRequiredInstance[] = createSelector(
	getSectionTasks,
	(activeTasks) => {
		return activeTasks.reduce((acc: AsRequiredInstance[], task) => {
			if (!task.instance?._id || task.lifecycle.complete || !task.instance?.instance_of?._id)
				return acc
			const instanceIndex = findIndex(acc, ['multiUseId', task.instance.instance_of?._id])
			if (instanceIndex < 0 && task.instance) {
				acc.push({
					multiUseId: task.instance.instance_of._id,
					multiUseName: task.instance.instance_of.name,
					instanceName: task.instance.name || '',
					tasks: [task],
				})
			} else {
				acc[instanceIndex].tasks.push(task)
			}
			return acc
		}, [])
	},
)

const getActiveSectionTaskList = createSelector(
	getActiveTasks,
	getParentsInSection,
	(tasks, sectionParents) => {
		const priorityTasks: TaskWithCompletable[] = []

		tasks.forEach((task) => {
			const parentId = sectionParents.find((parent) =>
				parent.children.some((child) => child.steps.includes(task.step_id || '')),
			)?._id
			if (task.instance?._id) return
			if (!parentId) {
				priorityTasks.push(task)
			} else {
				const insertIndex = _findLastIndex(priorityTasks, (t) => t._id === parentId)
				priorityTasks.splice(insertIndex + 1, 0, task)
			}
		})

		return priorityTasks
	},
)

const getTaskList = createSelector(
	getTaskListType,
	getActiveSectionTaskList,
	getMasterTasks,
	(taskListType, activeTasks, masterTasks) => {
		return taskListType === TaskListType.Active ? activeTasks : masterTasks
	},
)

const getSelectedTask: (state: RootState) => TaskWithCompletable | undefined = createSelector(
	getMappedTasks,
	getSelectedTaskId,
	getMappedTaskDescendants,
	(mappedTasks, selectedTaskId, mappedDescendants) => {
		if (!selectedTaskId) return
		const task = mappedTasks.get(selectedTaskId)
		if (!task) return
		const taskChildren = mappedDescendants.get(selectedTaskId) ?? []
		const filteredChildren = taskChildren.filter(({ isMultiUse }) => !isMultiUse)
		return {
			...task,
			descendants: taskChildren,
			isCompletable: filteredChildren.every(({ lifecycle }) => {
				if (!task.isParent) return true
				return lifecycle.complete
			}),
		}
	},
)

const getBlockedTask = createSelector(
	getSectionTasks,
	getBlockedTaskId,
	(sectionTasks, blockedTaskId) => {
		if (!blockedTaskId) return
		return _find(sectionTasks, ['_id', blockedTaskId])
	},
)

const getActiveTaskIndex: (state: RootState) => number = createSelector(
	getTaskList,
	getSelectedTask,
	(taskList, selectedTask) => findIndex(taskList, ['_id', selectedTask?._id]),
)

const hasSubtasks: (state: RootState, taskId?: string) => boolean = createSelector(
	getParentsInSection,
	getSectionTasks,
	(state: RootState, taskId?: string) => taskId,
	(sectionParents, sectionTasks, taskId) => {
		if (!taskId) return false
		return some(sectionParents, (task) => {
			if (task._id !== taskId) {
				return false
			}
			const children = sectionTasks.filter((task) => task.subtaskParentId === taskId)
			return children.some((child) => !child.lifecycle.complete)
		})
	},
)

const getTaskRoles: (state: RootState) => Project['roles'] = createSelector(
	getSelectedTask,
	getSelectedProjectRoles,
	rolesSelectors.getMappedRoles,
	(selectedTask, projectRoles, mappedRoles) => {
		if (!selectedTask) return []
		const roles = selectedTask.actions.reduce((_acc: RoleWithUsage[], _action) => {
			const actionRoles = _action.roles.reduce((_roleReducer: RoleWithUsage[], _role) => {
				const role = mappedRoles.get(_role)
				if (role) {
					_roleReducer.push(role)
				}
				return _roleReducer
			}, [])
			return _uniqBy([..._acc, ...(actionRoles ?? [])], '_id')
		}, [])
		const internalRoles = roles.filter((_role) => !!_role && !!_role.internal) as RoleWithUsage[]
		return internalRoles.map((actionRole) => {
			const matchedRole = projectRoles.find(({ role }) => role === actionRole._id)
			if (matchedRole) {
				return matchedRole
			}
			return {
				role: actionRole._id,
			}
		})
	},
)

const getTaskExternals: (state: RootState) => Project['externals'] = createSelector(
	getSelectedTask,
	getSelectedProjectExternals,
	rolesSelectors.getMappedRoles,
	(selectedTask, projectExternals, mappedRoles) => {
		if (!selectedTask) return []
		const externalRoles = selectedTask.actions.reduce((_acc: RoleWithUsage[], _action) => {
			const actionRoles = _action.roles.reduce((_roleReducer: RoleWithUsage[], _role) => {
				const role = mappedRoles.get(_role)
				if (role && !role.internal) {
					_roleReducer.push(role)
				}
				return _roleReducer
			}, [])
			return _uniqBy([..._acc, ...(actionRoles ?? [])], '_id')
		}, [])

		return externalRoles.map((_role) => {
			const matchedExternal = projectExternals.find(({ role }) => role === _role._id)
			if (matchedExternal) {
				return {
					role: matchedExternal.role,
					contact: matchedExternal.contact,
				}
			}
			return {
				role: _role._id,
			}
		})
	},
)

const getNextEligibleTask: (
	state: RootState,
	direction: 1 | -1,
	checkOppositeDirection?: boolean,
) => V3ClientTypes.Project.Task | null = createSelector(
	getTaskList,
	getActiveTaskIndex,
	(state: RootState, direction: 1 | -1, checkOppositeDirection?: boolean) => ({
		direction,
		checkOppositeDirection,
	}),
	(taskList, currentIndex, { direction, checkOppositeDirection }) => {
		let index = currentIndex

		// Define a helper function to check if a task is eligible
		const isEligible = (task: V3ClientTypes.Project.Task | undefined) => {
			if (!task) return false
			if (task.isMultiUse || task.isSection || task.isParent || task.lifecycle.complete)
				return false
			return true
		}

		// Check in the initial direction
		while (index >= 0 && index < taskList.length) {
			index += direction
			const task = get(taskList, index)
			if (isEligible(task)) {
				return task
			}
		}

		// If no eligible task found in the initial direction and checkOppositeDirection is true
		if (checkOppositeDirection) {
			index = currentIndex
			while (index >= 0 && index < taskList.length) {
				index -= direction // Opposite direction
				const task = get(taskList, index)
				if (isEligible(task)) {
					return task
				}
			}
		}

		// If still no eligible task found, return null
		return null
	},
)

const getProjectsNotInMyTasks = createSelector(
	projectSelectors.getProjectIds,
	getProjectsWithBlockedTasksSection,
	(projectStoreIds, projects) => {
		const projectIds = projects.map((project) => project._id)
		const diff = difference(projectStoreIds, projectIds)
		if (_isEmpty(diff)) return []
		const projectNotIncluded = { _id: diff[0] }
		if (!projectNotIncluded) return []
		return [projectNotIncluded]
	},
)

const isSectionBlocked = createSelector(getActiveSectionTaskList, (tasks) => {
	return tasks.some((task) => task.isFlagged)
})

const unreadTaskIdsInSection = createSelector(
	getActiveSectionTaskList,
	notesSelectors.getTasksWithUnreadNotes,
	(tasks, unreadTaskIds) =>
		tasks.filter(({ _id }) => unreadTaskIds.find(({ taskId }) => taskId === _id)),
)

const canCompleteSection = createSelector(
	getSelectedSection,
	getSectionIdLoading,
	(selectedSection, sectionIdLoading) => {
		if (!selectedSection || sectionIdLoading) {
			return false
		}

		return (
			selectedSection.totalTaskCount > 0 &&
			selectedSection.pendingTasksForOtherUsers === 0 &&
			selectedSection.userPendingTaskCount === 0 &&
			!selectedSection.isBlocked
		)
	},
)

const getSectionTasksPendingUpload = createSelector(getTasks, (tasks) =>
	tasks.filter((_task) => {
		if (!_task?.actions) {
			return false
		}
		return _task.actions.some((_action) => _action.file_ids.includes(V3ProjectSdk.UPLOADING_TEXT))
	}),
)

const getNumberOfFilesPendingUpload = createSelector(getSectionTasksPendingUpload, (tasks) => {
	return tasks.reduce((acc, task) => {
		const numberOfFileIds = task.actions.reduce((_acc: number, _action) => {
			const numberOfUploading = _action.file_ids.filter(
				(_id) => _id === V3ProjectSdk.UPLOADING_TEXT,
			).length
			return _acc + numberOfUploading
		}, 0)
		return acc + numberOfFileIds
	}, 0)
})

const getTotalNumberOfFilesRequiringUpload = createSelector(
	getMyTasksState,
	(state) => state.filesToUpload,
)

const getPercentageOfTasksUploaded = createSelector(
	getNumberOfFilesPendingUpload,
	getTotalNumberOfFilesRequiringUpload,
	(filesPendingUpload, totalTasksRequiringUpload) => {
		if (totalTasksRequiringUpload === 0) {
			return 100
		}

		const effectiveCompleted = totalTasksRequiringUpload - filesPendingUpload * 0.5

		const percentage = (effectiveCompleted / totalTasksRequiringUpload) * 100
		return Math.min(100, Math.max(0, percentage))
	},
)

export const selectors = {
	getProjects,
	isLoadingProjects,
	getSelectedTask,
	getSelectedTaskId,
	getSectionIdLoading,
	getProjectsList: getProjectsWithBlockedTasksSection,
	getSelectedSection,
	getSelectedProjectId,
	getSelectedSectionId,
	getSelectedProject,
	getSelectedProjectRoles,
	getActiveTaskIndex,
	getActiveSectionTaskList,
	getTaskRoles,
	getSelectedProjectExternals,
	getTaskExternals,
	hasSubtasks,
	isChatModalOpen,
	getActiveTasks,
	getTaskListType,
	getTaskList,
	getBlockOptions,
	getIsLoadingBlockOptions,
	getInstanceTasks,
	getNextEligibleTask,
	hasMyTasksError,
	getProjectsNotInMyTasks,
	unreadTaskIdsInSection,
	isSectionBlocked,
	getMappedSectionTasks,
	getBlockedTask,
	getMasterTasks,
	canCompleteSection,
	getMappedTasks,
	getProjectListState,
	getSectionTasks,
	getSectionTasksPendingUpload,
	getPercentageOfTasksUploaded,
	getTotalNumberOfFilesRequiringUpload,
	getNumberOfFilesPendingUpload,
}
