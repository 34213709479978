import { FilesSdk, UserSdk } from '@cango-app/sdk'
import { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import map from 'lodash/map'
import * as Sentry from '@sentry/react'
import { useEffectOnce } from 'usehooks-ts'

import { selectors as authSelectors } from 'src/store/modules/auth'
import { selectors as userSelectors } from 'src/store/modules/user'
import { showSnackbar } from 'src/helpers/snackbarManager'

export type FileData = {
	file: File
	uploadProgress: number
	state: FilesSdk.FileUploadState
	fileId?: string
}

interface FileUploadHookProps {
	parentFolderId: string
	defaultFilesToUpload?: FileData[]
}

export const useFileUpload = ({
	parentFolderId,
	defaultFilesToUpload = [],
}: FileUploadHookProps) => {
	const authHeaders = useSelector(authSelectors.getAuthHeaders)
	const userId = useSelector(userSelectors.getCurrentUserId)
	const [stagedFiles, setStagedFiles] = useState<FileData[]>(defaultFilesToUpload)
	const [googleAccessToken, setGoogleAccessToken] = useState<string | undefined>()
	const [fetchingDrivePermission, setFetchingDrivePermission] = useState(true)
	const [shouldRequestDrivePermission, setShouldRequestDrivePermission] = useState(false)

	const getDriveToken = useCallback(
		() =>
			UserSdk.getUserAccessToken(import.meta.env.VITE_API as string, authHeaders, {
				userId,
			}),
		[authHeaders, userId],
	)

	const initDriveUpload = useCallback(async () => {
		try {
			const tokenResponse = await getDriveToken()
			if (tokenResponse.tokenError) {
				setShouldRequestDrivePermission(true)
				return
			}
			setGoogleAccessToken(tokenResponse.token)
		} catch (error) {
			setShouldRequestDrivePermission(true)
			Sentry.captureException(error)
		} finally {
			setFetchingDrivePermission(false)
		}
	}, [])

	const handleSaveUserDriveToken = useCallback(
		async (authCode: string) => {
			try {
				const { token } = await UserSdk.updateUserToken(
					import.meta.env.VITE_API as string,
					authHeaders,
					{
						code: authCode,
					},
				)
				setGoogleAccessToken(token)
				setShouldRequestDrivePermission(false)
			} catch (error) {
				showSnackbar('Error saving drive token', { variant: 'error' })
			}
		},
		[userId],
	)

	const areFilesUploading = useMemo(() => {
		if (!stagedFiles.length) return false
		return stagedFiles.some((file) => file.state === FilesSdk.FileUploadState.Uploading)
	}, [JSON.stringify(stagedFiles)])

	const haveAllFilesUploaded = useMemo(() => {
		if (!stagedFiles.length) return false
		const haveAllUploaded = stagedFiles.every(
			(file) => file.state === FilesSdk.FileUploadState.Complete,
		)
		return haveAllUploaded
	}, [JSON.stringify(stagedFiles)])

	const hasUploadError = useMemo(() => {
		if (!stagedFiles.length) return false
		return stagedFiles.some((file) => file.state === FilesSdk.FileUploadState.Failed)
	}, [JSON.stringify(stagedFiles)])

	const handleStageFiles = (fileList: FileList | null) => {
		if (!fileList || !fileList.length) {
			return
		}
		const fileArray = map(fileList, (file) => ({
			file,
			uploadProgress: 0,
			state: FilesSdk.FileUploadState.Pending,
		}))
		setStagedFiles(fileArray)
	}

	const handleChangeFileName = (fileIndex: number, newName: string) => {
		const fileArrayCopy = stagedFiles
		const fileData = fileArrayCopy[fileIndex]
		const blob = fileData.file.slice(0, fileData.file.size, fileData.file.type)
		const newFile = new File([blob], newName, fileData.file)
		fileArrayCopy.splice(fileIndex, 1, { ...fileData, file: newFile })
		setStagedFiles([...fileArrayCopy])
	}

	const handleRemoveStagedFile = (fileIndex: number) => {
		const fileArrayCopy = stagedFiles
		fileArrayCopy.splice(fileIndex, 1)
		setStagedFiles([...fileArrayCopy])
	}

	const divideFileInChunks = (
		fileData: File,
	): Array<{ blob: Blob; start: number; end: number }> => {
		const fileChunks = []
		const maxBlob = 256 * 10 * 1024 // each chunk size (2.5MB)
		let offset = 0

		while (offset < fileData.size) {
			const chunkSize = Math.min(maxBlob, fileData.size - offset)
			fileChunks.push({
				blob: fileData.slice(offset, offset + chunkSize),
				start: offset,
				end: offset + chunkSize,
			})
			offset += chunkSize
		}

		return fileChunks
	}

	const handleUploadFile = async (file: FileData, index: number) => {
		if (!googleAccessToken) {
			return
		}
		try {
			const fileChunks = divideFileInChunks(file.file)
			const fileSize = file.file.size
			const sessionUrl = await FilesSdk.getGoogleSessionUrl(googleAccessToken, {
				meta: {
					name: file.file.name,
					projectDriveId: parentFolderId,
					mimeType: file.file.type,
				},
				contentLength: fileSize,
			})

			let totalBytesUploaded = 0

			for (const chunk of fileChunks) {
				const fileId = await FilesSdk.uploadFileChunk(
					{
						start: chunk.start,
						end: chunk.end,
						sessionUrl,
						contentLength: fileSize,
					},
					chunk.blob,
				)

				totalBytesUploaded += chunk.blob.size
				const percentCompleted = Math.round((totalBytesUploaded * 100) / fileSize)
				setStagedFiles((prevFiles) => {
					const newFiles = [...prevFiles]
					newFiles[index] = {
						...newFiles[index],
						uploadProgress: percentCompleted,
						fileId: newFiles[index].fileId ?? fileId,
					}
					return newFiles
				})
			}

			setStagedFiles((prevFiles) => {
				const newFiles = [...prevFiles]
				newFiles[index] = {
					...newFiles[index],
					state: FilesSdk.FileUploadState.Complete,
				}
				return newFiles
			})
		} catch (error) {
			Sentry.captureException(error)
		}
	}

	const handleUploadFiles = async () => {
		if (!googleAccessToken) {
			return
		}
		stagedFiles.forEach((file, i) => {
			setStagedFiles((prevFiles) => {
				const newFiles = [...prevFiles]
				newFiles[i].state = FilesSdk.FileUploadState.Uploading
				return newFiles
			})
			handleUploadFile(file, i)
		})
	}

	useEffectOnce(() => {
		initDriveUpload()
	})

	return {
		//variables
		stagedFiles,
		areFilesUploading,
		isUploadingComplete: haveAllFilesUploaded,
		hasUploadError,
		shouldRequestDrivePermission,
		fetchingDrivePermission,

		// methods
		onStageFiles: handleStageFiles,
		onChangeStagedFileName: handleChangeFileName,
		onUploadStagedFiles: handleUploadFiles,
		onRemoveStagedFile: handleRemoveStagedFile,
		onSaveAccessToken: handleSaveUserDriveToken,
	}
}
