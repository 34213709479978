import { ClientTypes } from '@cango-app/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import * as blueprintThunks from '../blueprints-v3/thunks'

import * as thunks from './thunks'
import { TableState } from './types'

const initialState: TableState = {
	tables: [],
	mountedTable: undefined,
	isLoadingTables: false,
}

export const tableSlice = createSlice({
	name: 'table',
	initialState,
	reducers: {
		setTables(state: TableState, action: PayloadAction<ClientTypes.Table.CangoTable[]>) {
			state.tables = action.payload
		},
		unmountTable: (state) => {
			state.mountedTable = undefined
		},
		endSession: () => initialState,
	},
	extraReducers: (builder) => {
		builder.addCase(thunks.updateTableName.pending, (state: TableState, action) => {
			const { tableId } = action.meta.arg
			state.tableIdLoading = tableId
		})
		builder.addCase(thunks.updateTableName.fulfilled, (state: TableState, action) => {
			const { tableId } = action.payload
			const tableIndex = state.tables.findIndex((table) => table._id === tableId)
			state.tables[tableIndex].name = action.payload.tableName
			state.tableIdLoading = undefined
		})
		builder.addCase(thunks.updateTableName.rejected, (state: TableState) => {
			state.tableIdLoading = undefined
		})
		builder.addCase(thunks.deleteTable.fulfilled, (state: TableState, action) => {
			const tableId = action.meta.arg.tableId
			state.tables = state.tables.filter((table) => table._id !== tableId)
		})
		builder.addCase(thunks.createTable.fulfilled, (state: TableState, action) => {
			state.tables = [...state.tables, action.payload]
		})
		builder.addCase(thunks.fetchAllTables.pending, (state: TableState) => {
			state.isLoadingTables = true
		})
		builder.addCase(thunks.fetchAllTables.fulfilled, (state: TableState, action) => {
			state.tables = action.payload
			state.isLoadingTables = false
		})
		builder.addCase(thunks.fetchAllTables.rejected, (state: TableState) => {
			state.isLoadingTables = false
		})
		builder
			.addCase(thunks.getTable.pending, (state: TableState, action) => {
				state.tableIdLoading = action.meta.arg.tableId
			})
			.addCase(thunks.getTable.fulfilled, (state: TableState, action) => {
				state.mountedTable = action.payload
				state.tableIdLoading = undefined
			})
			.addCase(thunks.getTable.rejected, (state: TableState) => {
				state.tableIdLoading = undefined
			})
		builder.addCase(thunks.getTableForProject.fulfilled, (state: TableState, action) => {
			state.mountedTable = action.payload
		})
		builder.addCase(thunks.updateRowOrder.fulfilled, (state: TableState, action) => {
			state.mountedTable = action.payload
		})
		builder.addCase(thunks.updateColumnOrder.fulfilled, (state: TableState, action) => {
			state.mountedTable = action.payload
		})
		builder.addCase(thunks.pasteData.fulfilled, (state: TableState, action) => {
			state.mountedTable = action.payload
		})
		builder.addCase(thunks.updateField.fulfilled, (state: TableState, action) => {
			state.mountedTable = action.payload
		})
		builder.addCase(thunks.deleteField.fulfilled, (state: TableState, action) => {
			state.mountedTable = action.payload
		})
		builder.addCase(blueprintThunks.updateDatabaseTable.fulfilled, (state: TableState, action) => {
			state.mountedTable = action.payload
		})
		builder.addCase(thunks.lockField.pending, (state: TableState, action) => {
			const { fieldId, lock } = action.meta.arg
			if (state.mountedTable) {
				const updatedFields = state.mountedTable.fields.map((field) =>
					field._id === fieldId ? { ...field, locked: lock } : field,
				)
				state.mountedTable = {
					...state.mountedTable,
					fields: updatedFields,
				}
			}
		})
		builder.addCase(thunks.deleteRecord.fulfilled, (state: TableState, action) => {
			if (state.mountedTable) {
				const updatedRows = state.mountedTable.records.filter(
					(record: any) => record._id !== action.meta.arg.rowId,
				)
				state.mountedTable = {
					...state.mountedTable,
					records: updatedRows,
				}
			}
		})
	},
})
