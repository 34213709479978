import React, { ComponentType, useMemo } from 'react'
import Modal, { ModalProps } from '@mui/material/Modal'
import {
	DataGridPro,
	GRID_REORDER_COL_DEF,
	GridColDef,
	GridRowOrderChangeParams,
} from '@mui/x-data-grid-pro'
import { useDispatch, useSelector } from 'react-redux'
import { SxProps } from '@mui/material'

import { dataGridStandard } from 'src/helpers/ui'
import {
	actions as blueprintActions,
	selectors as blueprintSelectors,
} from 'src/store/modules/blueprints-v3'
import { Box, Text } from 'src/components'
import { AsyncDispatchType } from 'src/store/types'
import { moveItemInArray } from 'src/helpers/operations'

type ReorderModalProps = Omit<ModalProps, 'children'> & {
	sectionId: string
	rows: { id: string; name: string; hierarchy: string[] }[]
	columns: GridColDef[]
	rowCount?: number
}

const style: SxProps = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 1200,
	maxWidth: '90vw',
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: 4,
	borderRadius: 2,
	maxHeight: '90vh',
	overflow: 'scroll',
}

const MIN_TASK_SIZE = 12

export const ReorderModal: ComponentType<ReorderModalProps> = ({
	sectionId,
	rows,
	columns,
	rowCount,
	...props
}) => {
	const dispatch = useDispatch<AsyncDispatchType>()
	const selectedBlueprint = useSelector(blueprintSelectors.getSelectedBlueprint)
	const shouldLazyLoad = !!rowCount && rowCount >= MIN_TASK_SIZE

	const handleRowOrderChange = async (params: GridRowOrderChangeParams) => {
		if (params.oldIndex === params.targetIndex || !selectedBlueprint?._id) return

		await dispatch(
			blueprintActions.updateStepOrder({
				_id: sectionId,
				blueprintId: selectedBlueprint._id,
				order: moveItemInArray(rows, params.oldIndex, params.targetIndex).map(({ id }) => id),
			}),
		)
	}

	const formattedColumns = useMemo(() => {
		const parentsColumn = columns.find(({ field }) => field === 'parents')
		if (!parentsColumn)
			return [GRID_REORDER_COL_DEF, { field: 'name', headerName: 'Name', flex: 1, sortable: false }]

		return [
			GRID_REORDER_COL_DEF,
			{ field: 'name', headerName: 'Name', flex: 1, sortable: false },
			{ ...parentsColumn, flex: 1 },
		]
	}, [columns])

	return (
		<Modal {...props}>
			<Box sx={style}>
				<Text variant="h6">Reorder tasks</Text>
				<Box height={shouldLazyLoad ? 500 : undefined}>
					<DataGridPro
						rowReordering
						rows={rows}
						columns={formattedColumns}
						onRowOrderChange={handleRowOrderChange}
						disableRowSelectionOnClick
						hideFooter
						disableColumnMenu
						getRowHeight={() => 'auto'}
						sx={{
							...dataGridStandard,
						}}
					/>
				</Box>
			</Box>
		</Modal>
	)
}
