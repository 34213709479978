import { PaletteOptions } from '@mui/material'

export const colors = {
	primary: {
		light: '#1976d2',
		main: '#2196f3',
	},
	info: {
		light: '#03a9f4',
		main: '#0288d1',
	},
	projectGreen: '#77B28C',
	blueprintBlue: '#42b1ed',
	status: {
		active: '#FDE12D',
		archived: '#449DD1',
		disabled: '#ccc',
	},
	success: {
		light: '#4caf50',
		main: '#2e7d32',
		dark: '#1b5e20',
	},
	error: {
		light: {
			20: 'rgba(239, 83, 80, 0.2)',
			50: '#e48080',
			100: '#ef5350',
		},
		main: '#d32f2f',
		dark: '#c62828',
	},
	warning: {
		light: '#ffb74d',
		main: '#ed6c02',
		dark: '#e65100',
	},
	logout: '#c05746',

	background: {
		modal: '#F6F8FC',
	},

	neutral: {
		0: '#ffffff',
		10: '#F6F8FC', // background
		20: '#F1F4F9',
		30: '#E2E8F0',
		40: '#CBD4E1', // borders
		50: '#94A3B8',
		60: '#64748B', // light fonts
		70: '#475569',
		80: '#27364B', // body fonts
		90: '#1E2A3B',
		100: '#0F1A2A', // dark fonts
	},

	sunglow: {
		10: '#FEF0CA',
		20: '#FDE195',
		30: '#FDD66D',
		40: '#FDD66D',
		60: '#FCCA46', // primary colour
		80: '#E2A703',
		100: '#886402',
	},

	feldgrau: {
		10: '#E1ECE9',
		20: '#A4C6BC',
		40: '#67A08F',
		60: '#3F6459', // main
		80: '#2D4840',
		100: '#1B2B26',
	},

	disabled: 'rgba(0, 0, 0, 0.26)',
}

export const palette: PaletteOptions = {
	primary: {
		main: colors.feldgrau['60'],
		contrastText: '#fff',
	},
	secondary: {
		main: '#f50057',
	},
	disabled: {
		main: colors.disabled,
		contrastText: '#000',
	},
	active: {
		main: colors.status.active,
		contrastText: '#000',
	},
	archived: {
		main: colors.status.archived,
		contrastText: '#fff',
	},
	feldgrau: {
		main: colors.feldgrau['60'],
		contrastText: colors.feldgrau['100'],
	},
	feldgrau40: {
		main: colors.feldgrau['40'],
		contrastText: '#fff',
	},
	borders: {
		main: colors.neutral['60'],
		contrastText: colors.neutral['80'],
	},
	neutral: {
		main: colors.neutral['50'],
		contrastText: '#fff',
	},
	sunglow: {
		main: colors.sunglow['40'],
		contrastText: colors.feldgrau['100'],
	},
}
