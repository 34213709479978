import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import isEmpty from 'lodash/isEmpty'

import * as configThunks from '../config/thunks'

import * as thunks from './thunks'
import { AuthState, UserOrganisationState } from './types'

export const initialState: AuthState = {
	userOrganisations: {},
	isAuthenticating: false,
	organisationState: UserOrganisationState.idle,
}

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setJwt(state: AuthState, action: PayloadAction<{ orgId: string; jwt: string }>) {
			state.userOrganisations[action.payload.orgId].jwt = action.payload.jwt
		},
		setOrganisations(state: AuthState, action: PayloadAction<AuthState['userOrganisations']>) {
			state.userOrganisations = action.payload
		},
		setLastVisitedOrganisationId(state: AuthState, action: PayloadAction<AuthState['lastVisitedOrganisationId']>) {
			state.lastVisitedOrganisationId = action.payload
		},
		endSession: () => initialState,
	},
	extraReducers: (builder) => {
		builder.addCase(thunks.ssoLogin.pending, (state: AuthState) => {
			state.isAuthenticating = true
		})
		builder.addCase(thunks.ssoLogin.fulfilled, (state: AuthState, action) => {
			const { organisationId, organisationName, jwt } = action.payload
			state.isAuthenticating = false
			state.userOrganisations[organisationId] = {
				name: organisationName,
				jwt,
			}
			if (!state.defaultOrganisationId) {
				state.defaultOrganisationId = organisationId
				state.lastVisitedOrganisationId = organisationId
			}
		})
		builder.addCase(thunks.switchOrganisation.fulfilled, (state: AuthState, action) => {
			const { organisationId, organisationName, jwt } = action.payload
			state.userOrganisations[organisationId] = {
				name: organisationName,
				jwt,
			}
		})

		builder.addCase(thunks.ssoLogin.rejected, (state: AuthState) => {
			state.userOrganisations = {}
			state.loginToken = ''
			state.isAuthenticating = false
		})

		builder.addCase(thunks.getOrganisations.pending, (state: AuthState) => {
			state.loginToken = ''
			state.organisationState = UserOrganisationState.pending
		})

		builder.addCase(thunks.getOrganisations.fulfilled, (state: AuthState, action) => {
			state.userOrganisations = action.payload.organisations
			state.loginToken = action.payload.loginToken
			state.organisationState = UserOrganisationState.fetched
		})

		builder.addCase(thunks.getOrganisations.rejected, (state: AuthState) => {
			state.loginToken = ''
			state.organisationState = UserOrganisationState.failed
			if (!isEmpty(state.userOrganisations)) {
				state.userOrganisations = {}
			}
		})

		builder.addCase(configThunks.updateSchema.fulfilled, (state: AuthState, action) => {
			const { organisationId, newToken } = action.payload
			state.userOrganisations[organisationId].jwt = newToken
		})

		builder.addCase(thunks.logoutUser.fulfilled, () => initialState)
		builder.addCase(thunks.fetchFirebaseToken.fulfilled, (state: AuthState, action) => {
			state.firebaseToken = action.payload.token
		})
		builder.addCase(thunks.updateUserOrganisations.fulfilled, (state, action) => {
			state.userOrganisations = action.payload.organisations
		})
	},
})
