import { V3BlueprintTypes, V3ClientTypes } from '@cango-app/types'
import Chip from '@mui/material/Chip'
import { ComponentType, useMemo, useState } from 'react'

import { Box } from './box'
import { Text } from './text'

type OptionsProps = {
	options: V3ClientTypes.Blueprint.Child[]
	isMenu: boolean
}

const Options: ComponentType<OptionsProps> = ({ options, isMenu }) => {
	const [showAll, setShowAll] = useState(false)
	const optionsToRender = useMemo(
		() =>
			options.filter(
				(child) =>
					child._id &&
					![V3BlueprintTypes.TaskPhase.Commence, V3BlueprintTypes.TaskPhase.Complete].includes(
						child._id as V3BlueprintTypes.TaskPhase,
					),
			),
		[JSON.stringify(options)],
	)

	return (
		<Box>
			{!!optionsToRender.length && (
				<Text fontSize={14} fontWeight={'bold'}>
					{isMenu ? 'Any combination of' : 'One of these options'}
				</Text>
			)}
			{optionsToRender.map((option, i) => {
				const hasTooManyOptions = optionsToRender.length >= 6
				if (!showAll && hasTooManyOptions && i === 5) {
					return (
						<Chip
							key={option._id}
							label={`... and ${optionsToRender.length - 5} more`}
							color={'primary'}
							variant={'outlined'}
							onClick={(event) => {
								setShowAll(true)
								event.stopPropagation()
							}}
							style={{
								marginTop: '3px',
								marginBottom: '3px',
								marginLeft: isMenu ? '-1px' : '3px',
								marginRight: isMenu ? '0px' : '3px',
								borderRadius: 1,
								backgroundColor: 'white',
							}}
						/>
					)
				}

				if (!showAll && hasTooManyOptions && i > 5) return null

				return (
					<Chip
						key={option._id}
						label={option.label}
						color={'primary'}
						variant={'outlined'}
						style={{
							marginTop: '3px',
							marginBottom: '3px',
							marginLeft: isMenu ? '-1px' : i === 0 ? '0px' : '3px',
							marginRight: isMenu ? '0px' : '3px',
							borderRadius: 1,
							backgroundColor: 'white',
						}}
					/>
				)
			})}
		</Box>
	)
}

export default Options
