import React, { ComponentType, useCallback, useEffect, useMemo } from 'react'
import {
	gridFilteredDescendantCountLookupSelector,
	GridRenderCellParams,
	useGridApiContext,
	useGridSelector,
} from '@mui/x-data-grid-pro'
import { ButtonProps } from '@mui/material/Button'
import FlagIcon from '@mui/icons-material/Flag'
import dayjs from 'dayjs'

import { Box, Button, Text } from 'src/components'
import { ChainMap } from 'src/store/modules/blueprints-v3'
import { ChevronDown } from 'src/assets/icons'

import { ChainsList } from './chains-list'

const isNavigationKey = (key: string) =>
	key === 'Home' ||
	key === 'End' ||
	key.indexOf('Arrow') === 0 ||
	key.indexOf('Page') === 0 ||
	key === ' '

export enum RowOption {
	edit = 'edit',
	sync = 'sync',
	addSubtask = 'add-subtask',
	changeSection = 'change-section',
	reOrder = 're-order',
	importTaskFromStep = 'import-task-from-step',
}

export type RowTitleProps = {
	selectedTaskId?: string
	onRowExpand?: (rowId: string, shouldExpand: boolean) => void
	isRowExpanded?: boolean
	name?: JSX.Element
	chains?: ChainMap
}

export const RowTitle: ComponentType<RowTitleProps & GridRenderCellParams> = ({
	selectedTaskId,
	isRowExpanded,
	onRowExpand,
	name,
	chains,
	...props
}) => {
	const { id, rowNode } = props
	const apiRef = useGridApiContext()
	const rowChildrenExpanded = rowNode.type === 'group' && rowNode.childrenExpanded
	const filteredDescendantCountLookup = useGridSelector(
		apiRef,
		gridFilteredDescendantCountLookupSelector,
	)

	const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0

	const handleOpenParentRow = useCallback(() => {
		if (selectedTaskId && rowNode.type === 'group') {
			const rowChildren = apiRef.current.getRowGroupChildren({
				groupId: rowNode.id,
			})
			if (rowChildren.includes(selectedTaskId)) {
				apiRef.current.setRowChildrenExpansion(id, true)
			}
		}
	}, [selectedTaskId, rowNode])

	const handleKeyDown: ButtonProps['onKeyDown'] = (event) => {
		if (event.key === ' ') {
			event.stopPropagation()
		}
		if (isNavigationKey(event.key) && !event.shiftKey) {
			apiRef.current.publishEvent('cellKeyDown', props, event)
		}
	}

	const handleClick: ButtonProps['onClick'] = (event) => {
		if (rowNode.type !== 'group') {
			return
		}

		apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded)
		if (onRowExpand) {
			onRowExpand(props.row.id, !rowNode.childrenExpanded)
		}
		event.stopPropagation()
	}

	const taskNameWithMilestone = useMemo(() => {
		if (props.row.type !== !props.row.dateAssigned) {
			return name
		}
		return (
			<>
				{name}: <b>{dayjs.unix(props.row.dateAssigned).format('MMM DD, YYYY')}</b>
			</>
		)
	}, [name, props.row.dateAssigned, props.row.type])

	useEffect(() => {
		handleOpenParentRow()
	}, [selectedTaskId])

	useEffect(() => {
		if (rowNode.type === 'group' && rowChildrenExpanded != isRowExpanded) {
			apiRef.current.setRowChildrenExpansion(props.row.id, !!isRowExpanded)
		}
	}, [isRowExpanded, rowChildrenExpanded])

	return (
		<Box sx={{ ml: rowNode.depth * 4 }} width="100%">
			<>
				{filteredDescendantCount > 0 ? (
					<Box display="flex" flexDirection="column" width="100%" position="relative">
						<Button
							onClick={handleClick}
							onKeyDown={handleKeyDown}
							tabIndex={-1}
							sx={{
								textTransform: 'none',
								px: 0,
								py: 1,
								fontSize: 14,
								textAlign: 'left',
								justifyContent: 'space-between',
							}}
							startIcon={
								props.row.isFlagged ? <FlagIcon fontSize="small" color="error" /> : undefined
							}
							endIcon={
								<Box display="flex" alignItems="center" mr={1}>
									<Text fontSize={12} mr={1}>
										({filteredDescendantCount})
									</Text>
									<ChevronDown
										style={{ transform: rowChildrenExpanded ? 'rotate(180deg)' : undefined }}
									/>
								</Box>
							}
							variant="text"
							fullWidth>
							{name}
						</Button>
						{!!chains?.size && (
							<ChainsList
								chains={Array.from(chains.values()).map((chain) => ({
									color: chain!.color,
									label: chain!.label,
								}))}
							/>
						)}
					</Box>
				) : (
					<Box display="flex" flexDirection="column" position="relative">
						<Text
							fontSize={14}
							sx={{
								py: 1,
								display: 'flex',
								whiteSpace: 'pre-line',
							}}>
							{!!props.row.isFlagged && <FlagIcon fontSize="small" color="error" />}{' '}
							{taskNameWithMilestone}
						</Text>
						{!!chains?.size && (
							<ChainsList
								chains={Array.from(chains.values()).map((chain) => ({
									color: chain!.color,
									label: chain!.label,
								}))}
							/>
						)}
					</Box>
				)}
			</>
		</Box>
	)
}
