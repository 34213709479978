import { Dispatch, createAsyncThunk } from '@reduxjs/toolkit'
import { TablesSdk } from '@cango-app/sdk'

import { showSnackbar } from 'src/helpers/snackbarManager'

import { errorHandler } from '../../../helpers/api'
import { selectors as authSelectors } from '../auth'
import { RootState } from '../../types'

export const fetchAllTables = createAsyncThunk<
	TablesSdk.GetTablesResponse,
	TablesSdk.GetTablesRequest,
	{ rejectValue: string; dispatch: Dispatch }
>('table/getAllTables', async (data, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState() as RootState
		const headers = authSelectors.getAuthHeaders(state)

		const response = await TablesSdk.getTables(import.meta.env.VITE_API as string, headers)

		return response
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue((error as Error).message)
	}
})

export const getTable = createAsyncThunk<
	TablesSdk.GetTableResponse,
	TablesSdk.GetTableRequest,
	{ rejectValue: string; dispatch: Dispatch }
>('table/getTable', async (data, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState() as RootState
		const headers = authSelectors.getAuthHeaders(state)
		const response = await TablesSdk.getTable(import.meta.env.VITE_API as string, headers, data)

		return response
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue((error as Error).message)
	}
})

export const getTableForProject = createAsyncThunk<
	TablesSdk.GetTableForProjectResponse,
	TablesSdk.GetTableForProjectRequest,
	{ rejectValue: string; dispatch: Dispatch }
>('table/getTableForProject', async (data, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState() as RootState
		const headers = authSelectors.getAuthHeaders(state)
		const response = await TablesSdk.getTableForProject(
			import.meta.env.VITE_API as string,
			headers,
			data,
		)

		return response
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue((error as Error).message)
	}
})

export const updateRecords = createAsyncThunk<
	TablesSdk.UpdateRecordsResponse,
	TablesSdk.UpdateRecordsRequest & {
		data?: {
			[key: string]: any
			[key: symbol]: any
		}
	},
	{ rejectValue: string; dispatch: Dispatch }
>('table/updateRecords', async (data, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState() as RootState
		const headers = authSelectors.getAuthHeaders(state)
		const response = await TablesSdk.updateRecords(
			import.meta.env.VITE_API as string,
			headers,
			data,
		)

		return response
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue((error as Error).message)
	}
})

export const updateColumnOrder = createAsyncThunk<
	TablesSdk.UpdateColumnOrderResponse,
	TablesSdk.UpdateColumnOrderRequest,
	{ rejectValue: string; dispatch: Dispatch }
>('table/updateColumnOrder', async (data, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState() as RootState
		const headers = authSelectors.getAuthHeaders(state)
		const response = await TablesSdk.updateColumnOrder(
			import.meta.env.VITE_API as string,
			headers,
			data,
		)

		return response
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue((error as Error).message)
	}
})

export const updateRowOrder = createAsyncThunk<
	TablesSdk.UpdateRowOrderResponse,
	TablesSdk.UpdateRowOrderRequest,
	{ rejectValue: string; dispatch: Dispatch }
>('table/updateRowOrder', async (data, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState() as RootState
		const headers = authSelectors.getAuthHeaders(state)
		const response = await TablesSdk.updateRowOrder(
			import.meta.env.VITE_API as string,
			headers,
			data,
		)

		return response
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue((error as Error).message)
	}
})

export const pasteData = createAsyncThunk<
	TablesSdk.PasteDataResponse,
	TablesSdk.PasteDataRequest,
	{ rejectValue: string; dispatch: Dispatch }
>('table/pasteData', async (data, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState() as RootState
		const headers = authSelectors.getAuthHeaders(state)
		const response = await TablesSdk.pasteData(import.meta.env.VITE_API as string, headers, data)
		return response
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue((error as Error).message)
	}
})

export const deleteRecord = createAsyncThunk<
	TablesSdk.DeleteRecordResponse,
	TablesSdk.DeleteRecordRequest,
	{ rejectValue: string; dispatch: Dispatch }
>('table/deleteRecord', async (data, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState() as RootState
		const headers = authSelectors.getAuthHeaders(state)
		const response = await TablesSdk.deleteRecord(import.meta.env.VITE_API as string, headers, data)

		return response
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue((error as Error).message)
	}
})

export const createTable = createAsyncThunk<
	TablesSdk.CreateTableResponse,
	Omit<TablesSdk.CreateTableRequest, 'version'>
>('table/createTable', async (data, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState() as RootState
		const headers = authSelectors.getAuthHeaders(state)
		const response = await TablesSdk.create(import.meta.env.VITE_API as string, headers, {
			...data,
		})
		return response
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue(error as any)
	}
})

export const addRow = createAsyncThunk<TablesSdk.AddRowResponse, TablesSdk.AddRowRequest>(
	'table/addRow',
	async (data, { getState, rejectWithValue, dispatch }) => {
		try {
			const state = getState() as RootState
			const headers = authSelectors.getAuthHeaders(state)
			const response = await TablesSdk.addRow(import.meta.env.VITE_API as string, headers, data)
			return response
		} catch (error) {
			errorHandler({ error, dispatch })
			return rejectWithValue(error as any)
		}
	},
)

export const addColumn = createAsyncThunk<TablesSdk.AddColumnResponse, TablesSdk.AddColumnRequest>(
	'table/addColumn',
	async (data, { getState, rejectWithValue, dispatch }) => {
		try {
			const state = getState() as RootState
			const headers = authSelectors.getAuthHeaders(state)
			const response = await TablesSdk.addColumn(import.meta.env.VITE_API as string, headers, data)
			return response
		} catch (error) {
			errorHandler({ error, dispatch })
			return rejectWithValue(error as any)
		}
	},
)

export const updateField = createAsyncThunk<
	TablesSdk.UpdateFieldResponse,
	TablesSdk.UpdateFieldRequest
>('table/updateField', async (data, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState() as RootState
		const headers = authSelectors.getAuthHeaders(state)
		const response = await TablesSdk.updateField(import.meta.env.VITE_API as string, headers, data)
		return response
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue(error as any)
	}
})

export const updateTableName = createAsyncThunk<
	{ tableId: string; tableName: string },
	TablesSdk.UpdateNameRequest
>('table/updateTableName', async (data, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState() as RootState
		const headers = authSelectors.getAuthHeaders(state)

		await TablesSdk.updateName(import.meta.env.VITE_API as string, headers, data)

		return { tableId: data.tableId, tableName: data.name }
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue(error as any)
	}
})

export const lockField = createAsyncThunk<
	TablesSdk.LockFieldResponse,
	TablesSdk.LockFieldRequest & { fieldName: string }
>('table/lockField', async (data, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState() as RootState
		const headers = authSelectors.getAuthHeaders(state)
		const response = await TablesSdk.lockField(import.meta.env.VITE_API as string, headers, data)
		const { lock, fieldName } = data
		showSnackbar(`${fieldName} field ${lock ? 'locked' : 'unlocked'}`, { variant: 'success' })
		return response
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue(error as any)
	}
})

export const deleteField = createAsyncThunk<
	TablesSdk.DeleteFieldResponse,
	TablesSdk.DeleteFieldRequest
>('table/deleteField', async (data, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState() as RootState
		const headers = authSelectors.getAuthHeaders(state)
		const response = await TablesSdk.deleteField(import.meta.env.VITE_API as string, headers, data)
		return response
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue(error as any)
	}
})

export const deleteTable = createAsyncThunk<
	TablesSdk.DeleteTableResponse,
	TablesSdk.DeleteTableRequest,
	{
		state: RootState
		dispatch: Dispatch
	}
>('table/deleteTable', async (data, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState()
		const headers = authSelectors.getAuthHeaders(state)
		const response = await TablesSdk.deleteTable(import.meta.env.VITE_API as string, headers, data)
		return response
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue(error as any)
	}
})
