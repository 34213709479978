import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _uniqBy from 'lodash/uniqBy'
import { FilesSdk } from '@cango-app/sdk'

import { FilesState } from './types'

export const initialState: FilesState = {}

export const filesSlice = createSlice({
	name: 'files',
	initialState,
	reducers: {
		setProjectFiles: (state, action: PayloadAction<{ id: string; files: FilesSdk.File[] }>) => {
			const { id, files } = action.payload
			state[id] = _uniqBy(files, 'id')
		},
		setAddNewFilesToProjectFiles: (
			state,
			action: PayloadAction<{ id: string; files: FilesSdk.File[] }>,
		) => {
			const { id, files } = action.payload
			state[id] = _uniqBy([...state[id], ...files], 'id')
		},
		removeProjectFilesReference: (state, action: PayloadAction<string>) => {
			delete state[action.payload]
		},
	},
})
