import { ComponentType } from 'react'

import { colors } from 'src/theme/colors'

import { IconType } from './index'

export const SwitchVertical: ComponentType<IconType> = ({
	width = 24,
	stroke = colors.feldgrau['80'],
	...props
}) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} fill="none" viewBox="0 0 24 24" {...props}>
		<path
			stroke={stroke}
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="2"
			d="M7 16V4m0 0L3 8m4-4l4 4m6 0v12m0 0l4-4m-4 4l-4-4"></path>
	</svg>
)
