import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import _set from 'lodash/set'
import { Path } from 'react-hook-form'
import { V3ProjectSdk } from '@cango-app/sdk'

import * as blueprintThunks from '../blueprints-v3/thunks'

import { ListedTask, ProjectFilesState, ProjectsStateV3 } from './types'
import * as thunks from './thunks'

const initialState: ProjectsStateV3 = {
	projects: {},
	projectCards: [],
	projectFiles: [],
	projectFilesState: ProjectFilesState.Idle,
	isFetchingProjectTasks: false,
}

export const projectsSliceV3 = createSlice({
	name: 'projects-v3',
	initialState,
	reducers: {
		endSession: () => initialState,
		setSelectedTaskId: (state: ProjectsStateV3, action: PayloadAction<string | undefined>) => {
			state.selectedTaskId = action.payload
		},
		resetFiles: (state: ProjectsStateV3) => {
			state.projectFiles = []
			state.projectFilesState = ProjectFilesState.Idle
		},
		clearSelectedProject: (state: ProjectsStateV3) => {
			state.selectedProjectId = undefined
			state.selectedTaskId = undefined
		},
		resetTask: (
			state: ProjectsStateV3,
			action: PayloadAction<
				V3ProjectSdk.ResetTaskResponse & V3ProjectSdk.ResetTaskRequest & { projectId: string }
			>,
		) => {
			const { taskId, updates, projectId } = action.payload
			const { task, newTasks } = action.payload
			state.projects[projectId].tasks = [
				...state.projects[projectId].tasks.reduce((acc: ListedTask[], _task) => {
					if (updates.tasksToDelete.includes(_task._id)) {
						return acc
					}
					if (_task._id === taskId) {
						acc.push(task)
						return acc
					}
					acc.push(_task)
					return acc
				}, []),
				...newTasks,
			]
		},
		updateStoreTask: (
			state: ProjectsStateV3,
			action: PayloadAction<{
				projectId: string
				taskId: string
				key: Path<ListedTask>
				value: any
			}>,
		) => {
			const { projectId, taskId, key, value } = action.payload
			const project = state.projects[projectId]
			const taskIndex = project.tasks.findIndex(({ _id }) => _id === taskId)
			if (taskIndex < 0) return
			_set(project.tasks[taskIndex], key, value)
		},
	},
	extraReducers: (builder) => {
		builder.addCase(thunks.getCards.fulfilled, (state: ProjectsStateV3, action) => {
			state.projectCards = action.payload
		})
		builder.addCase(thunks.getProject.fulfilled, (state: ProjectsStateV3, action) => {
			state.projects[action.payload._id] = {
				...action.payload,
				tasks: state.projects[action.payload._id]?.tasks ?? [],
			}
			state.selectedProjectId = action.payload._id
		})
		builder.addCase(thunks.getProjectTasks.pending, (state: ProjectsStateV3) => {
			state.isFetchingProjectTasks = true
		})
		builder.addCase(thunks.getProjectTasks.fulfilled, (state: ProjectsStateV3, action) => {
			const projectId = action.meta.arg.projectId
			state.projects[projectId].tasks = action.payload
			state.isFetchingProjectTasks = false
		})
		builder.addCase(thunks.getProjectTasks.rejected, (state: ProjectsStateV3) => {
			state.isFetchingProjectTasks = false
		})
		builder.addCase(thunks.archive.fulfilled, (state: ProjectsStateV3, action) => {
			const { projectId, archivedAt } = action.payload
			if (!state.projects[projectId]) return
			state.projects[projectId].archived.at = archivedAt
		})
		builder.addCase(thunks.completeTask.fulfilled, (state: ProjectsStateV3, action) => {
			const { projectId, tasks, taskId, optionIds } = action.payload
			const presentTasks = state.projects[projectId].tasks.map((task) =>
				task._id === taskId
					? {
							...task,
							lifecycle: {
								complete: !task.isMultiUse,
								completed_at: task.lifecycle.completed_at
									? [...task.lifecycle.completed_at, dayjs().unix()]
									: [dayjs().unix()],
								completed_options: optionIds ?? [],
							},
						}
					: task,
			)
			state.projects[projectId] = {
				...state.projects[projectId],
				tasks: [...presentTasks, ...tasks],
			}
		})
		builder.addCase(thunks.restoreFromArchive.fulfilled, (state: ProjectsStateV3, action) => {
			const { projectId } = action.meta.arg
			if (!state.projects[projectId]) return
			state.projects[projectId] = {
				...state.projects[projectId],
				archived: {
					state: false,
				},
			}
		})
		builder
			.addCase(thunks.fetchProjectFiles.pending, (state) => {
				state.projectFilesState = ProjectFilesState.Loading
			})
			.addCase(thunks.fetchProjectFiles.fulfilled, (state, action) => {
				state.projectFiles = action.payload
				state.projectFilesState = ProjectFilesState.Idle
			})
			.addCase(thunks.fetchProjectFiles.rejected, (state) => {
				state.projectFilesState = ProjectFilesState.Error
			})
		builder.addCase(thunks.updateTask.fulfilled, (state, action) => {
			const { projectId } = action.meta.arg
			const newTask = action.payload
			const project = state.projects[projectId]
			const taskIndex = project.tasks.findIndex(({ _id }) => _id === newTask._id)
			if (taskIndex < 0) return
			project.tasks[taskIndex] = newTask
		})
		builder.addCase(thunks.assignUserToRole.fulfilled, (state, action) => {
			const { project } = action.payload
			state.projects[project._id] = {
				...state.projects[project._id],
				...project,
			}
		})
		builder.addCase(thunks.updateProject.fulfilled, (state, action) => {
			const {
				response: { _id },
			} = action.payload
			state.projects[_id] = {
				...state.projects[_id],
				...action.payload.response,
			}
		})
		builder.addCase(thunks.deleteProject.fulfilled, (state, action) => {
			const { projectId } = action.payload
			delete state.projects[projectId]
			const cardIndex = state.projectCards.findIndex((card) => card._id === projectId)
			state.projectCards.splice(cardIndex, 1)
		})
		builder.addCase(thunks.assignContactToExternal.fulfilled, (state, action) => {
			const { projectId } = action.meta.arg
			const externals = action.payload
			const project = state.projects[projectId]
			project.externals = [...externals]
		})
		builder.addCase(thunks.createNewTask.fulfilled, (state, action) => {
			const { project_id } = action.meta.arg
			const { task } = action.payload
			const project = state.projects[project_id]
			if (!project) return
			project.tasks = [...(project.tasks ?? []), task]
		})
		builder.addCase(blueprintThunks.updateLiveTasks.fulfilled, (state, action) => {
			const tasks = action.payload
			tasks.forEach((task) => {
				const { project_id } = task
				const project = state.projects[project_id]
				if (!project || !project.tasks) return
				const taskIndex = project.tasks.findIndex(({ _id }) => _id === task._id)
				if (taskIndex < 0) return
				project.tasks[taskIndex] = task
			})
		})
		builder.addCase(thunks.getUpdatedDependencies.fulfilled, (state, action) => {
			const tasks = action.payload
			tasks.forEach((task) => {
				const { project_id } = task
				const project = state.projects[project_id]
				if (!project || !project.tasks) return
				const taskIndex = project.tasks.findIndex(({ _id }) => _id === task._id)
				if (taskIndex < 0) return
				project.tasks[taskIndex] = task
			})
		})
		builder.addCase(thunks.getProjectNames.fulfilled, (state, action) => {
			const projects = action.payload
			projects.forEach((project) => {
				const existingProjectEntry = state.projects[project._id] ?? {}
				state.projects[project._id] = {
					...existingProjectEntry,
					...project,
				}
			})
		})
		builder.addCase(thunks.flagTaskfromProjectView.fulfilled, (state, action) => {
			const { taskId } = action.meta.arg
			const { projectId } = action.payload
			const project = state.projects[projectId]
			const blockedTaskIndex = project.tasks.findIndex(({ _id }) => _id === taskId)
			if (blockedTaskIndex === -1) {
				return
			}
			state.projects[projectId].tasks[blockedTaskIndex].isFlagged = true
		})
		builder.addCase(thunks.resolveFlaggedTaskFromProjectView.fulfilled, (state, action) => {
			const { projectId, taskId } = action.meta.arg
			const project = state.projects[projectId]
			const blockedTaskIndex = project.tasks.findIndex(({ _id }) => _id === taskId)
			if (blockedTaskIndex === -1) {
				return
			}
			state.projects[projectId].tasks[blockedTaskIndex].isFlagged = false
		})
	},
})
