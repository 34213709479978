import { createSelector } from '@reduxjs/toolkit'
import { TablesSdk } from '@cango-app/sdk'
import _orderBy from 'lodash/orderBy'

import { RootState } from '../../types'

const getTableState = (state: RootState) => state.cangoTables

const getAllTables: (state: RootState) => TablesSdk.MenuTable[] = createSelector(
	getTableState,
	({ tables }) => tables,
)

const getTablesList = createSelector(getAllTables, (tables) => {
	const list = tables.map((table) => ({ _id: table._id, label: table.name }))
	return _orderBy(list, ['label'])
})

const isLoadingTables = createSelector(getTableState, ({ isLoadingTables }) => isLoadingTables)

const getMountedTable = createSelector(getTableState, ({ mountedTable }) => mountedTable)

const getMountedTableId = createSelector(getTableState, ({ mountedTable }) => mountedTable?._id)

const getTableIdLoading = createSelector(getTableState, ({ tableIdLoading }) => tableIdLoading)

export const selectors = {
	getAllTables,
	isLoadingTables,
	getMountedTable,
	getMountedTableId,
	getTableIdLoading,
	getTablesList,
}
