import React, { ComponentType, useEffect, useState } from 'react'
import { FileUploader } from 'react-drag-drop-files'
import Grow from '@mui/material/Grow'
import { PulseLoader } from 'react-spinners'
import { useGoogleLogin } from '@react-oauth/google'

import { DocumentUpload } from 'src/assets/icons'
import { useIsMobile } from 'src/hooks/useMobile'
import { FileData, useFileUpload } from 'src/hooks/useFileUpload'
import { showSnackbar } from 'src/helpers/snackbarManager'

import { Button } from '../button'
import { Box } from '../box'
import { Text } from '../text'

import { StagedFile } from './stagedFile'

type DriveUploadProps = {
	parentFolderId: string
	onFilesUploaded: (fileIds: string[]) => void
	defaultFilesToUpload?: FileData[]
}

enum FormState {
	uploadRequired = 'uploadRequired',
	nameConfirmation = 'nameConfirmation',
}

export const UploadPanel: ComponentType<DriveUploadProps> = ({
	parentFolderId,
	onFilesUploaded,
	defaultFilesToUpload = [],
}) => {
	const isMobile = useIsMobile()
	const [formState, setFormState] = useState<FormState>(
		defaultFilesToUpload ? FormState.nameConfirmation : FormState.uploadRequired,
	)
	const [isFileHovering, setIsFileHovering] = useState(false)
	const [isSavingToken, setIsSavingToken] = useState(false)
	const {
		stagedFiles,
		isUploadingComplete,
		onStageFiles,
		onChangeStagedFileName,
		onRemoveStagedFile,
		areFilesUploading,
		onUploadStagedFiles,
		hasUploadError,
		fetchingDrivePermission,
		shouldRequestDrivePermission,
		onSaveAccessToken,
	} = useFileUpload({ parentFolderId, defaultFilesToUpload })
	const googleLogin = useGoogleLogin({
		flow: 'auth-code',
		onSuccess: async (credentials) => {
			setIsSavingToken(true)
			await onSaveAccessToken(credentials.code)
			setIsSavingToken(false)
		},
		scope: 'https://www.googleapis.com/auth/drive.file',
		onError: (errorResponse) => {
			showSnackbar(errorResponse.error_description, { variant: 'error' })
		},
	})

	const handleStagingFinished = () => {
		if (!stagedFiles.length) return
		setTimeout(() => {
			setFormState(FormState.nameConfirmation)
		}, 500)
	}

	useEffect(() => {
		if (isUploadingComplete) {
			const successfulUploads = stagedFiles
				.filter((file) => !!file.fileId)
				.map((file) => file.fileId as string)
			onFilesUploaded(successfulUploads)
		}
	}, [isUploadingComplete])

	useEffect(() => {
		if (!stagedFiles.length && formState === FormState.nameConfirmation) {
			setFormState(FormState.uploadRequired)
		}
	}, [stagedFiles, formState])

	if (fetchingDrivePermission) {
		return (
			<Box display="flex" justifyContent="center" mt={5}>
				<PulseLoader size={8} />
			</Box>
		)
	}

	if (shouldRequestDrivePermission) {
		return (
			<Box display="flex" justifyContent="center" flexDirection="column" alignItems="center" mt={5}>
				<Text mb={2}>
					For Cango to upload files to Google Drive through Cango, Google requires your permission
				</Text>
				<Button variant="contained" onClick={() => googleLogin()} isLoading={isSavingToken}>
					Allow access to Google Drive
				</Button>
			</Box>
		)
	}

	return (
		<Box display="flex" flexDirection="column" justifyContent="space-between" pt={3} px={2}>
			<Box>
				{!defaultFilesToUpload.length && (
					<Grow in={!stagedFiles.length} unmountOnExit addEndListener={handleStagingFinished}>
						<div>
							<Box mb={3}>
								<FileUploader
									handleChange={onStageFiles}
									classes="drop_area"
									onDraggingStateChange={setIsFileHovering}
									dropMessageStyle={{ backgroundColor: '#42a5f5' }}
									hoverTitle=" "
									multiple>
									<Box
										border="1.5px dashed black"
										borderRadius={2}
										display="flex"
										justifyContent="center"
										alignItems="center"
										flexDirection="column"
										py={2}
										sx={{ cursor: 'pointer' }}>
										<DocumentUpload />
										<Text
											variant="h6"
											fontWeight="bold"
											color={isFileHovering ? 'error' : 'primary'}>
											{isFileHovering ? 'Drop it like its hot!' : 'Select file(s) to upload'}
										</Text>
										<Text>or drag and drop here</Text>
									</Box>
								</FileUploader>
							</Box>
						</div>
					</Grow>
				)}
				{formState === FormState.nameConfirmation &&
					stagedFiles.map((fileData, index) => {
						return (
							<Grow key={`${fileData.file.name}-${index}`} in style={{ transformOrigin: 'left' }}>
								<div>
									<StagedFile
										fileData={fileData}
										onChangeName={(newName) => onChangeStagedFileName(index, newName)}
										onRemoveFile={() => onRemoveStagedFile(index)}
									/>
								</div>
							</Grow>
						)
					})}
			</Box>
			<Box display="flex" justifyContent={{ laptop: 'flex-end' }} width="100%">
				<Grow
					in={!areFilesUploading && !hasUploadError && formState === FormState.nameConfirmation}
					style={{ transformOrigin: 'left' }}>
					<div style={{ width: '100%', marginBottom: 12 }}>
						<Button variant="contained" onClick={onUploadStagedFiles} fullWidth={isMobile}>
							Upload
						</Button>
					</div>
				</Grow>
			</Box>
		</Box>
	)
}
