import { V3BlueprintTypes } from '@cango-app/types'
import { createSelector } from '@reduxjs/toolkit'
import { V3BlueprintSdk } from '@cango-app/sdk'
import _uniqBy from 'lodash/uniqBy'
import { v4 } from 'uuid'

import { getStepChain, sortDocuments } from 'src/helpers/chains'
import { GroupedSelectOption } from 'src/components'

import type { RootState } from '../../types'

import { ChainMap, V3BlueprintStep } from './types'

const getBlueprintsState: (state: RootState) => RootState['cangoBlueprintsV3'] = createSelector(
	(state: RootState) => state.cangoBlueprintsV3,
	(blueprintState) => blueprintState,
)

const getCards: (state: RootState) => V3BlueprintSdk.Card[] = createSelector(
	getBlueprintsState,
	({ blueprintCards }) => blueprintCards,
)

const getMenuChips = createSelector(getCards, (cards) =>
	cards.map(({ _id, name, active }) => ({ _id, name, active })),
)

const getBlueprintIds: (state: RootState) => string[] = createSelector(
	getBlueprintsState,
	({ blueprints }) => Object.keys(blueprints) || [],
)

const getBlueprints = createSelector(getBlueprintsState, ({ blueprints }) => blueprints || {})

const getSelectedBlueprintId = createSelector(
	getBlueprintsState,
	({ selectedBlueprintId }) => selectedBlueprintId,
)

const getSelectedBlueprintDatabaseTable = createSelector(
	getBlueprintsState,
	({ selectedBlueprintId, blueprints }) => {
		if (!selectedBlueprintId) return
		return blueprints[selectedBlueprintId]?.database_table
	},
)

const getSelectedSectionId = createSelector(
	getBlueprintsState,
	({ selectedSectionId }) => selectedSectionId,
)

const getSelectedStepId = createSelector(getBlueprintsState, ({ selectedStepId }) => selectedStepId)

const getSelectedBlueprint = createSelector(
	getBlueprints,
	getSelectedBlueprintId,
	(blueprints, selectedBlueprintId) => {
		if (!selectedBlueprintId) return
		return blueprints[selectedBlueprintId]
	},
)

const getSelectedSectionOrder = createSelector(getSelectedBlueprint, (blueprint) => {
	if (!blueprint) return []
	return blueprint.section_order ?? []
})

const getBlueprintSteps: (state: RootState) => V3BlueprintStep[] = createSelector(
	getSelectedBlueprint,
	(blueprint) => {
		if (!blueprint) return []
		const mappedSteps = new Map(blueprint.steps.map((step) => [step._id, step]))
		return blueprint.steps.map((step) => ({
			...step,
			chains: getStepChain({ step, allSteps: mappedSteps }),
		}))
	},
)

const getMappedSteps = createSelector(getBlueprintSteps, (steps) => {
	return new Map(steps.map((step) => [step._id, step]))
})

const getBlueprintStepsWithDescendants = createSelector(
	getBlueprintSteps,
	(steps: V3BlueprintStep[]): Map<string, V3BlueprintStep[]> => {
		const taskMap = new Map<string, V3BlueprintStep>(steps.map((step) => [step._id, step]))
		const descendantsMap = new Map<string, V3BlueprintStep[]>()

		const recursivelyGetChildren = (parentId: string, currentDescendants: Set<string>) => {
			const parent = taskMap.get(parentId)
			if (!parent || !parent.children) return

			parent.children.forEach((child) => {
				child.steps.forEach((childId) => {
					if (!currentDescendants.has(childId)) {
						currentDescendants.add(childId)
						const childStep = taskMap.get(childId)
						if (childStep) {
							recursivelyGetChildren(childId, currentDescendants)
						}
					}
				})
			})
		}

		steps.forEach((step) => {
			const currentDescendantsIds = new Set<string>()
			recursivelyGetChildren(step._id, currentDescendantsIds)
			const currentDescendantsSteps = Array.from(currentDescendantsIds).map(
				(id) => taskMap.get(id)!,
			)
			descendantsMap.set(step._id, currentDescendantsSteps)
		})

		return descendantsMap
	},
)

const getBeginsWithSteps: (state: RootState) => V3BlueprintStep[] = createSelector(
	getSelectedBlueprint,
	(blueprint) => {
		if (!blueprint) return []
		const beginsWithSteps = blueprint.steps.filter(
			({ _id, isSection }) => !isSection && blueprint.begins_with.includes(_id),
		)

		return beginsWithSteps
	},
)

interface Section extends V3BlueprintStep {
	steps: V3BlueprintStep[]
}

const getSections = createSelector(
	getSelectedSectionOrder,
	getBlueprintSteps,
	(sectionOrder, steps) => {
		const filteredSteps = steps.filter(({ isSection }) => isSection)
		return sortDocuments(filteredSteps, sectionOrder)
	},
)

const getSectionsWithDescendants: (state: RootState) => Section[] = createSelector(
	getSelectedBlueprint,
	getBlueprintSteps,
	(blueprint, blueprintSteps) => {
		const { sectionMap, steps } = blueprintSteps.reduce(
			(acc: { sectionMap: Map<string, Section>; steps: V3BlueprintStep[] }, step) => {
				if (step.isSection) {
					acc.sectionMap.set(step._id, {
						...step,
						steps: [],
					})
				} else {
					acc.steps.push(step)
				}
				return acc
			},
			{
				sectionMap: new Map(),
				steps: [],
			},
		)

		steps.forEach((step) => {
			const section = sectionMap.get(step.section_id || '')
			if (section) {
				section.steps.push(step)
			}
		})

		const sectionsWithDescendants = Array.from(sectionMap.values())

		const sectionsWithOrderedDescendants = sectionsWithDescendants.map((section) => ({
			...section,
			steps: sortDocuments(section.steps, section.childOrder ?? []),
		}))

		return sortDocuments(sectionsWithOrderedDescendants, blueprint?.section_order ?? [])
	},
)

const getSectionIds = createSelector(getSections, (sections) => {
	return sections.map(({ _id }) => _id)
})

const getSectionNames = createSelector(getSections, (sections) => {
	return sections.map(({ _id, name }) => ({ _id, label: name }))
})

const getSelectedSection = createSelector(
	getSelectedSectionId,
	getMappedSteps,
	(selectedSectionId, stepMap) => {
		if (!selectedSectionId) return
		return stepMap.get(selectedSectionId)
	},
)

const getSelectedStep = createSelector(
	getSelectedStepId,
	getMappedSteps,
	(selectedStepId, stepMap) => {
		if (!selectedStepId) return
		return stepMap.get(selectedStepId)
	},
)

export const parentOrSectionOptions = [
	{
		_id: V3BlueprintTypes.TaskPhase.Commence,
		label: 'Start',
	},
	{
		_id: V3BlueprintTypes.TaskPhase.Complete,
		label: 'Completion',
	},
]

const getBlueprintSelectDependancyOptions: (
	state: RootState,
	shouldShowSections?: boolean,
) => {
	stepOptions: { _id: string; label: string; isMenu: boolean; isBlockedType: boolean }[]
	stepChildren: Map<string, { hasExtraOptions: boolean; options: { _id: string; label: string }[] }>
	parentsAndSections: Set<string>
} = createSelector(
	getBlueprintSteps,
	getSelectedStepId,
	getSelectedStep,
	getSelectedSection,
	(state: RootState, shouldShowSections?: boolean) => shouldShowSections,
	(steps, selectedStepId, selectedStep, selectedSection, manualShouldShowSections) => {
		const stepChildren = new Map<
			string,
			{ hasExtraOptions: boolean; options: { _id: string; label: string }[] }
		>()
		const parentsAndSections = new Set<string>()
		const shouldShowSections = manualShouldShowSections ?? !!selectedSection

		const filteredSteps = steps.filter((step) => {
			if (step._id === selectedStepId || step._id === selectedSection?._id) return false
			if (!shouldShowSections && step.isSection) return false
			if (!selectedStepId) return true
			const isSelectedTaskParentOfStep = step.parents.map(({ _id }) => _id).includes(selectedStepId)
			const isSubtask = selectedStep?.children
				.find(({ _id }) => _id === V3BlueprintTypes.TaskPhase.Commence)
				?.steps.includes(step._id)
			const stepHasExtraOptions = step.children.some(
				(child) =>
					![V3BlueprintTypes.TaskPhase.Commence, V3BlueprintTypes.TaskPhase.Complete].includes(
						child._id as V3BlueprintTypes.TaskPhase,
					),
			)
			if (stepHasExtraOptions && !isSubtask) {
				return true
			}

			return !isSelectedTaskParentOfStep && !isSubtask
		})

		const stepOptions = filteredSteps.map((step) => {
			if (!step.isParent && !step.isSection) {
				const hasExtraOptions = step.children.some(
					(child) =>
						![V3BlueprintTypes.TaskPhase.Commence, V3BlueprintTypes.TaskPhase.Complete].includes(
							child._id as V3BlueprintTypes.TaskPhase,
						),
				)

				const options = step.children.filter((child) => {
					if (hasExtraOptions) {
						return ![
							V3BlueprintTypes.TaskPhase.Commence,
							V3BlueprintTypes.TaskPhase.Complete,
						].includes(child._id as V3BlueprintTypes.TaskPhase)
					}
					return child._id !== V3BlueprintTypes.TaskPhase.Commence
				})

				stepChildren.set(step._id, { hasExtraOptions, options })
			} else {
				stepChildren.set(step._id, { options: parentOrSectionOptions, hasExtraOptions: false })
				parentsAndSections.add(step._id)
			}
			return {
				_id: step._id,
				label: step.name,
				isMenu: step.isMenu,
				isBlockedType: step.task_type === V3BlueprintTypes.TaskType.BlockType,
			}
		})

		return {
			stepOptions,
			stepChildren,
			parentsAndSections,
		}
	},
)

const getOrphanedSteps = createSelector(
	[getBlueprintSteps, getBeginsWithSteps, getSections],
	(steps, beginsWith, sections) => {
		const sectionMap = new Map(sections.map((section) => [section._id, section]))

		return steps.filter((step) => {
			if (step.isSection) return false
			if (beginsWith.some(({ _id }) => _id === step._id)) return false
			return !sectionMap.has(step.section_id || '')
		})
	},
)

const getStepDescendants: (state: RootState, stepId?: string) => V3BlueprintStep[] = createSelector(
	getBlueprintStepsWithDescendants,
	(state: RootState, stepId?: string) => stepId,
	(mappedSteps, stepId) => {
		if (!stepId) return []
		const descendants = mappedSteps.get(stepId)
		if (!descendants) return []
		return descendants.filter(Boolean)
	},
)

const getBlueprintStepsWithFiles: (state: RootState) => {
	stepIds: Set<string>
	options: { _id: string; label: string }[]
} = createSelector(getBlueprintSteps, getSelectedStepId, (steps, selectedStepId) => {
	const stepIds: Set<string> = new Set()
	const options: Map<string, { _id: string; label: string }> = new Map()
	steps.forEach((step) => {
		Object.keys(step.actions).forEach((key) => {
			const actions = step.actions[key]
			actions.forEach((_action) => {
				if (
					step._id !== selectedStepId &&
					[
						V3BlueprintTypes.ActionEnum.FileTemplate,
						V3BlueprintTypes.ActionEnum.FileUpload,
					].includes(_action.type)
				) {
					stepIds.add(step._id)
					options.set(step._id, {
						_id: step._id,
						label: step.name,
					})
				}
			})
		})
	})

	return { stepIds, options: [...options.values()] }
})

const getBlueprintStepsWithLinks: (state: RootState) => {
	stepIds: Set<string>
	options: { _id: string; label: string }[]
} = createSelector(getBlueprintSteps, getSelectedStepId, (steps, selectedStepId) => {
	const stepIds: Set<string> = new Set()
	const options: Map<string, { _id: string; label: string }> = new Map()
	steps.forEach((step) => {
		Object.keys(step.actions).forEach((key) => {
			const actions = step.actions[key]
			actions.forEach((_action) => {
				if (step._id !== selectedStepId && _action.type === V3BlueprintTypes.ActionEnum.Software) {
					stepIds.add(step._id)
					options.set(step._id, {
						_id: step._id,
						label: step.name,
					})
				}
			})
		})
	})

	return { stepIds, options: [...options.values()] }
})

const isBlueprintActive: (state: RootState, blueprintId?: string | null) => boolean =
	createSelector(getSelectedBlueprint, (blueprint) => !!blueprint?.active)

const getStep: (
	state: RootState,
	blueprintId: string,
	stepId?: string,
) => V3BlueprintStep | undefined = createSelector(
	getBlueprintSteps,
	(state: RootState, blueprint_id: string, step_id?: string) => step_id,
	(blueprintSteps, stepId) => {
		return blueprintSteps.find((step) => step._id === stepId)
	},
)

const getDriveIdByBlueprintId: (state: RootState, blueprintId: string) => string | undefined =
	createSelector(getSelectedBlueprint, (blueprint) => blueprint?.googleDriveId)

const getBlueprintNameById: (state: RootState, blueprintId: string) => string | undefined =
	createSelector(getSelectedBlueprint, (blueprint) => blueprint?.name)

const getStepChainOptions: (state: RootState, stepChains?: ChainMap) => GroupedSelectOption[] =
	createSelector(
		getMappedSteps,
		getSelectedStep,
		(state: RootState, stepChains?: ChainMap) => stepChains,
		(mappedSteps, selectedStep, _stepChains) => {
			if (!selectedStep && !_stepChains) return []
			const stepChains = _stepChains ?? selectedStep?.chains ?? (new Map() as ChainMap)
			const chainOrigins = [...(stepChains.keys() ?? [])]
			return chainOrigins.reduce((options: GroupedSelectOption[], chainOrigin) => {
				const originStep = mappedSteps.get(chainOrigin)
				if (!originStep) return options
				const extraOptions = originStep.parents.reduce((options: GroupedSelectOption[], parent) => {
					const parentExtraOptions = parent.options.filter(
						(option) =>
							![V3BlueprintTypes.TaskPhase.Commence, V3BlueprintTypes.TaskPhase.Complete].includes(
								option._id as V3BlueprintTypes.TaskPhase,
							),
					)
					if (!parentExtraOptions.length) {
						return options
					}

					return [...options, { groupName: parent.name, options: parentExtraOptions }]
				}, [])
				return _uniqBy([...options, ...extraOptions], 'groupName')
			}, [])
		},
	)

const getTempStepChainOptions = createSelector(
	getBlueprintSteps,
	(state: RootState, formParents: V3BlueprintSdk.Parent[], formSectionId?: string) => ({
		state,
		formParents,
		formSectionId,
	}),
	(steps, { state, formParents, formSectionId }) => {
		const formParentsCopy = [...formParents]
		if (!formParents.length && formSectionId) {
			formParentsCopy.push({ _id: formSectionId, options: [], name: '' })
		}
		const stepChain = getStepChain({
			step: {
				_id: v4(),
				parents: formParentsCopy,
				chain: undefined,
			},
			allSteps: new Map(steps.map((_blueprintStep) => [_blueprintStep._id, _blueprintStep])),
		})
		return getStepChainOptions(state, stepChain)
	},
)

export const selectors = {
	getCards,
	getMenuChips,
	getBlueprintIds,
	getSelectedBlueprint,
	getBlueprintSteps,
	getBeginsWithSteps,
	getSections,
	getSectionNames,
	getStepDescendants,
	getBlueprintStepsWithFiles,
	isBlueprintActive,
	getStep,
	getDriveIdByBlueprintId,
	getBlueprintNameById,
	getOrphanedSteps,
	getSelectedSection,
	getSelectedBlueprintId,
	getSelectedBlueprintDatabaseTable,
	getMappedSteps,
	getSelectedStep,
	getBlueprintSelectDependancyOptions,
	getSectionsWithDescendants,
	getSectionIds,
	getStepChainOptions,
	getTempStepChainOptions,
	getBlueprintStepsWithLinks,
}
