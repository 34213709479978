import React, { ComponentType, memo } from 'react'
import { V3BlueprintTypes } from '@cango-app/types'
import Tooltip from '@mui/material/Tooltip'
import EventIcon from '@mui/icons-material/Event'
import QuestionMark from '@mui/icons-material/QuestionMark'
import capitalize from 'lodash/capitalize'
import { QuestionMarkSharp } from '@mui/icons-material'

import * as ActionIcons from 'src/assets/icons/actions'
import { taskActionLabel } from 'src/helpers/labels'

type ActionTypeParam = V3BlueprintTypes.ActionEnum | 'none'

type ActionIconProps = {
	actionType: ActionTypeParam
	isMilestone?: boolean
	tooltip?: string | JSX.Element
}

const actionIconDictionary: Record<V3BlueprintTypes.ActionEnum, ComponentType> = {
	[V3BlueprintTypes.ActionEnum.Call]: ActionIcons.Call,
	[V3BlueprintTypes.ActionEnum.Email]: ActionIcons.Email,
	[V3BlueprintTypes.ActionEnum.Meeting]: ActionIcons.Meeting,
	[V3BlueprintTypes.ActionEnum.Invite]: ActionIcons.Invite,
	[V3BlueprintTypes.ActionEnum.FileTemplate]: ActionIcons.FileTemplate,
	[V3BlueprintTypes.ActionEnum.FileUpload]: ActionIcons.FileUpload,
	[V3BlueprintTypes.ActionEnum.FileView]: ActionIcons.FileView,
	[V3BlueprintTypes.ActionEnum.Software]: ActionIcons.Software,
	[V3BlueprintTypes.ActionEnum.Video]: ActionIcons.Video,
	[V3BlueprintTypes.ActionEnum.Note]: ActionIcons.Note,
	[V3BlueprintTypes.ActionEnum.Contact]: ActionIcons.Contact,
	[V3BlueprintTypes.ActionEnum.Archive]: ActionIcons.Archive,
	[V3BlueprintTypes.ActionEnum.LinkView]: ActionIcons.Software,

	// V3 Actions
	[V3BlueprintTypes.ActionEnum.None]: QuestionMarkSharp,
}

const UndecoratedTaskActionIcon: ComponentType<ActionIconProps> = ({
	actionType,
	isMilestone,
	tooltip,
}) => {
	if (actionType === 'none') {
		return null
	}
	let ActionIcon = actionIconDictionary[actionType]
	let tooltipTitle = tooltip || capitalize(taskActionLabel(actionType))

	if (isMilestone) {
		ActionIcon = EventIcon
		tooltipTitle = 'Milestone'
	}

	if (actionType) {
		return (
			<Tooltip title={tooltipTitle} placement="left">
				<ActionIcon />
			</Tooltip>
		)
	}

	return (
		<Tooltip title={'Unknown'} placement="left">
			<QuestionMark />
		</Tooltip>
	)
}

export const TaskActionIcon = memo(UndecoratedTaskActionIcon)
