import React, { ComponentType, useState } from 'react'
import findIndex from 'lodash/findIndex'
import uniq from 'lodash/uniq'
import { Tab, Tabs } from '@mui/material'
import SwapVerticalCircleOutlined from '@mui/icons-material/SwapVerticalCircleOutlined'

import { UploadDriveChildProps } from 'src/providers'

import googleDriveLogo from '../../assets/images/google_drive_logo.png'
import { Button } from '../button'
import { Modal } from '../modal'
import { Box } from '../box'
import { Text } from '../text'
import { TabPanel } from '../tab-panel'

import { UploadPanel } from './upload-panel'
import { FilesPanel } from './files-panel'
import { DriveUploadProps } from './index'

const a11yProps = (index: number) => {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	}
}

export const DriveUploadWeb: ComponentType<UploadDriveChildProps & DriveUploadProps> = ({
	containerStyles,
	label = 'Save to Google Drive™',
	isDisabled,
	parentFolderId,
	parentFolderName,
	onChange,
	isLoading,
	variant = 'modal',
	fileIds,
	ctaVariant = 'button',
	onlyUpload,
	children,
	allFiles,
	fetchFiles,
	isLoadingFiles,
	fetchFilesWithAllIdsPresent,
	countdown,
	attemptNumber,
}) => {
	const [isUploadModalOpen, setIsUploadModalOpen] = useState(false)
	const [selectedTab, setSelectedTab] = useState(onlyUpload ? 1 : 0)
	const [selectedFiles, setSelectedFiles] = useState<string[]>(fileIds)
	const [errorRetryingFileFetch, setErrorRetryingFileFetch] = useState(false)

	const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
		setSelectedTab(newValue)
	}

	const handleSelectFile = (fileId: string, isSelected: boolean) => {
		if (selectedFiles.includes(fileId) && isSelected) return

		if (!selectedFiles.includes(fileId) && isSelected) {
			setSelectedFiles([...selectedFiles, fileId])
			return
		}

		if (selectedFiles.includes(fileId) && !isSelected) {
			const selectedFilesCopy = [...selectedFiles]
			const fileIndex = findIndex(selectedFilesCopy, (_fileId) => _fileId === fileId)
			selectedFilesCopy.splice(fileIndex, 1)
			setSelectedFiles([...selectedFilesCopy])
		}
	}

	const handleSelectedFiles = async (fileIds: string[]) => {
		if (onlyUpload) {
			await onChange(fileIds)
			await fetchFiles()
			setIsUploadModalOpen(false)
			return
		}

		setSelectedTab(0)
		try {
			await fetchFilesWithAllIdsPresent(fileIds)
		} catch (error) {
			setErrorRetryingFileFetch(true)
		}
		setSelectedFiles(uniq(fileIds))
	}

	const handleDriveClick = async () => {
		setSelectedFiles(fileIds)
		setIsUploadModalOpen(true)
	}

	const handleCloseModal = () => {
		setSelectedFiles([])
		setIsUploadModalOpen(false)
	}

	const handleSubmit = async (fileIds: string[]) => {
		await onChange(fileIds)
		setIsUploadModalOpen(false)
	}

	const renderCta = () => {
		if (children) {
			return children({ onClick: handleDriveClick })
		}

		if (variant !== 'modal') return null
		switch (ctaVariant) {
			case 'replaceIcon':
				return (
					<Button
						onClick={handleDriveClick}
						isLoading={!!isLoading || isLoadingFiles}
						variant="outlined"
						size="small"
						startIcon={<SwapVerticalCircleOutlined />}>
						Replace files
					</Button>
				)
			default: {
				return (
					<Button
						sx={{ ...containerStyles, textTransform: 'none' }}
						variant="outlined"
						startIcon={<img src={googleDriveLogo} width={20} />}
						disabled={isDisabled}
						isLoading={!!isLoading || isLoadingFiles}
						onClick={handleDriveClick}>
						<Text>{isDisabled ? 'Failed to fetch file' : label}</Text>
					</Button>
				)
			}
		}
	}

	const content = () => {
		return (
			<>
				<Box width="100%">
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<Tabs value={selectedTab} onChange={handleChangeTab}>
							{!onlyUpload && (
								<Tab
									label="Select from Google Drive"
									sx={{ textTransform: 'none' }}
									{...a11yProps(0)}
									icon={<img src={googleDriveLogo} width={20} />}
									iconPosition="start"
								/>
							)}
							<Tab
								label="Upload to Google Drive"
								sx={{ textTransform: 'none' }}
								{...a11yProps(1)}
								icon={<img src={googleDriveLogo} width={20} />}
								iconPosition="start"
							/>
						</Tabs>
					</Box>
				</Box>
				{!onlyUpload && (
					<TabPanel value={selectedTab} index={0}>
						<FilesPanel
							parentFolderName={parentFolderName}
							onSelectFile={handleSelectFile}
							selectedFiles={selectedFiles}
							files={allFiles}
							onSubmit={handleSubmit}
							fetchError={errorRetryingFileFetch}
							isLoadingFiles={isLoadingFiles}
							attemptNumber={attemptNumber}
							countdown={countdown}
						/>
					</TabPanel>
				)}
				<TabPanel value={selectedTab} index={1}>
					<UploadPanel parentFolderId={parentFolderId} onFilesUploaded={handleSelectedFiles} />
				</TabPanel>
			</>
		)
	}

	if (variant === 'modal') {
		return (
			<>
				<Modal open={isUploadModalOpen} onClose={handleCloseModal}>
					<Box width={700} minHeight={400} maxHeight={600} maxWidth="100%" bgcolor="white">
						{content()}
					</Box>
				</Modal>
				{renderCta()}
			</>
		)
	}

	return (
		<Box display="flex" flex={1} minHeight={200} flexDirection="column">
			{content()}
		</Box>
	)
}
