import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import find from 'lodash/find'
import _set from 'lodash/set'
import _forEach from 'lodash/forEach'
import _findIndex from 'lodash/findIndex'
import { V3ClientTypes } from '@cango-app/types'
import { V3ProjectSdk } from '@cango-app/sdk'

import { sortDocuments } from 'src/helpers/chains'

import * as thunks from './thunks'
import { MyTasksV3State, TaskListType, MyTasksLoadingState } from './types'

const initialState: MyTasksV3State = {
	myTasksState: MyTasksLoadingState.Idle,
	projects: {},
	isChatModalOpen: false,
	taskListType: TaskListType.Active,
	tasks: [],
	filesToUpload: 0,
	blockOptions: [],
	isLoadingBlockOptions: false,
}

export const myTasksSlice = createSlice({
	name: 'my-tasks-v3',
	initialState,
	reducers: {
		endSession: () => initialState,
		deselectSection: (state) => {
			state.selectedSectionId = undefined
		},
		selectSection: (state, action: PayloadAction<string>) => {
			state.selectedSectionId = action.payload
			state.taskListType = TaskListType.Active
		},
		setSelectedTaskId: (state, action: PayloadAction<string | undefined>) => {
			state.selectedTaskId = action.payload
		},
		setFlaggedTaskId: (state, action: PayloadAction<string | undefined>) => {
			state.flaggedTaskId = action.payload
		},
		toggleChatModal: (state, action: PayloadAction<boolean>) => {
			state.isChatModalOpen = action.payload
		},
		setTaskListType: (state, action: PayloadAction<TaskListType>) => {
			state.taskListType = action.payload
			state.selectedTaskId = undefined
		},
		addNewTask: (
			state,
			action: PayloadAction<{
				section_id?: string
				task: V3ClientTypes.Project.Task
			}>,
		) => {
			const { section_id, task } = action.payload
			if (state.selectedSectionId !== section_id) {
				return
			}
			state.tasks.push(task)
		},
		resetTask: (
			state,
			action: PayloadAction<V3ProjectSdk.ResetTaskResponse & V3ProjectSdk.ResetTaskRequest>,
		) => {
			const { taskId, updates } = action.payload
			const { task, newTasks } = action.payload
			state.tasks = [
				...state.tasks.reduce((acc: V3ClientTypes.Project.Task[], _task) => {
					if (updates.tasksToDelete.includes(_task._id)) {
						return acc
					}
					if (_task._id === taskId) {
						acc.push(task)
						return acc
					}
					acc.push(_task)
					return acc
				}, []),
				...newTasks,
			]
		},
		updateTaskFileIds: (
			state,
			action: PayloadAction<V3ProjectSdk.GetTasksPendingUploadedFilesResponse>,
		) => {
			action.payload.forEach((_task) => {
				const taskIndex = state.tasks.findIndex(({ _id }) => _id === _task._id)
				if (taskIndex < 0) return
				state.tasks[taskIndex].actions = _task.actions
			})
		},
		setFilesTotalToUpload: (state, action: PayloadAction<number>) => {
			state.filesToUpload = action.payload
		},
	},
	extraReducers: (builder) => {
		builder.addCase(thunks.fetchAllProjects.pending, () => ({
			...initialState,
			myTasksState: MyTasksLoadingState.Loading,
		}))
		builder.addCase(thunks.fetchAllProjects.fulfilled, (state, action) => {
			state.myTasksState = MyTasksLoadingState.Fetched
			state.projects = action.payload.projects
		})
		builder.addCase(thunks.fetchAllProjects.rejected, (state) => {
			state.myTasksState = MyTasksLoadingState.Error
		})
		builder.addCase(thunks.fetchProjectsById.pending, (state) => {
			state.myTasksState = MyTasksLoadingState.BackgroundLoading
		})
		builder.addCase(thunks.fetchProjectsById.fulfilled, (state, action) => {
			state.projects = { ...state.projects, ...action.payload.projects }
			state.myTasksState = MyTasksLoadingState.Fetched
		})
		builder.addCase(thunks.fetchProjectsById.rejected, (state) => {
			state.myTasksState = MyTasksLoadingState.Error
		})
		builder.addCase(thunks.fetchSectionTasks.pending, (state, action) => {
			state.sectionLoading = action.meta.arg.sectionId
		})
		builder.addCase(thunks.fetchSectionTasks.fulfilled, (state, action) => {
			state.sectionLoading = undefined
			state.tasks = action.payload
			state.selectedSectionId = action.meta.arg.sectionId
			state.filesToUpload += action.payload.reduce(
				(_acc: number, _task) =>
					_acc +
					_task.actions.reduce(
						(_actionAcc, _action) =>
							_actionAcc +
							_action.file_ids.filter((_id) => _id === V3ProjectSdk.UPLOADING_TEXT).length,
						0,
					),
				0,
			)
		})
		builder.addCase(thunks.fetchBlockedTasks.fulfilled, (state, action) => {
			state.sectionLoading = undefined
			state.tasks = action.payload
		})
		builder.addCase(thunks.fetchBlockOptions.pending, (state, action) => {
			state.isLoadingBlockOptions = true
		}),
			builder.addCase(thunks.fetchBlockOptions.fulfilled, (state, action) => {
				state.isLoadingBlockOptions = false
				state.blockOptions = action.payload
			})
		builder.addCase(thunks.fetchBlockOptions.rejected, (state, action) => {
			state.isLoadingBlockOptions = false
		}),
			builder.addCase(thunks.fetchSectionTasks.rejected, (state) => {
				state.sectionLoading = undefined
			})
		builder.addCase(thunks.updateTask.fulfilled, (state, action) => {
			const taskIndex = state.tasks.findIndex((t) => t._id === action.payload._id)
			if (taskIndex === undefined || taskIndex === -1) {
				return
			}
			state.tasks[taskIndex] = action.payload
		})
		builder.addCase(thunks.assignUsersToRoles.fulfilled, (state, action) => {
			const { projectId } = action.meta.arg
			state.projects[projectId].roles = action.payload
		})
		builder.addCase(thunks.assignContactsToProject.fulfilled, (state, action) => {
			const { projectId } = action.meta.arg
			state.projects[projectId].externals = action.payload
		})

		builder.addCase(thunks.flagTask.fulfilled, (state, action) => {
			const { taskId } = action.meta.arg
			const { sectionId, projectId } = action.payload
			state.flaggedTaskId = undefined
			const project = state.projects[projectId]
			const sectionIndex = project.sections.findIndex((s) => s._id === sectionId)
			if (sectionIndex === -1) {
				return
			}
			project.sections[sectionIndex].isBlocked = true
			const taskIndex = state.tasks.findIndex((t) => t._id === taskId)
			if (taskIndex === -1) {
				return
			}
			state.tasks[taskIndex].isFlagged = true
		})
		builder.addCase(thunks.completeTask.fulfilled, (state, action) => {
			const { taskId, instanceName } = action.meta.arg
			const {
				newTasks,
				instances,
				sections: newSections,
				projectId,
				sectionId,
				sectionCount,
				isProjectComplete,
			} = action.payload

			if (isProjectComplete) {
				delete state.projects[projectId]
				return
			}

			const project = state.projects[projectId]
			const projectCopy = { ...project }
			state.tasks.push(...newTasks)
			state.filesToUpload += newTasks.reduce(
				(_acc: number, _task) =>
					_acc +
					_task.actions.reduce(
						(_actionAcc, _action) =>
							_actionAcc +
							_action.file_ids.filter((_id) => _id === V3ProjectSdk.UPLOADING_TEXT).length,
						0,
					),
				0,
			)

			if (newSections.length) {
				const newSectionArray = sortDocuments(
					[...projectCopy.sections, ...newSections],
					project.section_order ?? [],
				)
				projectCopy.sections = newSectionArray.map((_section) => ({
					..._section,
					...sectionCount[_section._id],
				}))
			}

			if (taskId === sectionId) {
				const hasAnotherSection = projectCopy.sections.length > 1
				const sectionIndex = projectCopy.sections.findIndex((s) => s._id === taskId)
				projectCopy.sections.splice(sectionIndex, 1)
				if (hasAnotherSection) {
					state.selectedSectionId = projectCopy.sections[0]._id
				} else {
					state.selectedSectionId = undefined
				}
			} else {
				const completedTaskSectionIndex = projectCopy.sections.findIndex(
					(section) => section._id === sectionId,
				)
				const completedTaskIndex = state.tasks.findIndex((t) => t._id === taskId)

				projectCopy.sections[completedTaskSectionIndex] = {
					...projectCopy.sections[completedTaskSectionIndex],
					...sectionCount[sectionId],
				}

				if (!instanceName) {
					state.tasks[completedTaskIndex].lifecycle.complete = true
				} else {
					_set(state.tasks[completedTaskIndex], 'instance.instances', instances)
				}
			}
			_forEach(sectionCount, (counts, sectionId) => {
				const sectionIndex = projectCopy.sections.findIndex((s) => s._id === sectionId)
				if (sectionIndex === -1) {
					return
				}
				projectCopy.sections[sectionIndex] = {
					...projectCopy.sections[sectionIndex],
					...counts,
				}
			})
			state.projects[projectId] = projectCopy
		})
		builder.addCase(thunks.resolveFlaggedTask.fulfilled, (state, action) => {
			const { projectId, taskId, sectionId } = action.meta.arg
			const project = state.projects[projectId]
			const taskIndex = state.tasks.findIndex((t) => t._id === taskId)
			if (taskIndex === -1) {
				return
			}
			state.tasks[taskIndex].isFlagged = false
			const hasBlockedTasks = state.tasks.some((t) => t._id !== taskId && t.isFlagged)
			const sectionIndex = _findIndex(project.sections, { _id: sectionId })
			if (sectionIndex === -1) {
				return
			}
			project.sections[sectionIndex].isBlocked = hasBlockedTasks
		})
		builder.addCase(thunks.addFileIdsToTask.fulfilled, (state, action) => {
			const { taskId, actionIndex } = action.meta.arg
			const { file_history: fileHistory = [], file_ids: fileIds } = action.payload
			const taskIndex = state.tasks.findIndex((t) => t._id === taskId)
			if (taskIndex === -1) {
				return
			}
			const task = state.tasks[taskIndex]

			task.actions[actionIndex] = {
				...task.actions[actionIndex],
				file_ids: [...fileIds],
				file_history: [...fileHistory],
			}
		})
		builder.addCase(thunks.getUpdatedDependencies.fulfilled, (state, action) => {
			const tasks = action.payload
			tasks.forEach((task) => {
				const tasks = state.tasks
				const taskIndex = tasks.findIndex(({ _id }) => _id === task._id)
				if (taskIndex < 0) return
				tasks[taskIndex] = task
			})
		})
		builder.addCase(thunks.getTableId.fulfilled, (state, action) => {
			const { _id } = action.payload
			const { projectId } = action.meta.arg
			if (!state.projects[projectId]) {
				return
			}
			state.projects[projectId].table = _id
		})
	},
})
