import { createSelector } from '@reduxjs/toolkit'
import find from 'lodash/find'

import { RootState } from '../../types'
import { selectors as blueprintSelectors } from '../blueprints-v3'
import { selectors as configSelectors } from '../config/selectors'

import { PersistedConfigState, RecentlyViewedProject } from './types'

const getPersistedConfigState: (state: RootState) => PersistedConfigState = createSelector(
	(state: RootState) => state.cangoPersistedConfig,
	(persistedConfig) => persistedConfig,
)

const isHqAxesSwapped: (state: RootState) => boolean = createSelector(
	getPersistedConfigState,
	(persistedConfig) => !!persistedConfig.hqAxesSwapped,
)

const getRecentlyViewedProjects: (
	state: RootState,
) => Array<Omit<RecentlyViewedProject, 'blueprintId'> & { blueprintName: string }> = createSelector(
	getPersistedConfigState,
	blueprintSelectors.getCards,
	configSelectors.getOrganisationId,
	(persistedConfig, blueprintCards, organisationId) => {
		if (!persistedConfig || !persistedConfig.recentlyViewedProjects || !organisationId) return []
		if (!persistedConfig.recentlyViewedProjects[organisationId]) return []
		const recentlyViewedOrganisationProjects =
			persistedConfig.recentlyViewedProjects[organisationId]
		return recentlyViewedOrganisationProjects.map((project) => {
			const blueprint = find(blueprintCards, ({ _id }) => _id === project.blueprintId)
			const blueprintCard = find(blueprintCards, ({ _id }) => _id === project.blueprintId)
			return {
				...project,
				blueprintName: blueprint?.name || blueprintCard?.name || '',
			}
		})
	},
)

export const selectors = {
	isHqAxesSwapped,
	getRecentlyViewedProjects,
}
