import Backdrop from '@mui/material/Backdrop'
import { ComponentType, useLayoutEffect } from 'react'
import { motion, useAnimate } from 'framer-motion'

import { CangoLogoText } from 'src/assets/icons'
import { colors } from 'src/theme/colors'

import { Box } from './box'

type FullScreenLoaderProps = {
	isOpen: boolean
}

export const FullScreenLoader: ComponentType<FullScreenLoaderProps> = ({ isOpen }) => {
	const [scope, animate] = useAnimate()

	useLayoutEffect(() => {
		if (!isOpen) return

		const containerWidth =
			document?.querySelector<HTMLElement>('.loading-container')?.offsetWidth ?? 0
		const animateLoader = async () => {
			await animate(
				[
					[scope.current, { x: 0, width: '100%' }],
					[scope.current, { x: containerWidth, width: '0%' }, { delay: 0.6 }],
				],
				{
					duration: 2,
					repeat: Infinity,
					repeatDelay: 0.8,
				},
			)
		}
		animateLoader()
	}, [isOpen])
	return (
		<Backdrop open={isOpen} sx={{ bgcolor: colors.feldgrau['60'] }}>
			<Box className="loading-container" position="relative">
				<motion.div
					ref={scope}
					style={{
						position: 'absolute',
						height: '100%',
						backgroundColor: colors.sunglow['60'],
						borderRadius: '8px',
					}}
				/>
				<Box m={2}>
					<CangoLogoText
						width={300}
						fill={colors.feldgrau['60']}
						style={{ mixBlendMode: 'darken' }}
					/>
				</Box>
			</Box>
		</Backdrop>
	)
}
