import { createSelector } from '@reduxjs/toolkit'
import { FilesSdk } from '@cango-app/sdk'

import { CangoReduxModuleName, RootState } from '../../types'

const getFilesRootState: (state: RootState) => RootState[CangoReduxModuleName.CangoPersistedFiles] =
	createSelector(
		(state: RootState) => state,
		({ cangoPersistedFiles }) => cangoPersistedFiles,
	)

const getProjectFiles: (state: RootState, projectFolderId?: string) => FilesSdk.File[] =
	createSelector(
		getFilesRootState,
		(state: RootState, projectFolderId?: string) => projectFolderId,
		(filesState, projectFolderId) => {
			if (!projectFolderId) return []
			if (!filesState[projectFolderId]) return []
			return filesState[projectFolderId]
		},
	)

const getMappedProjectFiles = createSelector(
	getProjectFiles,
	(files) => new Map(files.map((file) => [file.id, file])),
)

export const selectors = {
	getProjectFiles,
	getMappedProjectFiles,
}
