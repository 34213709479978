import React, { ComponentType } from 'react'
import {
	DataGridPro,
	DataGridProProps,
	GridColDef,
	GridGroupNode,
	GridRenderCellParams,
} from '@mui/x-data-grid-pro'
import uniq from 'lodash/uniq'

import { Box } from 'src/components'
import { dataGridStandard } from 'src/helpers/ui'
import { ReorderModal } from 'src/modules/blueprints-v3/blueprint-detail/section-tasks/reorder-modal'
import { BlueprintRow } from 'src/modules/blueprints-v3/blueprint-detail/section-tasks'

import { NameLabel } from './name-label'
import { RowTitle } from './row-title'
import { SectionTitleBar } from './title-bar'

import 'src/assets/css/SectionTasks.scss'

export const HEADER_CLASSNAME = 'section-tasks--header'

const MIN_TASK_SIZE = 12

type SectionTasksProps = DataGridProProps & {
	isBlueprint: boolean
	sectionId: string
	sectionName: string
	columns: GridColDef[]
	rows: BlueprintRow[]
	onNewTaskClick: (data: { sectionId: string; parentId?: string }) => void
	onOpenSectionReoderModal?: () => void
	onSectionStart?: (sectionId: string) => Promise<void>
	onSectionComplete?: (sectionId: string) => Promise<void>
	startedAt?: number
	completedAt?: number[]
	onFetchRows?: (fromIndex: number, toIndex: number) => Promise<void>
	selectedTaskId?: string
	canReorder?: boolean
	onSectionNameClick?: () => void
}

const SectionTasks: ComponentType<SectionTasksProps> = ({
	isBlueprint,
	sectionId,
	sectionName,
	columns,
	rows,
	onNewTaskClick,
	onSectionStart,
	onSectionComplete,
	startedAt,
	completedAt,
	rowCount,
	selectedTaskId,
	canReorder,
	onSectionNameClick,
	...dataGridProps
}) => {
	const [expandedRows, setExpandedRows] = React.useState<string[]>([])
	const [showReorderModal, setShowReorderModal] = React.useState(false)
	const shouldLazyLoad = !!rowCount && rowCount >= MIN_TASK_SIZE

	const handleExpandRow = (rowId: string, shouldExpand: boolean) => {
		if (shouldExpand) {
			setExpandedRows(uniq([...expandedRows, rowId]))
		} else {
			setExpandedRows(expandedRows.filter((id) => id !== rowId))
		}
	}

	const isGroupExpandedByDefault = (node: GridGroupNode) => expandedRows.includes(node.id as string)

	const getTreeDataPath: DataGridProProps['getTreeDataPath'] = (row) => row.hierarchy

	const groupingColDef: DataGridProProps['groupingColDef'] = {
		headerName: 'Name',
		width: 300,
		headerClassName: HEADER_CLASSNAME,
		flex: 1,
		renderCell: (params: GridRenderCellParams<BlueprintRow>) => (
			<RowTitle
				{...params}
				name={<NameLabel name={params.row.name} />}
				selectedTaskId={selectedTaskId}
				isRowExpanded={expandedRows.includes(params.row.id)}
				onRowExpand={handleExpandRow}
				chains={params.row.chains}
			/>
		),
	}

	return (
		<Box
			p={2}
			boxShadow="rgb(0 0 0 / 12%) 2px 4px 24px;"
			borderRadius="0 20px 20px 20px"
			mb={2}
			bgcolor="#fff">
			<ReorderModal
				open={showReorderModal}
				rows={rows}
				columns={columns}
				sectionId={sectionId}
				onClose={() => setShowReorderModal(false)}
				rowCount={rowCount}
			/>
			<SectionTitleBar
				isBlueprint={isBlueprint}
				sectionName={sectionName}
				sectionId={sectionId}
				onNewTaskClick={onNewTaskClick}
				onSectionStart={onSectionStart}
				onSectionComplete={onSectionComplete}
				startedAt={startedAt}
				completedAt={completedAt}
				rowCount={rowCount}
				canReorder={canReorder}
				onReorderClick={() => setShowReorderModal(true)}
				onSectionNameClick={onSectionNameClick}
			/>
			<Box height={shouldLazyLoad ? 700 : undefined} mb={shouldLazyLoad ? 4 : undefined}>
				<DataGridPro
					autoHeight={!shouldLazyLoad}
					treeData
					rows={rows}
					columns={columns}
					isGroupExpandedByDefault={isGroupExpandedByDefault}
					sx={{
						...dataGridStandard,
						borderRadius: '0 15px 15px 15px',
					}}
					getTreeDataPath={getTreeDataPath}
					groupingColDef={groupingColDef}
					getRowHeight={() => 'auto'}
					disableRowSelectionOnClick
					hideFooter
					disableColumnMenu
					rowCount={rowCount}
					{...dataGridProps}
				/>
			</Box>
		</Box>
	)
}

export default SectionTasks
