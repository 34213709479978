import { createAsyncThunk, Dispatch } from '@reduxjs/toolkit'
import { V3ProjectSdk, V3BlueprintSdk, FilesSdk, MyTasksV3Sdk } from '@cango-app/sdk'
import { V3BlueprintTypes, V3ClientTypes } from '@cango-app/types'
import { NavigateFunction } from 'react-router-dom'

import { showSnackbar } from 'src/helpers/snackbarManager'

import type { RootState, AsyncDispatchType } from '../../types'
import { selectors as authSelectors } from '../auth'
import { selectors as configSelectors } from '../config'
import { actions as blueprintActions } from '../blueprints-v3'
import { actions as tableActions } from '../tables'
import { actions as persistedConfigActions } from '../persisted-config'
import { errorHandler } from '../../../helpers/api'

import { selectors as projectSelectors } from './selectors'

export const createNewTask = createAsyncThunk<
	{ task: V3ClientTypes.Project.Task; projectId: string },
	V3ProjectSdk.CreateTaskRequest,
	{ rejectValue: void; dispatch: Dispatch; state: RootState }
>('projects-v3/create-new-task', async (data, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState()
		const headers = authSelectors.getAuthHeaders(state)
		const response = await V3ProjectSdk.createTask(
			import.meta.env.VITE_API as string,
			headers,
			data,
		)
		return { projectId: data.project_id, task: response }
	} catch (error) {
		if ((error as any).response.data.message === 'duplicated_key') {
			showSnackbar('Oops! Please try saving again...', { variant: 'error' })
		}
		errorHandler({ error, dispatch })
		return rejectWithValue()
	}
})

export const deleteTask = createAsyncThunk<
	{ taskId: string; projectId: string },
	{ taskId: string; projectId: string },
	{ rejectValue: string; dispatch: AsyncDispatchType }
>('projects-v3/deleteTask', async (data, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState() as RootState
		const headers = authSelectors.getAuthHeaders(state)
		await V3ProjectSdk.deleteTask(import.meta.env.VITE_API as string, headers, data)

		return { ...data }
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue((error as Error).message)
	}
})

export const getProjectNames = createAsyncThunk<
	V3ProjectSdk.GetProjectNamesResponse,
	undefined,
	{ state: RootState; rejectValue: string; dispatch: AsyncDispatchType }
>('projects-v3/project-names', async (_, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState()
		const headers = authSelectors.getAuthHeaders(state)
		const projectNames = await V3ProjectSdk.getProjectNames(
			import.meta.env.VITE_API as string,
			headers,
		)

		return projectNames
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue((error as Error).message)
	}
})

export const getCards = createAsyncThunk<
	V3BlueprintSdk.Card[],
	void,
	{ state: RootState; rejectValue: string; dispatch: AsyncDispatchType }
>('projects-v3/getProjectCards', async (ids, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState()
		const headers = authSelectors.getAuthHeaders(state)
		const organisationId = configSelectors.getOrganisationId(state)
		const projectCards = await V3ProjectSdk.getCards(import.meta.env.VITE_API as string, headers)

		const projectIds = projectCards.map((project) => project._id)
		dispatch(persistedConfigActions.cleanRecentlyViewedProjects({ projectIds, organisationId }))

		return projectCards
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue((error as Error).message)
	}
})

export const getProjectTasks = createAsyncThunk<
	V3ProjectSdk.GetTasksResponse<keyof V3ClientTypes.Project.Task>,
	{ projectId: string },
	{ rejectValue: any; dispatch: AsyncDispatchType; state: RootState }
>(
	'projects-v3/get-project-tasks',
	async ({ projectId }, { getState, rejectWithValue, dispatch }) => {
		try {
			const state = getState()
			const headers = authSelectors.getAuthHeaders(state)
			const response = await V3ProjectSdk.getTasks({
				baseURL: import.meta.env.VITE_API as string,
				authHeaders: headers,
				projectId,
			})

			return response
		} catch (error) {
			errorHandler({ error, dispatch })
			return rejectWithValue(error as any)
		}
	},
)

export const createProject = createAsyncThunk<
	{ projectId: string; error?: string },
	V3ProjectSdk.CreateProjectRequest,
	{
		rejectValue: { projectId?: string; error: string }
		dispatch: Dispatch
	}
>('projects-v3/create-project', async (data, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState() as RootState
		const headers = authSelectors.getAuthHeaders(state)
		const response = await V3ProjectSdk.createProject(
			import.meta.env.VITE_API as string,
			headers,
			data,
		)
		return { projectId: response._id }
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue({
			projectId: undefined,
			error: (error as Error).message,
		})
	}
})

export const getProject = createAsyncThunk<
	V3ProjectSdk.GetProjectResponse,
	{ projectId: string },
	{ rejectValue: any; dispatch: AsyncDispatchType }
>('projects-v3/getById', async ({ projectId }, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState() as RootState
		const headers = authSelectors.getAuthHeaders(state)
		const organisationId = configSelectors.getOrganisationId(state)
		const response = await V3ProjectSdk.getProject({
			baseURL: import.meta.env.VITE_API as string,
			authHeaders: headers,
			projectId,
			params: {
				withSectionOrder: 'true',
			},
		})

		if (organisationId) {
			dispatch(
				persistedConfigActions.setRecentlyViewedProject({
					organisationId,
					project: {
						_id: response._id,
						name: response.name,
						blueprintId: response.blueprint_id,
					},
				}),
			)
		}

		dispatch(getProjectTasks({ projectId }))
		dispatch(tableActions.getTableForProject({ projectId: response._id }))

		return response
	} catch (error) {
		if ((error as any).response.data.message === 'cannot_find_project') {
			return rejectWithValue('cannot_find_project')
		}
		errorHandler({ error, dispatch })
		return rejectWithValue(error as any)
	}
})

export const updateProject = createAsyncThunk<
	{ response: V3ProjectSdk.UpdateProjectResponse; organisationId?: string },
	V3ProjectSdk.UpdateProjectRequest & { projectId: string },
	{ rejectValue: any; dispatch: Dispatch }
>('projects-v3/updateProject', async (data, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState() as RootState
		const headers = authSelectors.getAuthHeaders(state)
		const organisationId = configSelectors.getOrganisationId(state)
		const response = await V3ProjectSdk.updateProject({
			baseURL: import.meta.env.VITE_API as string,
			authHeaders: headers,
			data,
		})
		return { response, organisationId }
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue(error as any)
	}
})

export const updateTask = createAsyncThunk<
	V3ClientTypes.Project.Task,
	V3ProjectSdk.UpdateTaskRequest & { projectId: string },
	{ rejectValue: any; dispatch: Dispatch; state: RootState }
>('projects-v3/updateTask', async (data, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState()
		const headers = authSelectors.getAuthHeaders(state)
		const response = await V3ProjectSdk.updateTask({
			baseURL: import.meta.env.VITE_API as string,
			authHeaders: headers,
			data,
		})
		return response
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue(error as any)
	}
})

export const completeTask = createAsyncThunk<
	Omit<V3ProjectSdk.CompleteTaskResponse, 'sectionCount'> & {
		projectId: string
		taskId: string
		optionIds?: string[]
	},
	V3ProjectSdk.CompleteTaskRequest & { projectId: string },
	{ rejectValue: any; dispatch: AsyncDispatchType; state: RootState }
>('projects-v3/complete-task', async (data, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState()
		const headers = authSelectors.getAuthHeaders(state)
		const mappedTasks = projectSelectors.getMappedProjectTasks(state)
		const response = await V3ProjectSdk.completeTask(
			import.meta.env.VITE_API as string,
			headers,
			data,
		)

		const completedTask = mappedTasks.get(data.taskId)
		if (
			completedTask?.actions.some((_action) =>
				[V3BlueprintTypes.ActionEnum.FileUpload, V3BlueprintTypes.ActionEnum.FileTemplate].includes(
					_action.type,
				),
			)
		) {
			dispatch(getUpdatedDependencies({ task: data.taskId }))
		}

		return {
			optionIds: data.optionIds ? data.optionIds : [],
			taskId: data.taskId,
			tasks: response.tasks,
			projectId: data.projectId,
			instances: response.instances,
			isProjectComplete: response.isProjectComplete,
		}
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue(error as any)
	}
})

export const completeOccurrence = createAsyncThunk<
	V3ProjectSdk.CompleteOccurrenceResponse,
	V3ProjectSdk.CompleteOccurrenceRequest,
	{ rejectValue: any; dispatch: Dispatch }
>('projects-v3/complete-occurrence', async (data, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState() as RootState
		const headers = authSelectors.getAuthHeaders(state)
		const response = await V3ProjectSdk.completeOccurrence(
			import.meta.env.VITE_API as string,
			headers,
			data,
		)
		return response
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue(error as any)
	}
})

export const assignTaskDate = createAsyncThunk<
	V3ProjectSdk.AssignTaskDateResponse & { projectId: string },
	V3ProjectSdk.AssignTaskDateRequest & { projectId: string },
	{ rejectValue: any; dispatch: Dispatch }
>('projects-v3/assign-task-date', async (data, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState() as RootState
		const headers = authSelectors.getAuthHeaders(state)
		await V3ProjectSdk.assignTaskDate(import.meta.env.VITE_API as string, headers, data)
		return data
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue(error as any)
	}
})

export const assignUserToRole = createAsyncThunk<
	V3ProjectSdk.AssignUserRoleResponse,
	V3ProjectSdk.AssignUserRoleRequest,
	{ rejectValue: any; dispatch: Dispatch; state: RootState }
>('projects-v3/assign-user-role', async (data, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState()
		const headers = authSelectors.getAuthHeaders(state)
		const response = await V3ProjectSdk.assignUserRole(
			import.meta.env.VITE_API as string,
			headers,
			data,
		)
		showSnackbar('User assigned to Project Role')
		return response
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue(error as any)
	}
})

export const assignContactToExternal = createAsyncThunk<
	V3ClientTypes.Project.Project['externals'],
	V3ProjectSdk.AssignContactsToProjectRequest,
	{ rejectValue: any; dispatch: Dispatch }
>('projects-v3/assign-contact-external', async (data, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState() as RootState
		const headers = authSelectors.getAuthHeaders(state)
		await V3ProjectSdk.assignContactsToProject(import.meta.env.VITE_API as string, headers, data)
		const project = projectSelectors.getSelectedProject(state)
		const projectExternals = project?.externals || []

		const updatedRoles = projectExternals.reduce(
			(acc: V3ClientTypes.Project.Project['externals'], projectExternal) => {
				const assignment = data.assignments.find(
					(assignment) => assignment.roleId === projectExternal.role,
				)

				if (assignment) {
					acc.push({
						...projectExternal,
						contact: assignment.contactId,
					})
				} else {
					acc.push(projectExternal)
				}
				return acc
			},
			[],
		)

		if (
			!data.assignments.every((assignment) =>
				updatedRoles.find((role) => role.role === assignment.roleId),
			)
		) {
			data.assignments.forEach((assignment) => {
				if (updatedRoles.find((role) => role.role === assignment.roleId)) return
				updatedRoles.push({
					role: assignment.roleId,
					contact: assignment.contactId,
				})
			})
		}

		return updatedRoles
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue(error as any)
	}
})

export const flagTaskfromProjectView = createAsyncThunk<
	{ projectId: string },
	MyTasksV3Sdk.FlatTaskRequest & { sectionId: string },
	{ rejectValue: string; state: RootState; dispatch: Dispatch }
>('my-project-v3/flag-task', async (data, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState()
		const headers = authSelectors.getAuthHeaders(state)
		const selectedProjectId = projectSelectors.getSelectedProjectId(state)
		if (!selectedProjectId) {
			throw new Error('No project selected')
		}
		await MyTasksV3Sdk.flagTask(import.meta.env.VITE_API as string, headers, data)
		return {
			projectId: selectedProjectId,
		}
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue(error as any)
	}
})

export const resolveFlaggedTaskFromProjectView = createAsyncThunk<
	void,
	{ projectId: string; taskId: string },
	{ rejectValue: string; state: RootState; dispatch: Dispatch }
>(
	'my-project-v3/resolve-flagged-task',
	async ({ taskId }, { getState, rejectWithValue, dispatch }) => {
		try {
			const state = getState()
			const headers = authSelectors.getAuthHeaders(state)
			await V3ProjectSdk.updateTask({
				baseURL: import.meta.env.VITE_API as string,
				authHeaders: headers,
				data: {
					taskId,
					update: {
						isFlagged: false,
					},
				},
			})
		} catch (error) {
			errorHandler({ error, dispatch })
			return rejectWithValue((error as Error).message)
		}
	},
)

export const deleteProject = createAsyncThunk<
	{ projectId: string; organisationId?: string },
	{ projectId: string; navigate: NavigateFunction },
	{ rejectValue: string; dispatch: Dispatch }
>(
	'projects-v3/delete',
	async ({ projectId, navigate }, { getState, rejectWithValue, dispatch }) => {
		try {
			const state = getState() as RootState
			const headers = authSelectors.getAuthHeaders(state)
			const organisationId = configSelectors.getOrganisationId(state)
			await V3ProjectSdk.deleteProject(import.meta.env.VITE_API as string, headers, {
				projectId: projectId,
			})
			navigate(`/projects`, { replace: true })
			return {
				projectId: projectId,
				organisationId,
			}
		} catch (error) {
			errorHandler({ error, dispatch })
			return rejectWithValue((error as Error).message)
		}
	},
)

export const archive = createAsyncThunk<
	{ projectId: string; archivedAt: number },
	{ projectId: string },
	{ rejectValue: string; dispatch: Dispatch }
>('projects-v3/archive', async ({ projectId }, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState() as RootState
		const headers = authSelectors.getAuthHeaders(state)
		const response = await V3ProjectSdk.archive(import.meta.env.VITE_API as string, headers, {
			projectId,
		})
		return {
			projectId,
			archivedAt: response.archivedAt,
		}
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue((error as Error).message)
	}
})

export const restoreFromArchive = createAsyncThunk<
	void,
	{ projectId: string },
	{ rejectValue: string; dispatch: Dispatch }
>(
	'projects-v3/restoreFromArchive',
	async ({ projectId }, { getState, rejectWithValue, dispatch }) => {
		try {
			const state = getState() as RootState
			const headers = authSelectors.getAuthHeaders(state)
			await V3ProjectSdk.updateProject({
				baseURL: import.meta.env.VITE_API as string,
				authHeaders: headers,
				data: {
					projectId,
					update: {
						archived: {
							state: false,
						},
					},
				},
			})
		} catch (error) {
			errorHandler({ error, dispatch })
			return rejectWithValue((error as Error).message)
		}
	},
)

export const addFileIdsToTask = createAsyncThunk<
	V3ProjectSdk.AddFileIdsToTaskResponse,
	V3ProjectSdk.AddFileIdsToTaskRequest & { projectId: string },
	{ rejectValue: string; state: RootState; dispatch: Dispatch }
>(
	'projects-v3/add-task-file',
	async ({ fileIds, ...data }, { getState, rejectWithValue, dispatch }) => {
		try {
			const state = getState()
			const headers = authSelectors.getAuthHeaders(state)
			const response = await V3ProjectSdk.addFileIdsToTask(
				import.meta.env.VITE_API as string,
				headers,
				{
					taskId: data.taskId,
					fileIds,
					actionIndex: data.actionIndex,
				},
			)
			return response
		} catch (error) {
			errorHandler({ error, dispatch })
			return rejectWithValue((error as Error).message)
		}
	},
)

export const getTasksPendingUploadedFiles = createAsyncThunk<
	V3ProjectSdk.GetTasksPendingUploadedFilesResponse,
	V3ProjectSdk.GetTasksPendingUploadedFilesRequest & { projectId: string; fetchFiles: () => void },
	{ rejectValue: any }
>(
	'projects-v3/get-tasks-pending-uploaded-files',
	async ({ projectId, ...data }, { getState, rejectWithValue }) => {
		try {
			const state = getState() as RootState
			const headers = authSelectors.getAuthHeaders(state)
			const response = await V3ProjectSdk.getTasksPendingUploadedFiles({
				baseURL: import.meta.env.VITE_API as string,
				authHeaders: headers,
				data,
			})

			if (response.length) {
				data.fetchFiles()
			}

			return response
		} catch (error) {
			return rejectWithValue(error as any)
		}
	},
)

export const fetchProjectFiles = createAsyncThunk<
	FilesSdk.GetFilesByFolderIdResponse,
	FilesSdk.GetFilesByFolderIdRequest,
	{ rejectValue: any; state: RootState; dispatch: AsyncDispatchType }
>('projects-v3/fetch-files', async (data, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState()
		const headers = authSelectors.getAuthHeaders(state)

		const response = await FilesSdk.getFilesByFolderId(
			import.meta.env.VITE_API as string,
			headers,
			data,
		)

		return response
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue(error as any)
	}
})

export const getUpdatedDependencies = createAsyncThunk<
	V3ProjectSdk.GetUpdatedDependenciesResponse,
	V3ProjectSdk.GetUpdatedDependenciesRequest,
	{ rejectValue: any; state: RootState; dispatch: AsyncDispatchType }
>('projects-v3/updated-dependencies', async (data, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState()
		const headers = authSelectors.getAuthHeaders(state)

		const response = await V3ProjectSdk.getUpdatedDependencies(
			import.meta.env.VITE_API as string,
			headers,
			data,
		)

		return response
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue(error as any)
	}
})
