import { ComponentType, SVGProps } from 'react'

import { colors } from 'src/theme/colors'

type KeyIconProps = Omit<SVGProps<SVGSVGElement>, 'width'> & {
	width?: number
}
export const KeyIcon: ComponentType<KeyIconProps> = ({
	width = 12,
	stroke = colors.feldgrau['100'],
	...props
}) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} viewBox="0 0 24 24" fill="none" {...props}>
		<path
			stroke={stroke}
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
			d="M15 7a2 2 0 0 1 2 2m4 0a6 6 0 0 1-7.743 5.743L11 17H9v2H7v2H4a1 1 0 0 1-1-1v-2.586a1 1 0 0 1 .293-.707l5.964-5.964A6 6 0 1 1 21 9Z"
		/>
	</svg>
)
