/**
 * Here we define and configure all Cango's routes
 */

import { UserTypes } from '@cango-app/types'
import { ComponentType } from 'react'

import {
	BlueprintsIcon,
	InboxNavIcon,
	MyTasksIcon,
	ProjectsIcon,
	ContactsIcon,
	RolesIcon,
	TablesIcon,
	IconType,
	UsersIcon,
} from 'src/assets/icons'

const ALL = [
	UserTypes.Permission.Techmaster,
	UserTypes.Permission.Consultant,
	UserTypes.Permission.Admin,
	UserTypes.Permission.Advanced,
	UserTypes.Permission.Staff,
]

export enum RouteId {
	MyTasks = 'my-tasks',
	Blueprints = 'blueprints',
	Projects = 'projects',
	Inbox = 'inbox',
	Contacts = 'contacts',
	Blueprint = 'blueprint',
	Project = 'project',
	Skillsets = 'skillsets',
	Roles = 'roles',
	Users = 'users',
	Help = 'help',
	Tables = 'databases',
  Bid = 'bid',
}

export const MOBILE_ROUTES = [RouteId.MyTasks]

export const ROUTES: {
	permissions: UserTypes.Permission[]
	id: RouteId
	label: string
	description: string
	icon?: ComponentType<IconType>
}[] = [
	{
		permissions: ALL,
		id: RouteId.MyTasks,
		label: 'My tasks',
		description: 'Where I get things done',
		icon: MyTasksIcon,
	},
	{
		permissions: [
			UserTypes.Permission.Techmaster,
			UserTypes.Permission.Consultant,
			UserTypes.Permission.Admin,
		],
		id: RouteId.Blueprints,
		label: 'Blueprints',
		description: 'Client Blueprints',
		icon: BlueprintsIcon,
	},
	{
		permissions: ALL,
		id: RouteId.Projects,
		label: 'Projects',
		description: 'Create and Manage Projects',
		icon: ProjectsIcon,
	},
	{
		permissions: ALL,
		id: RouteId.Inbox,
		label: 'Inbox',
		description: 'Communication Center',
		icon: InboxNavIcon,
	},
	{
		permissions: ALL,
		id: RouteId.Contacts,
		label: 'Contacts',
		description: 'Address Book',
		icon: ContactsIcon,
	},
	{
		permissions: [
			UserTypes.Permission.Techmaster,
			UserTypes.Permission.Consultant,
			UserTypes.Permission.Admin,
			UserTypes.Permission.Advanced,
		],
		id: RouteId.Roles,
		label: 'Roles',
		description: 'Who does what in your organisation',
		icon: RolesIcon,
	},
	{
		permissions: [
			UserTypes.Permission.Techmaster,
			UserTypes.Permission.Consultant,
			UserTypes.Permission.Admin,
			UserTypes.Permission.Advanced,
		],
		id: RouteId.Users,
		label: 'Users',
		description: 'Manage users, their roles, permissions, and details',
		icon: UsersIcon,
	},
	{
		permissions: [
			UserTypes.Permission.Techmaster,
			UserTypes.Permission.Consultant,
			UserTypes.Permission.Admin,
			UserTypes.Permission.Advanced,
		],
		id: RouteId.Tables,
		label: 'Databases',
		description: 'Manage resources and data',
		icon: TablesIcon,
	},
	{
		permissions: [
			UserTypes.Permission.Techmaster,
			UserTypes.Permission.Consultant,
			UserTypes.Permission.Admin,
		],
		id: RouteId.Blueprint,
		label: 'Blueprint',
		description: 'Client Blueprint',
	},
	{
		permissions: [
			UserTypes.Permission.Techmaster,
			UserTypes.Permission.Consultant,
			UserTypes.Permission.Admin,
			UserTypes.Permission.Advanced,
		],
		id: RouteId.Project,
		label: 'Project',
		description: 'Project',
	},
]
