import { createAsyncThunk, Dispatch } from '@reduxjs/toolkit'
import { V3BlueprintSdk } from '@cango-app/sdk'
import { NavigateFunction } from 'react-router-dom'

import { showSnackbar } from 'src/helpers/snackbarManager'

import type { RootState, AsyncDispatchType } from '../../types'
import { selectors as authSelectors } from '../auth'
import { actions as tableActions } from '../tables'
import { errorHandler } from '../../../helpers/api'

export const createNewStep = createAsyncThunk<
	V3BlueprintSdk.CreateStepResponse,
	V3BlueprintSdk.CreateStepRequest,
	{ rejectValue: string; state: RootState; dispatch: AsyncDispatchType }
>('blueprints-v3/createNewStep', async (data, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState()
		const headers = authSelectors.getAuthHeaders(state)
		const response = await V3BlueprintSdk.createStep(
			import.meta.env.VITE_API as string,
			headers,
			data,
		)

		return {
			steps: response.steps,
			begins_with: response.begins_with,
		}
	} catch (error) {
		if ((error as any).response.data.message === 'duplicated_key') {
			showSnackbar('Oops! Please try saving again...')
		}
		errorHandler({ error, dispatch })
		return rejectWithValue((error as Error).message)
	}
})

export const updateStep = createAsyncThunk<
	V3BlueprintSdk.UpdateStepResponse,
	V3BlueprintSdk.UpdateStepRequest,
	{ rejectValue: any; state: RootState; dispatch: AsyncDispatchType }
>('blueprints-v3/updateStep', async (data, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState()
		const headers = authSelectors.getAuthHeaders(state)
		const response = await V3BlueprintSdk.updateStep(
			import.meta.env.VITE_API as string,
			headers,
			data,
		)

		dispatch(updateLiveTasks({ stepId: data._id }))

		return {
			updatedSteps: response.updatedSteps,
			begins_with: response.begins_with,
		}
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue(error as any)
	}
})

export const deleteStep = createAsyncThunk<
	V3BlueprintSdk.DeleteBlueprintStepRequest,
	V3BlueprintSdk.DeleteBlueprintStepRequest,
	{ rejectValue: string; dispatch: AsyncDispatchType; state: RootState }
>('blueprints-v3/deleteStep', async (data, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState()
		const headers = authSelectors.getAuthHeaders(state)
		await V3BlueprintSdk.deleteStep(import.meta.env.VITE_API as string, headers, data)
		return { ...data }
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue((error as Error).message)
	}
})

export const updateName = createAsyncThunk<
	{ blueprintId: string; name: string },
	{ blueprintId: string; name: string },
	{ rejectValue: string; dispatch: Dispatch }
>('blueprints-v3/updateName', async (data, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState() as RootState
		const headers = authSelectors.getAuthHeaders(state)
		await V3BlueprintSdk.updateName(import.meta.env.VITE_API as string, headers, data)
		return data
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue((error as Error).message)
	}
})

export const updateDatabaseTable = createAsyncThunk<
	V3BlueprintSdk.UpdateDatabaseTableResponse,
	V3BlueprintSdk.UpdateDatabaseTableRequest,
	{ rejectValue: string; dispatch: Dispatch }
>('blueprints-v3/updateDatabaseTable', async (data, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState() as RootState
		const headers = authSelectors.getAuthHeaders(state)
		const response = await V3BlueprintSdk.updateDatabaseTable(
			import.meta.env.VITE_API as string,
			headers,
			data,
		)
		return response
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue((error as Error).message)
	}
})

export const updateTemplate = createAsyncThunk<
	{ blueprintId: string; sectionId: string; stepId: string; fileIds: string[] },
	V3BlueprintSdk.UpdateTemplateRequest & { blueprintId: string; sectionId: string },
	{ rejectValue: string; state: RootState; dispatch: Dispatch }
>('blueprints-v3/updateTemplate', async (data, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState()
		const headers = authSelectors.getAuthHeaders(state)
		await V3BlueprintSdk.updateTemplate(import.meta.env.VITE_API as string, headers, {
			stepId: data.stepId,
			fileIds: data.fileIds,
		})
		return data
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue((error as Error).message)
	}
})

export const getCards = createAsyncThunk<
	V3BlueprintSdk.Card[],
	void,
	{ rejectValue: string; dispatch: Dispatch }
>('blueprints-v3/getBlueprintCards', async (_, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState() as RootState
		const headers = authSelectors.getAuthHeaders(state)
		const blueprintCards = await V3BlueprintSdk.getCards(
			import.meta.env.VITE_API as string,
			headers,
		)

		return blueprintCards
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue((error as Error).message)
	}
})

export const clone = createAsyncThunk<
	V3BlueprintSdk.CloneBlueprintResponse,
	string,
	{ rejectValue: string; state: RootState; dispatch: Dispatch }
>('blueprints-v3/clone', async (_id, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState()
		const headers = authSelectors.getAuthHeaders(state)
		const response = await V3BlueprintSdk.clone(import.meta.env.VITE_API as string, headers, {
			_id,
		})
		return response
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue((error as Error).message)
	}
})

export const createBlankBlueprint = createAsyncThunk<
	V3BlueprintSdk.CreateBlankBlueprintResponse,
	void,
	{ rejectValue: any; state: RootState; dispatch: Dispatch }
>('blueprints-v3/createBlankBlueprint', async (_, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState()
		const headers = authSelectors.getAuthHeaders(state)
		const response = await V3BlueprintSdk.createBlankBlueprint(
			import.meta.env.VITE_API as string,
			headers,
		)
		return response
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue(error as any)
	}
})

export const getById = createAsyncThunk<
	V3BlueprintSdk.GetByIdResponse,
	V3BlueprintSdk.GetByIdRequest,
	{ rejectValue: any; state: RootState; dispatch: AsyncDispatchType }
>('blueprints-v3/getById', async (data, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState()
		const headers = authSelectors.getAuthHeaders(state)
		const response = await V3BlueprintSdk.getById({
			baseURL: import.meta.env.VITE_API as string,
			authHeaders: headers,
			data,
		})

		return response
	} catch (error) {
		if ((error as any).response.data.message === 'cannot_find_blueprint') {
			return rejectWithValue('cannot_find_blueprint')
		}
		errorHandler({ error, dispatch })
		return rejectWithValue(error as any)
	}
})

export const publish = createAsyncThunk<
	{ shouldPublish: boolean; blueprintId: string },
	{ shouldPublish: boolean; blueprintId: string },
	{ rejectValue: any; dispatch: Dispatch }
>('blueprints-v3/publish', async (data, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState() as RootState
		const headers = authSelectors.getAuthHeaders(state)
		await V3BlueprintSdk.publish(import.meta.env.VITE_API as string, headers, data)
		return { shouldPublish: data.shouldPublish, blueprintId: data.blueprintId }
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue(error as any)
	}
})

export const deleteBlueprint = createAsyncThunk<
	string,
	{ blueprintId: string; navigate: NavigateFunction },
	{ rejectValue: any; dispatch: Dispatch }
>(
	'blueprints-v3/delete',
	async ({ blueprintId, navigate }, { getState, rejectWithValue, dispatch }) => {
		try {
			const state = getState() as RootState
			const headers = authSelectors.getAuthHeaders(state)
			await V3BlueprintSdk.deleteBlueprint(import.meta.env.VITE_API as string, headers, {
				blueprintId,
			})
			await navigate(`/blueprints`, { replace: true })
			return blueprintId
		} catch (error) {
			errorHandler({ error, dispatch })
			return rejectWithValue(error as any)
		}
	},
)

export const updateBlueprint = createAsyncThunk<
	V3BlueprintSdk.UpdateBlueprintResponse,
	V3BlueprintSdk.UpdateBlueprintRequest,
	{ rejectValue: any; dispatch: Dispatch; state: RootState }
>('blueprints-v3/update-blueprint', async (data, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState()
		const headers = authSelectors.getAuthHeaders(state)
		const updatedBlueprint = await V3BlueprintSdk.updateBlueprint({
			baseURL: import.meta.env.VITE_API as string,
			authHeaders: headers,
			data,
		})
		return updatedBlueprint
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue(error as any)
	}
})

export const updateStepOrder = createAsyncThunk<
	V3BlueprintSdk.UpdateStepOrderResponse,
	V3BlueprintSdk.UpdateStepOrderRequest & { blueprintId: string },
	{ rejectValue: any; dispatch: AsyncDispatchType; state: RootState }
>('blueprints-v3/step-order', async (data, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState()
		const headers = authSelectors.getAuthHeaders(state)
		const response = await V3BlueprintSdk.updateStepOrder(
			import.meta.env.VITE_API as string,
			headers,
			data,
		)

		dispatch(
			updateLiveTasks({
				stepId: data._id,
			}),
		)

		return response
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue(error as any)
	}
})

export const updateLiveTasks = createAsyncThunk<
	V3BlueprintSdk.UpdateLiveTasksResponse,
	V3BlueprintSdk.UpdateLiveTasksRequest,
	{ rejectValue: any; dispatch: Dispatch; state: RootState }
>('blueprints-v3/update-live-tasks', async (data, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState()
		const headers = authSelectors.getAuthHeaders(state)
		const response = await V3BlueprintSdk.updateLiveTasks(
			import.meta.env.VITE_API as string,
			headers,
			data,
		)
		if (!response.length) {
			showSnackbar('All tasks up to date')
		} else {
			showSnackbar(`${response.length} tasks updated!`, { variant: 'success' })
		}

		return response
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue(error as any)
	}
})
