import { V3BlueprintTypes } from '@cango-app/types'
import { V3BlueprintSdk } from '@cango-app/sdk'
import Chip from '@mui/material/Chip'
import { ComponentType, useMemo, useState } from 'react'

import { Box } from './box'
import { Text } from './text'

type ParentProps = {
	parents: V3BlueprintSdk.Parent[]
	requireEvery: boolean
	shouldCreateForEveryOption: boolean
}

const Parents: ComponentType<ParentProps> = ({
	parents,
	requireEvery,
	shouldCreateForEveryOption,
}) => {
	const [showAll, setShowAll] = useState(false)
	const { parentsWithExtraOptions, moreThanOneRenderableParent } = useMemo(() => {
		return parents.reduce(
			(
				acc: { parentsWithExtraOptions: Set<string>; moreThanOneRenderableParent: boolean },
				parent,
			) => {
				const extraOptions = parent.options.filter(
					(option) =>
						option._id !== V3BlueprintTypes.TaskPhase.Commence &&
						option._id !== V3BlueprintTypes.TaskPhase.Complete,
				)
				if (extraOptions.length > 1) {
					acc.parentsWithExtraOptions.add(parent._id)
				}

				if (!acc.moreThanOneRenderableParent) {
					acc.moreThanOneRenderableParent =
						parents.filter(
							(parent) =>
								parent.options.length > 1 ||
								(parent.options.length === 1 &&
									parent.options[0]._id !== V3BlueprintTypes.TaskPhase.Commence),
						).length > 1
				}

				return acc
			},
			{ parentsWithExtraOptions: new Set<string>(), moreThanOneRenderableParent: false },
		)
	}, [JSON.stringify(parents)])

	return (
		<Box>
			{!shouldCreateForEveryOption && moreThanOneRenderableParent && (
				<Text fontSize={14} fontWeight={'bold'}>
					{requireEvery ? 'All' : 'Any'} of
				</Text>
			)}
			{parents.map((parent, i) => {
				if (
					parent.options.length === 1 &&
					parent.options[0]._id === V3BlueprintTypes.TaskPhase.Commence
				) {
					return null
				}
				if (
					parent.options.length === 1 &&
					parent.options[0]._id === V3BlueprintTypes.TaskPhase.Complete
				) {
					return (
						<Chip
							key={parent._id}
							label={parent.name}
							style={{
								marginTop: '3px',
								marginBottom: '3px',
								marginLeft: '0px',
								marginRight: '1px',
							}}
						/>
					)
				}
				return (
					<Box key={parent._id}>
						{shouldCreateForEveryOption && parentsWithExtraOptions.has(parent._id) && (
							<Text fontSize={14} fontWeight={'bold'}>
								Creates a new chain for every selection
							</Text>
						)}
						<Chip
							label={parent.name}
							style={{
								marginTop: '3px',
								marginBottom: '3px',
								marginLeft: '0px',
								marginRight: '1px',
								borderRadius: '20px 0 0 20px',
							}}
						/>
						{parent.options.map((option, optionIndex) => {
							const hasTooManyOptions = parent.options.length >= 6

							if (!showAll && hasTooManyOptions && optionIndex === 5) {
								return (
									<Chip
										key={option._id}
										label={`... and ${parent.options.length - 5} more`}
										color={'primary'}
										variant={'filled'}
										style={{
											marginTop: '3px',
											marginBottom: '3px',
											marginLeft: '1px',
											marginRight: '1px',
											borderRadius: 1,
										}}
										onClick={(event) => {
											setShowAll(true)
											event.stopPropagation()
										}}
									/>
								)
							}

							if (!showAll && hasTooManyOptions && optionIndex > 5) return null

							return (
								<Chip
									key={option._id}
									label={option.label}
									color={'primary'}
									variant={'filled'}
									style={{
										marginTop: '3px',
										marginBottom: '3px',
										marginLeft: '1px',
										marginRight: '1px',
										borderRadius: 1,
									}}
								/>
							)
						})}
					</Box>
				)
			})}
		</Box>
	)
}

export default Parents
