import { MyTasksV3Sdk } from '@cango-app/sdk'
import { ClientTypes, V3ClientTypes, V3BlueprintTypes } from '@cango-app/types'

export type Project = MyTasksV3Sdk.Project
export type Section = MyTasksV3Sdk.Section

export type AsRequiredInstance = {
	multiUseId: string
	multiUseName: string
	instanceName: string
	tasks: TaskWithCompletable[]
}

type IsCompletable = {
	isCompletable: boolean
}

type SubtaskParent = {
	subtaskParentId?: string
}

type WithDescendants = {
	descendants: V3ClientTypes.Project.Task[]
}

export type TaskWithCompletable = V3ClientTypes.Project.Task &
	IsCompletable &
	SubtaskParent &
	WithDescendants

export enum TaskNotesState {
	Loading = 'loading',
	Error = 'error',
	Idle = 'idle',
}

export enum MyTasksLoadingState {
	Loading = 'loading',
	BackgroundLoading = 'backgroundLoading',
	Error = 'error',
	Idle = 'idle',
	Fetched = 'fetched',
}

export enum TaskListType {
	Active = 'active',
	ProjectTasks = 'project-tasks',
}

export interface MyTasksV3State {
	myTasksState: MyTasksLoadingState
	projectLoading?: string
	sectionLoading?: string
	selectedSectionId?: string
	selectedTaskId?: string
	projects: {
		[_id: string]: Project
	}
	flaggedTaskId?: string
	isChatModalOpen: boolean
	taskListType: TaskListType
	sectionIdLoading?: string
	tasks: V3ClientTypes.Project.Task[]
	filesToUpload: number
	blockOptions: MyTasksV3Sdk.BlockOptionsResponse
	isLoadingBlockOptions: boolean
}

export enum SectionBlockedReason {
	None = 'none',
	TasksAssignedToOtherUser = 'tasks-assigned-to-other-user',
}
