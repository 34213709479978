import { ThunkDispatch, Action } from '@reduxjs/toolkit'

import { NotesState } from './modules/notes'
import { AuthState } from './modules/auth'
import { UserState } from './modules/user'
import { RolesState } from './modules/roles'
import { ContactState } from './modules/contacts'
import { ConfigState } from './modules/config'
import { BlueprintsStateV3 } from './modules/blueprints-v3'
import { ProjectsStateV3 } from './modules/projects-v3'
import { MyTasksV3State } from './modules/my-tasks-v3'
import { PersistedConfigState } from './modules/persisted-config'
import { RemoteConfigState } from './modules/remote-config'
import { TableState } from './modules/tables'
import { SessionConfigState } from './modules/session-config'
import { FilesState } from './modules/persisted-files'

export enum CangoReduxModuleName {
	CangoAuth = 'cangoAuth',
	CangoUser = 'cangoUser',
	CangoRoles = 'cangoRoles',
	CangoContacts = 'cangoContacts',
	CangoConfig = 'cangoConfig',
	CangoBlueprintsV3 = 'cangoBlueprintsV3',
	CangoProjectsV3 = 'cangoProjectsV3',
	CangoMyTasksV3 = 'cangoMyTasksV3',
	CangoNotes = 'cangoNotes',
	CangoPersistedConfig = 'cangoPersistedConfig',
	CangoRemoteConfig = 'cangoRemoteConfig',
	CangoTables = 'cangoTables',
	CangoSession = 'cangoSession',
	CangoPersistedFiles = 'cangoPersistedFiles',
}

export type RootState = {
	[CangoReduxModuleName.CangoAuth]: AuthState
	[CangoReduxModuleName.CangoConfig]: ConfigState
	[CangoReduxModuleName.CangoUser]: UserState
	[CangoReduxModuleName.CangoRoles]: RolesState
	[CangoReduxModuleName.CangoContacts]: ContactState
	[CangoReduxModuleName.CangoBlueprintsV3]: BlueprintsStateV3
	[CangoReduxModuleName.CangoProjectsV3]: ProjectsStateV3
	[CangoReduxModuleName.CangoMyTasksV3]: MyTasksV3State
	[CangoReduxModuleName.CangoNotes]: NotesState
	[CangoReduxModuleName.CangoPersistedConfig]: PersistedConfigState
	[CangoReduxModuleName.CangoRemoteConfig]: RemoteConfigState
	[CangoReduxModuleName.CangoTables]: TableState
	[CangoReduxModuleName.CangoSession]: SessionConfigState
	[CangoReduxModuleName.CangoPersistedFiles]: FilesState
}

export type AsyncDispatchType = ThunkDispatch<RootState, void, Action>
