import { V3BlueprintTypes, V3ClientTypes } from '@cango-app/types'

import { ChainMap, V3BlueprintStep } from 'src/store/modules/blueprints-v3'
import { ListedTask } from 'src/store/modules/projects-v3'

export const getTaskDescendants = (
	allTasks: V3ClientTypes.Project.Task[],
	taskId: string | undefined,
) => {
	if (!taskId) return []
	const taskMap = new Map(allTasks.map((task) => [task._id, task]))
	const offspring = new Set<string>()
	const taversedTaskIds = new Set<string>()
	const isChildTask = (
		task: V3ClientTypes.Project.Task,
		parentTask: V3ClientTypes.Project.Task,
	) => {
		if (taversedTaskIds.has(task._id)) return false
		taversedTaskIds.add(task._id)
		if (task.isSection) return false
		if (parentTask.isMultiUse && !task.instance?._id) return false
		if (task.step_id) {
			return (
				parentTask.children.some((child) => child.steps.includes(task.step_id ?? '')) &&
				(parentTask.instance?._id ? task.instance?._id === parentTask.instance._id : true)
			)
		}
		return task.parent?._id === parentTask._id
	}

	const recursivelyGetChildren = (parentId: string) => {
		const parentTask = taskMap.get(parentId)
		if (!parentTask) return

		allTasks.forEach((task) => {
			if (isChildTask(task, parentTask)) {
				offspring.add(task._id)
				recursivelyGetChildren(task._id)
			}
		})
	}

	recursivelyGetChildren(taskId)

	return allTasks.filter((task) => offspring.has(task._id))
}

export const recursivelyGetParents = (
	hierarchy: string[],
	task: ListedTask | undefined,
	allTasks: ListedTask[],
): string[] => {
	if (!task) return hierarchy

	const filteredTasksForChainOrInstance = allTasks.filter((_task) => {
		if (task.instance?._id) {
			return !_task.instance?._id || _task.instance?._id === task.instance?._id
		}

		if (task.chain?.chain_id) {
			return !_task.chain?.chain_id || _task.chain?.chain_id === task.chain?.chain_id
		}

		return true
	})

	const parentTask = filteredTasksForChainOrInstance.find(
		(_task) =>
			_task.isParent &&
			_task.step_id &&
			_task.children.some(
				(child) =>
					child._id === V3BlueprintTypes.TaskPhase.Commence &&
					child.steps.includes(task.step_id ?? ''),
			),
	)

	if (parentTask) {
		return [...recursivelyGetParents(hierarchy, parentTask, allTasks), task._id]
	} else {
		return [...hierarchy, task._id]
	}
}

export const recursivelyGetBlueprintParents = ({
	hierarchy,
	step,
	allSteps,
	previouslyIteratedSteps,
}: {
	hierarchy: string[]
	step: V3BlueprintStep
	allSteps: V3BlueprintStep[]
	previouslyIteratedSteps?: Set<string>
}): string[] => {
	if (previouslyIteratedSteps?.has(step._id)) return hierarchy
	if (!step) return hierarchy
	let iteratedSteps = previouslyIteratedSteps
	if (!iteratedSteps) {
		iteratedSteps = new Set()
	}
	iteratedSteps.add(step._id)
	if (!step.parents.length) return [...hierarchy, step._id]
	const stepCommenceParents = step.parents.filter((_parent) =>
		_parent.options.some((option) => option._id === V3BlueprintTypes.TaskPhase.Commence),
	)
	if (!stepCommenceParents.length) return [...hierarchy, step._id]

	const parentTask = allSteps.find((_parentTask) => {
		return stepCommenceParents.some((_parent) => _parent._id === _parentTask._id)
	})

	if (parentTask) {
		return [
			...new Set([
				...recursivelyGetBlueprintParents({
					hierarchy,
					step: parentTask,
					allSteps,
					previouslyIteratedSteps: iteratedSteps,
				}),
				step._id,
			]),
		]
	} else {
		return [...new Set([...hierarchy, step._id])]
	}
}

export const getStepChain = ({
	step,
	allSteps,
	previouslyIteratedSteps,
}: {
	step: Pick<V3BlueprintStep, '_id' | 'parents' | 'chain'>
	allSteps: Map<string, V3BlueprintStep>
	previouslyIteratedSteps?: Set<string>
}): ChainMap => {
	let iteratedSteps = previouslyIteratedSteps

	if (!iteratedSteps) {
		iteratedSteps = new Set<string>()
	}

	if (iteratedSteps.has(step._id)) {
		return new Map()
	}
	iteratedSteps.add(step._id)
	if (!step.parents.length) {
		return new Map()
	}
	const stepChains = new Map<string, V3BlueprintStep['chain']>()
	const parentsWithoutChains: V3BlueprintStep[] = []

	if (step.chain?.label) {
		stepChains.set(step._id, step.chain)
	}

	step.parents.forEach((_parent) => {
		const parentStep = allSteps.get(_parent._id)
		if (!parentStep) return
		if (parentStep.isLastStepInChain) return

		const hasExtraOptions = _parent.options.some((_option) =>
			[V3BlueprintTypes.TaskPhase.Commence, V3BlueprintTypes.TaskPhase.Complete].includes(
				_option._id as V3BlueprintTypes.TaskPhase,
			),
		)
		if (hasExtraOptions && parentStep.chain?.label) {
			stepChains.set(parentStep._id, parentStep.chain)
		} else {
			parentsWithoutChains.push(parentStep)
		}
	})

	parentsWithoutChains.forEach((_parent) => {
		const parentChains = getStepChain({
			step: _parent,
			allSteps,
			previouslyIteratedSteps: iteratedSteps,
		})
		for (const [key, value] of parentChains) {
			stepChains.set(key, value)
		}
	})

	return stepChains
}

interface BaseTask {
	_id: string
	step_id?: string
}

export const sortDocuments = <T extends BaseTask>(documents: T[], definedOrder: string[]): T[] => {
	if (!documents.length || !definedOrder.length) return documents
	const documentsCopy = [...documents]
	const sortedDocs = documentsCopy.sort((a, b) => {
		if (!definedOrder) {
			definedOrder = []
		}
		let indexA = definedOrder.indexOf(a.step_id ?? a._id)
		let indexB = definedOrder.indexOf(b.step_id ?? b._id)

		if (indexA === -1) indexA = definedOrder.length
		if (indexB === -1) indexB = definedOrder.length

		return indexA - indexB
	})
	return sortedDocs
}

interface BaseTaskWithHierarchy extends BaseTask {
	name: string
	hierarchy: string[]
}

export const sortDocumentsUsingHierarchy = <T extends BaseTaskWithHierarchy>(
	documents: T[],
): T[] => {
	const docMap = new Map<string, T>()
	documents.forEach((doc) => {
		docMap.set(doc._id, doc)
	})

	const findRootIndex = (doc: T): number => {
		if (!doc.hierarchy || doc.hierarchy.length < 2) {
			return documents.indexOf(doc)
		}
		const parentID = doc.hierarchy[doc.hierarchy.length - 2]
		const parentDoc = docMap.get(parentID)
		return parentDoc ? findRootIndex(parentDoc) : -1
	}

	return documents.sort((a, b) => {
		const aIndex = findRootIndex(a)
		const bIndex = findRootIndex(b)

		if (aIndex !== bIndex) {
			return aIndex - bIndex
		}
		return a.name.localeCompare(b.name)
	})
}
