import { ComponentType } from 'react'

import { Box } from '../box'
import { Chip } from '../chip'
import { Text } from '../text'

export const ChainsList: ComponentType<{
	chains: { color: string; label: string; selectedOption?: string }[]
}> = ({ chains }) => {
	return (
		<Box>
			{chains.map(({ label, color, selectedOption }, index) => (
				<Chip
					key={`${label}-${index}`}
					label={
						<Text fontSize={12}>
							{label}
							<b>{selectedOption ? `: ${selectedOption}` : ''}</b>
						</Text>
					}
					sx={{ backgroundColor: color, fontSize: 12, color: '#fff', mr: 0.5, mb: 0.5 }}
					size="small"
				/>
			))}
		</Box>
	)
}
