import React, { ComponentType, ReactNode, useContext } from 'react'
import { SxProps } from '@mui/material'

import { useIsMobile } from 'src/hooks/useMobile'
import { DriveFilesContext } from 'src/providers/drive-files-provider'

import { DriveUploadWeb } from './drive-upload-web'
import { DriveUploadMobile } from './drive-upload-mobile'

export type DriveUploadProps = {
	containerStyles?: SxProps
	label?: string
	parentFolderId: string
	parentFolderName: string
	onChange: (fileIds: string[]) => Promise<void> | void
	isDisabled?: boolean
	isLoading?: boolean
	ctaVariant?: 'button' | 'replaceIcon'
	fileIds: string[]
	variant?: 'modal' | 'embedded'
	onlyUpload?: boolean
	children?: (props: { onClick: () => void }) => ReactNode
}

const DriveUploadContainer: ComponentType<DriveUploadProps> = (props) => {
	const contextProps = useContext(DriveFilesContext)
	const isMobile = useIsMobile()

	if (isMobile) {
		return <DriveUploadMobile {...contextProps} {...props} />
	}

	return <DriveUploadWeb {...contextProps} {...props} />
}

export default DriveUploadContainer
