import { Dispatch, createAsyncThunk } from '@reduxjs/toolkit'
import { MyTasksV3Sdk, V3ProjectSdk } from '@cango-app/sdk'
import { V3BlueprintTypes, V3ClientTypes } from '@cango-app/types'
import _findIndex from 'lodash/findIndex'

import { recursivelyGetParents, sortDocuments } from 'src/helpers/chains'
import { showSnackbar } from 'src/helpers/snackbarManager'

import { RootState, AsyncDispatchType, CangoReduxModuleName } from '../../types'
import { selectors as authSelectors } from '../auth'
import { selectors as userSelectors } from '../user'
import { errorHandler } from '../../../helpers/api'

import { selectors as myTasksSelectors } from './selectors'
import { MyTasksV3State, Project, Section } from './types'

export const fetchSectionTasks = createAsyncThunk<
	MyTasksV3Sdk.GetSectionTasksResponse,
	{ projectId: string; sectionId: string; instanceId?: string },
	{ rejectValue: string; dispatch: Dispatch; state: RootState }
>(
	'my-tasks-v3/fetch-section-tasks',
	async ({ projectId, sectionId, instanceId }, { getState, rejectWithValue, dispatch }) => {
		try {
			const state = getState()
			const headers = authSelectors.getAuthHeaders(state)

			const response = await MyTasksV3Sdk.getSectionTasks(
				import.meta.env.VITE_API as string,
				headers,
				{
					sectionId: sectionId,
					sectionInstanceId: instanceId,
					projectId,
				},
			)
			return response
		} catch (error) {
			errorHandler({ error, dispatch })
			return rejectWithValue((error as Error).message)
		}
	},
)

export const fetchBlockedTasks = createAsyncThunk<
	MyTasksV3Sdk.GetSectionTasksResponse,
	string,
	{ rejectValue: string; dispatch: Dispatch; state: RootState }
>('my-tasks-v3/fetch-blocked-tasks', async (projectId, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState()
		const headers = authSelectors.getAuthHeaders(state)
		const projects = state[CangoReduxModuleName.CangoMyTasksV3].projects

		const allSectionIds = projects[projectId].sections.reduce((sectionIds: string[], section) => {
			if (!section.step_id) {
				return sectionIds
			}
			sectionIds.push(section.step_id)
			return sectionIds
		}, [])

		const response = await MyTasksV3Sdk.getBlockedTasks(
			import.meta.env.VITE_API as string,
			headers,
			{
				sectionIds: allSectionIds,
				projectId,
			},
		)
		return response
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue((error as Error).message)
	}
})

export const fetchBlockOptions = createAsyncThunk<
	MyTasksV3Sdk.BlockOptionsResponse,
	string,
	{ rejectValue: string; dispatch: Dispatch; state: RootState }
>('my-tasks-v3/fetch-block-options', async (projectId, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState()
		const headers = authSelectors.getAuthHeaders(state)

		const response = await MyTasksV3Sdk.blockOptions(import.meta.env.VITE_API as string, headers, {
			projectId,
		})
		return response
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue((error as Error).message)
	}
})

export const fetchAllProjects = createAsyncThunk<
	{ projects: MyTasksV3State['projects']; error?: string },
	| {
			isMobile: boolean
			focusSection?: {
				projectId: string
				sectionId: string
			}
	  }
	| undefined,
	{
		rejectValue: { projects: MyTasksV3State['projects']; error?: string }
		dispatch: AsyncDispatchType
		state: RootState
	}
>('my-tasks-v3/fetch-projects', async (options, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState()
		const headers = authSelectors.getAuthHeaders(state)
		const response = await MyTasksV3Sdk.getProjects(import.meta.env.VITE_API as string, headers)

		let selectedSectionId: string | undefined
		let selectedProjectId: string | undefined

		const projectsAsObject = response.reduce((acc: MyTasksV3State['projects'], currValue) => {
			const sectionOrder = currValue.section_order
			currValue.sections = sortDocuments(currValue.sections, sectionOrder ?? [])
			acc[currValue._id] = currValue
			return acc
		}, {})

		if (options?.focusSection && !options.isMobile) {
			selectedSectionId = options.focusSection.sectionId
			selectedProjectId = options.focusSection.projectId
		} else if (response.length && !options?.isMobile) {
			selectedProjectId = response[0]?._id
			selectedSectionId = projectsAsObject[selectedProjectId].sections[0]._id
		}

		return { projects: projectsAsObject, selectedSectionId, selectedProjectId }
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue({ error: (error as Error).message, projects: {} })
	}
})

export const fetchProjectsById = createAsyncThunk<
	{ projects: MyTasksV3State['projects'] } & {
		selectedSectionId?: string
		selectedProjectId?: string
	},
	{
		ids: string[]
	},
	{ rejectValue: string; dispatch: AsyncDispatchType; state: RootState }
>('my-tasks-v3/fetch-projects-by-id', async ({ ids }, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState()
		const headers = authSelectors.getAuthHeaders(state)
		const response = await MyTasksV3Sdk.getProjects(import.meta.env.VITE_API as string, headers, {
			projectIds: ids,
		})

		let selectedSectionId: string | undefined
		let selectedProjectId: string | undefined

		const projectsAsObject = response.reduce((acc: MyTasksV3State['projects'], currValue) => {
			acc[currValue._id] = currValue
			return acc
		}, {})

		if (response.length) {
			selectedSectionId = response[0].sections[0]._id
			selectedProjectId = response[0]._id
		}

		return { projects: projectsAsObject, selectedSectionId, selectedProjectId }
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue((error as Error).message)
	}
})

export const completeTask = createAsyncThunk<
	{
		newTasks: V3ClientTypes.Project.Task[]
		projectId: string
		taskId: string
		stepId?: string
		sectionStepId?: string
		sectionId: string
		nextTaskId: string | undefined
		instances: V3ClientTypes.Project.TaskInstance[]
		sections: Omit<
			Section,
			'userPendingTaskCount' | 'totalTaskCount' | 'pendingTasksForOtherUsers'
		>[]
		sectionCount: {
			[key: string]: {
				userPendingTaskCount: number
				totalTaskCount: number
				pendingTasksForOtherUsers: number
			}
		}
		isProjectComplete: boolean
	},
	V3ProjectSdk.CompleteTaskRequest,
	{ rejectValue: string; state: RootState; dispatch: AsyncDispatchType }
>(
	'my-tasks-v3/complete-task',
	async ({ taskId, ...rest }, { getState, rejectWithValue, dispatch }) => {
		try {
			const state = getState()
			const headers = authSelectors.getAuthHeaders(state)
			const organisationId = authSelectors.getOrganisationId(state)
			const currentUserId = userSelectors.getCurrentUserId(state)
			const mappedTasks = myTasksSelectors.getMappedSectionTasks(state)
			const task = mappedTasks.get(taskId)
			const section = myTasksSelectors.getSelectedSection(state)
			let nextTaskId = myTasksSelectors.getNextEligibleTask(state, 1, true)?._id

			if (!section) {
				throw new Error('No section selected')
			}

			const response = await V3ProjectSdk.completeTask(
				import.meta.env.VITE_API as string,
				headers,
				{
					taskId,
					...rest,
				},
			)

			const { currentSectionTasks, otherSectionTasks } = response.tasks.reduce(
				(acc, _task) => {
					if (_task.assignee !== currentUserId) return acc
					if (_task.section_id === task?.section_id) {
						acc.currentSectionTasks.push(_task)
					} else {
						acc.otherSectionTasks.push(_task)
					}
					return acc
				},
				{
					currentSectionTasks: [] as V3ClientTypes.Project.Task[],
					otherSectionTasks: [] as V3ClientTypes.Project.Task[],
				},
			)

			if (!nextTaskId && currentSectionTasks.length) {
				const filteredTasks = currentSectionTasks.filter((task) => {
					if (task.isMultiUse || task.isSection || task.isParent) return false
					return true
				})
				nextTaskId = filteredTasks[0]?._id
			} else if (task?.subtaskParentId && response.tasks.length) {
				const allTasks = [...mappedTasks.values(), ...currentSectionTasks]
				const newSubtasksOfSameGroupAsCompletedTask = currentSectionTasks.find((_task) => {
					const hierarchy = recursivelyGetParents([], _task, allTasks)
					if (task.subtaskParentId && hierarchy.includes(task.subtaskParentId)) {
						return true
					}
					return false
				})
				if (newSubtasksOfSameGroupAsCompletedTask) {
					nextTaskId = newSubtasksOfSameGroupAsCompletedTask._id
				}
			}

			const sections = response.tasks.filter((task) => task.isSection)

			const newSections = sections.map(
				(
					_section,
				): Omit<
					Section,
					'userPendingTaskCount' | 'totalTaskCount' | 'pendingTasksForOtherUsers'
				> => {
					return {
						..._section,
						isBlocked: otherSectionTasks.some((task) => task.isFlagged),
					}
				},
			)

			if (
				task &&
				task?.actions.some((_action) =>
					[
						V3BlueprintTypes.ActionEnum.FileUpload,
						V3BlueprintTypes.ActionEnum.FileTemplate,
					].includes(_action.type),
				)
			) {
				dispatch(getUpdatedDependencies({ task: task._id }))
			}

			return {
				...rest,
				newTasks: [...currentSectionTasks, ...otherSectionTasks],
				taskId,
				stepId: task?.step_id,
				nextTaskId,
				instances: response.instances,
				sections: newSections,
				projectId: section.project_id,
				sectionId: section._id,
				sectionStepId: section.step_id,
				sectionCount: response.sectionCount,
				isProjectComplete: response.isProjectComplete,
			}
		} catch (error) {
			errorHandler({ error, dispatch })
			return rejectWithValue(error as any)
		}
	},
)

export const updateTask = createAsyncThunk<
	V3ProjectSdk.UpdateTaskResponse,
	V3ProjectSdk.UpdateTaskRequest,
	{ rejectValue: string; state: RootState; dispatch: Dispatch }
>(
	'my-tasks-v3/update-task',
	async ({ taskId, update }, { getState, rejectWithValue, dispatch }) => {
		try {
			const state = getState()
			const headers = authSelectors.getAuthHeaders(state)
			const updatedTask = await V3ProjectSdk.updateTask({
				baseURL: import.meta.env.VITE_API as string,
				authHeaders: headers,
				data: {
					taskId,
					update,
				},
			})

			return updatedTask
		} catch (error) {
			errorHandler({ error, dispatch })
			return rejectWithValue(error as any)
		}
	},
)

export const flagTask = createAsyncThunk<
	{ sectionId: string; projectId: string },
	MyTasksV3Sdk.FlatTaskRequest & { sectionId: string },
	{ rejectValue: string; state: RootState; dispatch: Dispatch }
>('my-tasks-v3/flag-task', async (data, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState()
		const headers = authSelectors.getAuthHeaders(state)
		const selectedProjectId = myTasksSelectors.getSelectedProjectId(state)
		const selectedSectionId = myTasksSelectors.getSelectedSectionId(state)
		if (!selectedSectionId || !selectedProjectId) {
			throw new Error('No section selected')
		}
		await MyTasksV3Sdk.flagTask(import.meta.env.VITE_API as string, headers, data)
		return {
			sectionId: selectedSectionId,
			projectId: selectedProjectId,
		}
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue(error as any)
	}
})

export const assignUsersToRoles = createAsyncThunk<
	Project['roles'],
	MyTasksV3Sdk.AssignUsersToRolesRequest,
	{ rejectValue: string; state: RootState; dispatch: Dispatch }
>('my-tasks-v3/assign-users-to-roles', async (data, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState()
		const headers = authSelectors.getAuthHeaders(state)
		await MyTasksV3Sdk.assignUsersToRoles(import.meta.env.VITE_API as string, headers, data)
		const projectRoles = myTasksSelectors.getSelectedProjectRoles(state)

		const projectRolesCopy = [...projectRoles]
		data.assignments.forEach((assignment) => {
			const roleIndex = _findIndex(projectRolesCopy, ['role._id', assignment.roleId])
			if (roleIndex === -1) {
				projectRolesCopy.push({
					role: assignment.roleId,
					user: assignment.userId,
				})
				return
			}
			projectRolesCopy[roleIndex] = {
				...projectRolesCopy[roleIndex],
				user: assignment.userId,
			}
		})

		return projectRolesCopy
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue(error as any)
	}
})

export const assignContactsToProject = createAsyncThunk<
	Project['externals'],
	V3ProjectSdk.AssignContactsToProjectRequest,
	{ rejectValue: string; state: RootState; dispatch: Dispatch }
>('my-tasks-v3/assign-contacts', async (data, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState()
		const headers = authSelectors.getAuthHeaders(state)
		await V3ProjectSdk.assignContactsToProject(import.meta.env.VITE_API as string, headers, data)
		const projectExternals = myTasksSelectors.getSelectedProjectExternals(state)

		const updatedRoles = projectExternals.reduce((acc: Project['externals'], projectExternal) => {
			const assignment = data.assignments.find(
				(assignment) => assignment.roleId === projectExternal.role,
			)

			if (assignment) {
				acc.push({
					...projectExternal,
					contact: assignment.contactId,
				})
			} else {
				acc.push(projectExternal)
			}
			return acc
		}, [])

		if (
			!data.assignments.every((assignment) =>
				updatedRoles.find((role) => role.role === assignment.roleId),
			)
		) {
			data.assignments.forEach((assignment) => {
				if (updatedRoles.find((role) => role.role === assignment.roleId)) return
				updatedRoles.push({
					role: assignment.roleId,
					contact: assignment.contactId,
				})
			})
		}

		return updatedRoles
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue(error as any)
	}
})

export const resolveFlaggedTask = createAsyncThunk<
	void,
	{ projectId: string; taskId: string; sectionId: string },
	{ rejectValue: string; state: RootState; dispatch: Dispatch }
>(
	'my-tasks-v3/resolve-flagged-task',
	async ({ taskId }, { getState, rejectWithValue, dispatch }) => {
		try {
			const state = getState()
			const headers = authSelectors.getAuthHeaders(state)
			await V3ProjectSdk.updateTask({
				baseURL: import.meta.env.VITE_API as string,
				authHeaders: headers,
				data: {
					taskId,
					update: {
						isFlagged: false,
					},
				},
			})
		} catch (error) {
			errorHandler({ error, dispatch })
			return rejectWithValue((error as Error).message)
		}
	},
)

export const addFileIdsToTask = createAsyncThunk<
	V3ProjectSdk.AddFileIdsToTaskResponse,
	V3ProjectSdk.AddFileIdsToTaskRequest & {
		projectId: string
		sectionId: string
		referencedTaskId?: string
	},
	{ rejectValue: string; state: RootState; dispatch: Dispatch }
>('my-tasks-v3/add-task-file', async (data, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState()
		const headers = authSelectors.getAuthHeaders(state)
		const response = await V3ProjectSdk.addFileIdsToTask(
			import.meta.env.VITE_API as string,
			headers,
			{
				taskId: data.referencedTaskId ?? data.taskId,
				fileIds: data.fileIds,
				actionIndex: data.actionIndex,
			},
		)

		return response
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue((error as Error).message)
	}
})

export const getUpdatedDependencies = createAsyncThunk<
	V3ProjectSdk.GetUpdatedDependenciesResponse,
	V3ProjectSdk.GetUpdatedDependenciesRequest,
	{ rejectValue: any; state: RootState; dispatch: AsyncDispatchType }
>('my-tasks-v3/updated-dependencies', async (data, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState()
		const headers = authSelectors.getAuthHeaders(state)

		const response = await V3ProjectSdk.getUpdatedDependencies(
			import.meta.env.VITE_API as string,
			headers,
			data,
		)

		return response
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue(error as any)
	}
})

export const getTableId = createAsyncThunk<
	V3ProjectSdk.GetTableIdResponse,
	V3ProjectSdk.GetTableIdRequest,
	{ rejectValue: any; state: RootState; dispatch: AsyncDispatchType }
>('my-tasks-v3/table-id', async (data, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState()
		const headers = authSelectors.getAuthHeaders(state)

		const response = await V3ProjectSdk.getTableId(
			import.meta.env.VITE_API as string,
			headers,
			data,
		)

		return response
	} catch (error) {
		showSnackbar('There is no database associated with this project', { variant: 'warning' })
		return rejectWithValue(error as any)
	}
})
