import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { BlueprintsStateV3 as BlueprintsState } from './types'
import * as thunks from './thunks'

const initialState: BlueprintsState = {
	isValidationLoading: false,
	blueprints: {},
	blueprintCards: [],
	names: [],
}

export const blueprintsSliceV3 = createSlice({
	name: 'blueprintsV3',
	initialState,
	reducers: {
		endSession: () => initialState,
		clearSelectedBlueprint: (state) => {
			state.selectedBlueprintId = undefined
			state.selectedSectionId = undefined
			state.selectedStepId = undefined
		},
		setSelectedSectionId: (state, action: PayloadAction<string | undefined>) => {
			state.selectedSectionId = action.payload
		},
		setSelectedStepId: (state, action: PayloadAction<string | undefined>) => {
			state.selectedStepId = action.payload
		},
	},
	extraReducers: (builder) => {
		builder.addCase(thunks.getCards.fulfilled, (state: BlueprintsState, action) => {
			state.blueprintCards = action.payload
		})
		builder.addCase(thunks.clone.fulfilled, (state: BlueprintsState, action) => {
			const { _id, ...rest } = action.payload
			state.blueprints = {
				...state.blueprints,
				[_id]: {
					_id,
					...rest,
				},
			}
		})
		builder.addCase(thunks.createBlankBlueprint.fulfilled, (state: BlueprintsState, action) => {
			const { _id, ...rest } = action.payload.newBlueprint
			state.blueprints = {
				...state.blueprints,
				[_id]: {
					_id,
					...rest,
				},
			}
		})
		builder.addCase(thunks.getById.fulfilled, (state: BlueprintsState, action) => {
			state.blueprints[action.payload.blueprint._id] = action.payload.blueprint
			state.selectedBlueprintId = action.payload.blueprint._id
		})
		builder.addCase(thunks.updateName.fulfilled, (state: BlueprintsState, action) => {
			const { blueprintId, name } = action.payload
			state.blueprints[blueprintId].name = name
		})
		builder.addCase(thunks.updateDatabaseTable.fulfilled, (state: BlueprintsState, action) => {
			const { blueprintId, tableId } = action.meta.arg
			state.blueprints[blueprintId].database_table = tableId
		})
		builder.addCase(thunks.deleteStep.fulfilled, (state: BlueprintsState, action) => {
			const { blueprintId, stepId } = action.payload
			const blueprintCopy = state.blueprints[blueprintId]
			blueprintCopy.steps = blueprintCopy.steps.filter((step) => step._id !== stepId)
			state.blueprints[blueprintId] = blueprintCopy
		})
		builder.addCase(thunks.updateStep.fulfilled, (state: BlueprintsState, action) => {
			const { blueprint_id: blueprintId } = action.meta.arg
			if (!blueprintId) return
			const { updatedSteps, begins_with } = action.payload
			state.blueprints[blueprintId].begins_with = begins_with
			const blueprintCopy = state.blueprints[blueprintId]
			if (!blueprintCopy) return
			updatedSteps.forEach((updatedStep) => {
				const stepIndex = blueprintCopy.steps.findIndex(({ _id }) => _id === updatedStep._id)
				blueprintCopy.steps[stepIndex] = updatedStep
			})
			state.blueprints[blueprintId] = blueprintCopy
		})
		builder.addCase(thunks.publish.fulfilled, (state: BlueprintsState, action) => {
			const { blueprintId, shouldPublish } = action.payload
			state.blueprints[blueprintId].active = shouldPublish
			const cardIndex = state.blueprintCards.findIndex(({ _id }) => _id === blueprintId)
			if (state.blueprintCards[cardIndex]) state.blueprintCards[cardIndex].active = shouldPublish
		})
		builder.addCase(thunks.deleteBlueprint.fulfilled, (state: BlueprintsState, action) => {
			const blueprintId = action.payload
			delete state.blueprints[blueprintId]
			const cardIndex = state.blueprintCards.findIndex((card) => card._id === blueprintId)
			state.blueprintCards.splice(cardIndex, 1)
		})
		builder.addCase(thunks.createNewStep.fulfilled, (state: BlueprintsState, action) => {
			const { blueprint_id: blueprintId } = action.meta.arg
			if (!blueprintId) return
			const { steps, begins_with } = action.payload
			state.blueprints[blueprintId].begins_with = begins_with
			steps.forEach((step) => {
				const stepIndex = state.blueprints[blueprintId].steps.findIndex(
					({ _id }) => _id === step._id,
				)
				if (stepIndex > -1) {
					state.blueprints[blueprintId].steps[stepIndex] = step
				} else {
					state.blueprints[blueprintId].steps.push(step)
				}
			})
		})
		builder.addCase(thunks.updateBlueprint.pending, (state: BlueprintsState, action) => {
			const { blueprintId, update } = action.meta.arg
			if (!blueprintId) return
			if (update?.section_order) {
				state.blueprints[blueprintId].section_order = update.section_order
			}
		})
		builder.addCase(thunks.updateBlueprint.fulfilled, (state: BlueprintsState, action) => {
			const { blueprintId } = action.meta.arg
			if (!blueprintId) return
			state.blueprints[blueprintId] = {
				...state.blueprints[blueprintId],
				...action.payload,
			}
		})
		builder.addCase(thunks.updateStepOrder.pending, (state: BlueprintsState, action) => {
			const { _id, order, blueprintId } = action.meta.arg
			if (!blueprintId) return
			const taskIndex = state.blueprints[blueprintId].steps.findIndex(
				({ _id: stepId }) => stepId === _id,
			)
			state.blueprints[blueprintId].steps[taskIndex].childOrder = order
		})
		builder.addCase(thunks.updateStepOrder.fulfilled, (state: BlueprintsState, action) => {
			const { blueprintId, _id } = action.meta.arg
			const { savedOrder } = action.payload
			if (!blueprintId) return
			const taskIndex = state.blueprints[blueprintId].steps.findIndex(
				({ _id: stepId }) => stepId === _id,
			)
			state.blueprints[blueprintId].steps[taskIndex].childOrder = savedOrder
		})
	},
})
